import React from "react";

const Badges = ({ data }: { data: string[] }) => {
  return (
    <div className="flex flex-wrap gap-1">
      {data.map((d) => {
        return (
          <span key={d} className="bg-gray-500 text-white px-2 py-1 rounded">
            {d}
          </span>
        );
      })}
    </div>
  );
};

export default Badges;
