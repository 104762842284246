import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  errorMsg?: string;
  required?: boolean;
}

const Input: React.FC<InputProps> = ({
  label,
  error = false,
  errorMsg,
  required,
  ...rest
}) => {
  const styleclasses: string = `bg-white border rounded p-3 text-sm placeholder:text-gray-400 ${
    error ? "border-error" : "border-gray-300"
  }`;
  return (
    <label className="flex flex-col mb-5">
      {label && (
        <span className="mb-1 text-sm">
          {label} {required && <b className="text-error">*</b>}
        </span>
      )}
      <input className={`${styleclasses} h-11`} {...rest} />
      {error && errorMsg ? (
        <span className="block text-error text-xs mt-1">{errorMsg}</span>
      ) : (
        ""
      )}
    </label>
  );
};
export default Input;
