import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  documentName: "",
};

const documentNameSlice = createSlice({
  name: "documentName",
  initialState,
  reducers: {
    setDocumentName: (state, action) => {
      state.documentName = action.payload;
    },
    resetDocumentName: (state) => {
      state.documentName = "";
    },
  },
});

export const { setDocumentName, resetDocumentName } = documentNameSlice.actions;

export default documentNameSlice.reducer;
