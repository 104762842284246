import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../utils/helpers";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface INoveltySearch {
  id?: string;
  projectId: string;
  abstract: string;
  features: any[];
  dateType?: string;
  dateFrom?: string;
  dateTo?: string;
  facetTerms?: any[];
  countries?: any[];
  foundedCountries?: any[];
  foundedFacetTerms?: any[];
  foundedAssignees?: any[];
  foundedClassifications?: any[];
  aiResultId?: string;
  aiSearchId?: string;
  status?: string;
  totalResult?: string | number;
  totalSaved?: string | number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  creatorId?: string;
  updaterId?: string | null;
  deleterId?: string | null;
}

export interface INoveltySearchList {
  count: number;
  rows: INoveltySearch[];
}

export const noveltySearchApi = createApi({
  reducerPath: "noveltySearchApi",
  tagTypes: ["noveltysearch"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getNoveltySearches: builder.query<
      INoveltySearchList,
      { currentPage: number; pageSize: number; projectId: number }
    >({
      query: (arg) => {
        const { currentPage, pageSize, projectId } = arg;
        return {
          url: "/noveltySearches",
          params: { projectId, currentPage, pageSize },
        };
      },
      providesTags: ["noveltysearch"],
    }),
    getNoveltySearch: builder.query<INoveltySearch, any>({
      query: (id) => {
        return {
          url: `/noveltySearches/${id}`,
        };
      },
      providesTags: ["noveltysearch"],
    }),
    addNoveltySearch: builder.mutation<any, any>({
      query: (search: INoveltySearch) => ({
        url: "/noveltySearches",
        method: "POST",
        body: search,
      }),
      invalidatesTags: ["noveltysearch"],
    }),
    updateNoveltySearch: builder.mutation({
      query: (search: INoveltySearch) => ({
        url: `/noveltySearches/${search.id}`,
        method: "PATCH",
        body: search,
      }),
      invalidatesTags: ["noveltysearch"],
    }),
    deleteNoveltySearch: builder.mutation({
      query: (id) => ({
        url: `/noveltySearches/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["noveltysearch"],
    }),
  }),
});

export const {
  useGetNoveltySearchesQuery,
  useGetNoveltySearchQuery,
  useAddNoveltySearchMutation,
  useDeleteNoveltySearchMutation,
  useUpdateNoveltySearchMutation,
} = noveltySearchApi;
