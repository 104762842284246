import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "../components/shared";
import { LoginCallback } from "@okta/okta-react";
import ProtectedRoute from "./ProtectedRoute";
import Main from "../layout/Main";
import Projects from "../pages/Projects";
import ProjectDetail from "../pages/ProjectDetail";
import Result from "../pages/Result";
import Help from "../pages/Help";
import Logout from "../pages/Logout";
import Error from "../pages/Error";
import NewProject from "../pages/NewProject";
import EditProject from "../pages/EditProject";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfUse from "../pages/TermsOfUse";
import PeopleAI from "../pages/peopleai/PeopleAI";
import CreateProject from "../pages/peopleai/CreateProject";
import CrossCite from "../pages/CrossCite";
import ViewProject from "../pages/peopleai/ViewProject";
import { FormProvider } from "../contexts/FormContext";

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="login/callback"
        element={<LoginCallback loadingElement={<Loader />} />}
      />
      <Route path="/" element={<ProtectedRoute />}>
        <Route path="/" element={<Main />}>
          <Route index element={<Navigate to="/projects" />} />
          <Route path="projects" element={<Projects />} />
          <Route path="projects/new" element={<NewProject />} />
          <Route path="projects/edit/:projectId" element={<EditProject />} />
          <Route path="projects/:projectId" element={<ProjectDetail />} />
          <Route
            path="projects/:projectId/:result/:searchId"
            element={<Result />}
          />
          <Route path="help" element={<Help />} />
          <Route
            path="peopleai"
            element={
              <FormProvider>
                <PeopleAI />
              </FormProvider>
            }
          />
          <Route
            path="peopleai/create"
            element={
              <FormProvider>
                <CreateProject />
              </FormProvider>
            }
          />
          <Route
            path="peopleai/view/:id"
            element={
              <FormProvider>
                <ViewProject />
              </FormProvider>
            }
          />
          <Route path="crosscite" element={<CrossCite />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
        </Route>
      </Route>
      <Route path="logout" element={<Logout />} />
      <Route
        path="*"
        element={<Error description="Message: Page not found." />}
      />
    </Routes>
  );
};

export default AppRoutes;
