import React from "react";
import NoveltyLogo from "../../../assets/img/novelty-icon-white.svg";

const NoveltyIcon = () => {
  return (
    <div className="w-8 h-8 inline-flex items-center justify-center bg-novelty rounded">
      <img src={NoveltyLogo} className="w-5" alt="Novelty search icon"/>
    </div>
  );
};

export default NoveltyIcon;
