import React from "react";
import { toast } from "react-hot-toast";
import RequestSubmittedIcon from "../../assets/img/email-submitted-icon.svg";
import { Button, Snackbar } from "../shared";

interface IRequestSubmitted {
  closeHandler: () => void;
  buttonLabel?: string;
}

const RequestSubmitted: React.FC<IRequestSubmitted> = ({
  closeHandler,
  buttonLabel = `Done`,
}) => {
  const btnHandler = () => {
    toast((t) => (
      <Snackbar
        message={
          <>
            Search <b>initiated.</b>
          </>
        }
        onClose={() => toast.dismiss(t.id)}
      />
    ));
    closeHandler();
  };

  return (
    <div className="p-12">
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
        <img src={RequestSubmittedIcon} alt="email icon"/>
      </div>
      <div className="mt-7 text-center sm:mt-5">
        <h3 className="text-2xl font-sweetsanspro font-bold leading-6 text-gray-900">
          Request Submitted
        </h3>
        <div className="mt-4 mb-8">
          <p className="text-sm font-normal">
            Our team has received your request and will get reach out to you in
            a few days.
          </p>
        </div>
        <Button onClick={btnHandler} className="!px-16">{buttonLabel}</Button>
      </div>
    </div>
  );
};

export default RequestSubmitted;
