import React from "react";

const QueueIcon = ({ color }: { color?: string }) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.666504C5.50333 0.666504 3.44336 2.56984 3.19336 4.99984H3C1.34667 4.99984 0 6.3465 0 7.99984C0 9.65317 1.34667 10.9998 3 10.9998H13C14.6533 10.9998 16 9.65317 16 7.99984C16 6.3465 14.6533 4.99984 13 4.99984H12.8066C12.5566 2.56984 10.4967 0.666504 8 0.666504ZM8 3.6665C9.65667 3.6665 11 5.00984 11 6.6665C11 8.32317 9.65667 9.6665 8 9.6665C6.34333 9.6665 5 8.32317 5 6.6665C5 5.00984 6.34333 3.6665 8 3.6665ZM7.99219 4.32601C7.85969 4.32808 7.73344 4.38265 7.64115 4.47775C7.54887 4.57284 7.4981 4.70067 7.5 4.83317V6.6665C7.50003 6.7991 7.55272 6.92626 7.64648 7.02002L8.47982 7.85335C8.52589 7.90134 8.58107 7.93965 8.64214 7.96604C8.7032 7.99243 8.76892 8.00637 8.83544 8.00705C8.90196 8.00773 8.96794 7.99512 9.02953 7.96998C9.09112 7.94483 9.14707 7.90765 9.19411 7.86061C9.24115 7.81358 9.27833 7.75762 9.30347 7.69604C9.32862 7.63445 9.34122 7.56846 9.34055 7.50194C9.33987 7.43542 9.32593 7.36971 9.29954 7.30864C9.27315 7.24758 9.23483 7.19239 9.18685 7.14632L8.5 6.45947V4.83317C8.50096 4.76625 8.48848 4.69981 8.46329 4.63781C8.4381 4.5758 8.40072 4.51948 8.35336 4.47218C8.30601 4.42489 8.24964 4.38758 8.1876 4.36247C8.12556 4.33736 8.05911 4.32496 7.99219 4.32601Z"
        fill={`${color || "white"}`}
      />
    </svg>
  );
};

export default QueueIcon;
