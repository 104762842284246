import React, { ReactElement } from "react";
import EmptyClipboard from "../../../assets/img/empty-clipboard.svg";

export interface IEmptyState {
  label: string;
  description?: string;
  icon?: ReactElement;
}

const EmptyState: React.FC<IEmptyState> = ({
  label = "There is nothing here",
  description,
  icon,
}) => {
  return (
    <div className="w-full text-center rounded-lg border-2 border-dashed border-gray-300 p-12 flex items-center flex-col">
      <div className="w-52 h-52 rounded-full bg-gray-300 flex justify-center items-center">
        <img src={EmptyClipboard} alt="Empty space icon" />
      </div>
      <h3 className="mt-8 text-2xl font-bold text-black">{label}</h3>
      <p className="mt-4 text-base text-gray-500 max-w-sm mx-auto">
        {description}
      </p>
    </div>
  );
};

export default EmptyState;
