import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const uploadApi = createApi({
  reducerPath: "uploadApi",
  tagTypes: ["upload"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token: any = localStorage.getItem("okta-token-storage");
      const accessToken = JSON.parse(token)?.accessToken?.accessToken;
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPresignedUrl: builder.query<any, any>({
      query: (documentName: string) => {
        return {
          url: "/upload/presigned-url",
          params: { documentName },
        };
      },
      providesTags: ["upload"],
    }),
    uploadFiles: builder.mutation<any, any>({
      query: (formData: FormData) => ({
        url: "/upload",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["upload"],
    }),
  }),
});

export const { useGetPresignedUrlQuery, useUploadFilesMutation } = uploadApi;
