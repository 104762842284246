import React from "react";
import NoveltyLogo from "../../assets/img/novelty-icon.svg";
import FreedomLogo from "../../assets/img/freedom-icon.svg";
import StrategicAlignmentLogo from "../../assets/img/strategic-alignment-icon.svg";
import { TooltipInfoIcon } from "../shared";

interface ISearchTypeItem {
  clickHandler: () => void;
  isActive?: boolean;
  type: "freedom" | "novelty" | "strategicAlignment";
  title: string;
  description?: string;
}

const SearchTypeItem: React.FC<ISearchTypeItem> = ({
  clickHandler,
  title,
  description,
  isActive = true,
  type,
}) => {
  const logo =
    type === "novelty"
      ? NoveltyLogo
      : type === "freedom"
      ? FreedomLogo
      : StrategicAlignmentLogo;
  const styles = isActive
    ? "border shadow-md cursor-pointer hover:outline-none hover:ring-2 hover:ring-dark-blue hover:ring-offset-0"
    : "bg-gray-100 select-none";

  const searchTypeClickHandler = () => {
    if (isActive) {
      clickHandler();
    }
  };

  return (
    <div
      tabIndex={1}
      onClick={searchTypeClickHandler}
      className={`p-8 text-center rounded-md mb-5 ${styles}`}
    >
      <img src={logo} className="inline-block h-20" alt="Search type logo" />
      <h3 className="font-sweetsanspro font-bold text-2xl mt-5">
        {title} <TooltipInfoIcon tooltipId={`${type}SearchInfo`} />
      </h3>
      <p className="font-normal text-sm mt-3">{description}</p>
    </div>
  );
};

export default SearchTypeItem;
