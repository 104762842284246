//================================================
const getAccessToken = () => {
  const token: any = localStorage.getItem("okta-token-storage");
  const accessToken = JSON.parse(token)?.accessToken?.accessToken;
  return accessToken || null;
};

//================================================
const permissionMappings: Record<string, string> = {
  "Product Access Novelty": "novelty",
  "Product Access Freedom": "freedom",
  "Product Access Strategic Alignment": "strategicAlignment",
  "Product Access Askdot": "askdot",
};

const getPermissions = () => {
  const token: any = localStorage.getItem("okta-token-storage");
  const groups = JSON.parse(token)?.accessToken?.claims?.productAccessGroups;
  const permissions: string[] = groups.map((group: string) => {
    return permissionMappings[group];
  });
  return permissions || [];
};

//================================================
const getCurrentUserId = () => {
  const token: any = localStorage.getItem("okta-token-storage");
  const userId = JSON.parse(token)?.idToken?.claims?.sub;
  return userId;
};

//================================================
const getFirstChars = (str: string | undefined) => {
  return (
    str &&
    str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "")
  );
};

//================================================
//This method, prepares the Opta owner list to selectbox.
const prepareOwners = (owners: any[]) => {
  const result: any[] = [];
  for (let i = 0; i < owners?.length; i++) {
    if (owners[i].status === "ACTIVE") {
      result.push({
        value: owners[i].id,
        label: owners[i].profile.firstName + " " + owners[i].profile.lastName,
      });
    }
  }
  return result.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );
};

//================================================
const prepareSelectedCountriesToProject = (selectedCountries: any[]) => {
  const result: string[] = [];
  for (let i = 0; i < selectedCountries.length; i++) {
    result.push(selectedCountries[i].value);
  }
  return result;
};

//================================================
//order alphabeticly countries
const sortCountries = (list: any[]) => {
  return list.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );
};

//================================================
//strip html tags from string
const textCleaner = (str?: string) => {
  let cleanStr = "";
  if (str) {
    const div = document.createElement("div");
    div.innerHTML = str;
    cleanStr = div.textContent || div.innerText || "";
  }
  return cleanStr;
};

//================================================
const stringSlicer = (str: string, len: number) => {
  if (!str) {
    return "";
  }
  if (str.length > len + 3) {
    return str.slice(0, len) + "...";
  } else {
    return str;
  }
};

//================================================
//searchResults params serializer
const paramsSerializer = (obj: any) => {
  const queries: string[] = [];

  for (const key in obj) {
    const val = obj[key];
    if (val) {
      if (Array.isArray(val)) {
        val.forEach((v) => {
          queries.push(key + "[]=" + v);
        });
      } else {
        queries.push(key + "=" + val);
      }
    }
  }
  return queries.join("&");
};

//================================================
//to capitalize string
const capitalize = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

//================================================
//year range for date and date range picker
const yearRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

//================================================
//format date for backend and frontend
//turns any date format to "YYYY-MM-DD" for backend
//turns any date format to "DD/MM/YYYY" for frontend
const dateFormatter = (date?: string, to: string = "backend") => {
  if (!date) {
    return undefined;
  }

  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (to === "backend") {
    return [year, month, day].join("-");
  } else {
    return [month, day, year].join("/");
  }
};

//================================================
//create slug from string for download all buttons
const createSlug = (str?: string) => {
  if (!str) {
    return "";
  }
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  let cap = capitalize(str.replaceAll("-", " ").trim());
  cap = cap.replaceAll(" ", "-");

  return cap;
};

const isEmptyObject = (obj: any) => {
  for (let key in obj) {
    if (
      obj[key] !== "" &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      !(Array.isArray(obj[key]) && obj[key].length === 0) &&
      !(typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0)
    ) {
      return false;
    }
  }
  return true;
};


export {
  getAccessToken,
  getPermissions,
  getCurrentUserId,
  getFirstChars,
  prepareOwners,
  prepareSelectedCountriesToProject,
  sortCountries,
  textCleaner,
  stringSlicer,
  paramsSerializer,
  capitalize,
  yearRange,
  dateFormatter,
  createSlug,
  isEmptyObject,
};
