import React from "react";

const Error = ({
  title = "An Error Occured!",
  description,
}: {
  title?: string;
  description?: string;
}) => {
  return (
    <div className="w-full rounded-lg border-2 border-dashed border-gray-300 p-8 text-center">
      <h3 className="text-xl font-sweetsanspro font-bold text-red-600">{title}</h3>
      {description && <div className="mt-4">{description}</div>}
    </div>
  );
};

export default Error;
