import React, { useEffect, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as Calendar } from "../../../assets/icons/calendar.svg";
import { Radio } from "semantic-ui-react";
import { ReactSelect } from "../../../components/shared";
import DatePicker from "react-datepicker";
import { StylesConfig } from "react-select";
import { FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import cx from "classnames";
import { useFormContext } from "../../../contexts/FormContext";
import { isEmptyObject } from "../../../utils/helpers";

const searchOptions = [
  {
    label: "US",
    value: "US",
  },
  {
    label: "EPO",
    value: "EPO",
  },
  {
    label: "PCT/WIPO",
    value: "PCT/WIPO",
  },
  {
    label: "Korea",
    value: "Korea",
  },
  {
    label: "Japan",
    value: "Japan",
  },
  {
    label: "China",
    value: "China",
  },
  {
    label: "Worldwide",
    value: "Worldwide",
  },
];
const nonPatentLiterature = [
  {
    label: "No non-patent literature",
    value: "No non-patent literature",
  },
  {
    label: "Basic non-patent literature",
    value: "Basic non-patent literature",
  },
  {
    label: "Extended non-patent literature",
    value: "Extended non-patent literature",
  },
];

const reportFormat = [
  {
    label: "Bibliographic Data",
    value: "Bibliographic Data",
  },
  {
    label: "Document Locations",
    value: "Document Locations",
  },
  {
    label: "Categorizations",
    value: "Categorizations",
  },
  {
    label: "Charted",
    value: "Charted",
  },
];
interface IQuestionFourForm {
  keyElements: string;
  isRush: string | null;
  appointmentDate: string | undefined;
  searchOptions: string[];
  nonPatentLiterature: string;
  reportFormat: string;
}

interface IQuestionFourFormResponse {
  keyElements: string;
  isRush: string | null;
  appointmentDate: string | undefined;
  searchOptions: string;
  nonPatentLiterature: string;
  reportFormat: string;
}

interface IQuestionFourProps {
  prefilledValues?: IQuestionFourForm | any;
}

const QuestionFour: React.FC<IQuestionFourProps> = ({ prefilledValues }) => {
  const datePickerRef = useRef<DatePicker>(null);
  const handleIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };

  const getStyles = (
    errors: any,
    touched: any,
    fieldName: string
  ): StylesConfig => ({
    control: (base: any, props: any) => ({
      ...base,
      pointerEvents:
        !!prefilledValues?.searchOptions ||
        !!prefilledValues?.nonPatentLiterature ||
        !!prefilledValues?.reportFormat
          ? "none"
          : "auto",
      border:
        errors[fieldName] && touched[fieldName]
          ? "2px solid red !important"
          : "2px solid #b1a7a2 !important",
      borderRadius: 10,
      ...(fieldName === "searchOptions" && { minHeight: "50px !important" }),
      height: fieldName === "searchOptions" ? "auto" : 50,
      ":hover": {
        cursor: "pointer",
      },
      ":focus-within": {
        boxShadow: "0 0 0 2px black !important",
        border: "2px solid transparent !important",
      },
    }),
    placeholder: (base, props) => ({
      ...base,
      color: "#b1a7a2 !important",
      fontSize: "16px !important",
      padding: "0 10px",
    }),
    input: (base, props) => ({
      ...base,
      padding: "0 10px",
      fontSize: "16px !important",
    }),
    dropdownIndicator: (base, props) => ({
      ...base,
      marginRight: "8px",
    }),
    menuList: (base, props) => ({
      ...base,
      padding: "10px",
      fontSize: "16px",
    }),
    noOptionsMessage: (base, props) => ({
      ...base,
      fontSize: "16px",
    }),
    singleValue: (base, props) => ({
      ...base,
      fontSize: "16px",
      paddingLeft: "8px",
    }),
  });
  const {
    currentQuestionIndex,
    handleAnswers,
    questionFourFormValues,
    setQuestionFourFormValues,
    setQuestionFourNext,
  } = useFormContext();
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    setFieldTouched,
    handleBlur,
    isValid,
  } = useFormik<IQuestionFourForm>({
    initialValues: questionFourFormValues,
    validationSchema: Yup.object().shape({
      keyElements: Yup.string().trim().required("Please enter key elements"),
      isRush: Yup.string().required("Please select urgency"),
      appointmentDate: Yup.string().test(
        "is-required-if-rush",
        "Please select appointment date",
        function (value) {
          const { isRush } = this.parent;
          if (isRush === "Yes") {
            return value != null;
          }
          return true;
        }
      ),
      searchOptions: Yup.array()
        .of(Yup.string().required("Please select search options"))
        .min(1, "Please select at least one search option"),
      nonPatentLiterature: Yup.string().required(
        "Please select non-patent literature"
      ),
      reportFormat: Yup.string().required("Please select report format"),
    }),
    onSubmit: (values: FormikValues) => {},
  });

  useEffect(() => {
    setQuestionFourFormValues(values);
    const isFormEmpty =
      isEmptyObject(values) && isEmptyObject(questionFourFormValues);
    let trimmedValues: IQuestionFourFormResponse = {
      appointmentDate: "",
      isRush: null,
      keyElements: "",
      nonPatentLiterature: "",
      reportFormat: "",
      searchOptions: "",
    };

    for (let v in values) {
      const key = v as keyof IQuestionFourForm;
      if (key === "appointmentDate") {
        trimmedValues[key] = values[key] ?? "";
      }
      if (key === "searchOptions" && Array.isArray(values[key])) {
        trimmedValues[key] = values[key]?.join(", ");
      }
      if (
        key !== "searchOptions" &&
        key !== "appointmentDate" &&
        !Array.isArray(values[key])
      ) {
        trimmedValues[key] = (values[key]?.replace(/\s+/g, " ")?.trim() ??
          "") as string;
      }
    }
    handleAnswers(trimmedValues);
    setQuestionFourNext({
      enable: !isFormEmpty && isValid,
      disable: isFormEmpty || !isValid,
    });

    if (values.isRush === "No") {
      if (!!values.appointmentDate) {
        setFieldValue("appointmentDate", "");
      }
    }
  }, [values, isValid, currentQuestionIndex, questionFourFormValues]);
  const title =
    "Please provide key element(s) of invention to be searched. Possible things to include: main points of novelty/uniqueness of invention to be searched; Any key assignee(s) to be searched?; Any known inventors to be searched?; Any other special instructions?; Any known publications to exclude from search results? Patent number if part of the search.";
  return (
    <form className="flex flex-col gap-y-14 responsive-form-gap">
      <div className="flex flex-col gap-y-4">
        <span
          className={cx("font-medium text-base text-black", {
            "text-red-500":
              errors.keyElements &&
              touched.keyElements &&
              !prefilledValues?.keyElements,
          })}
        >
          {title} &nbsp;
          {errors.keyElements &&
            touched.keyElements &&
            !prefilledValues?.keyElements && (
              <span className="font-light text-[14px]">
                ({errors.keyElements})
              </span>
            )}
        </span>
        <textarea
          name="keyElements"
          id="keyElements"
          autoFocus={!prefilledValues?.keyElements}
          readOnly={!!prefilledValues?.keyElements}
          value={
            !!prefilledValues
              ? prefilledValues?.keyElements
              : values.keyElements
          }
          onBlur={handleBlur}
          onChange={handleChange}
          className={cx(
            "border-2 border-dorothy-dark-gray text-black rounded-[10px] h-[133px] resize-none py-4 px-6 text-base placeholder:text-dorothy-dark-gray",
            {
              "border-red-500":
                errors.keyElements &&
                touched.keyElements &&
                !prefilledValues?.keyElements,
              "pointer-events-none": !!prefilledValues?.keyElements,
            }
          )}
          placeholder="Key elements"
        />
      </div>
      <div className="flex items-center justify-between text-black flex-wrap gap-y-12">
        <div className="flex flex-1 gap-x-16 text-base font-medium">
          <span
            className={cx({
              "text-red-500":
                errors.isRush && touched.isRush && !prefilledValues?.isRush,
            })}
          >
            Is this a rush?
          </span>
          <div className="flex items-center gap-x-12">
            <Radio
              label="Yes"
              name="isRush"
              checked={
                !!prefilledValues
                  ? prefilledValues?.isRush === "Yes"
                  : values.isRush === "Yes"
              }
              onChange={(e, { value }) => {
                setFieldTouched("isRush", true);
                setFieldValue("isRush", value);
              }}
              value="Yes"
              readOnly={!!prefilledValues?.isRush}
            />
            <Radio
              label="No"
              name="isRush"
              checked={
                !!prefilledValues
                  ? prefilledValues?.isRush === "No"
                  : values.isRush === "No"
              }
              onChange={(e, { value }) => {
                setFieldTouched("isRush", true);
                setFieldValue("isRush", value);
              }}
              value="No"
              readOnly={!!prefilledValues?.isRush}
            />
          </div>
        </div>
        <div className="relative peopleai">
          <DatePicker
            ref={datePickerRef}
            onBlur={handleBlur}
            disabled={
              values.isRush === "No" ||
              !values.isRush ||
              !!prefilledValues?.isRush
            }
            placeholderText="Select due date"
            className={cx("text-base")}
            value={
              !!prefilledValues
                ? prefilledValues?.appointmentDate
                  ? new Date(prefilledValues?.appointmentDate)
                  : new Date()
                : (values.appointmentDate as any)
            }
            selected={
              !!prefilledValues
                ? prefilledValues?.appointmentDate
                  ? (new Date(prefilledValues?.appointmentDate) as
                      | Date
                      | null
                      | undefined)
                  : new Date()
                : (values.appointmentDate as Date | null | undefined)
            }
            onChange={(date) => {
              setFieldTouched("appointmentDate", true);
              setFieldValue("appointmentDate", date);
            }}
            minDate={new Date()}
          />
          <div
            className={cx("absolute top-[5px] right-3 z-40 cursor-pointer", {
              "!cursor-not-allowed":
                values.isRush === "No" || !!prefilledValues?.appointmentDate,
            })}
            onClick={handleIconClick}
          >
            <Calendar />
          </div>
          {errors.appointmentDate && !prefilledValues?.appointmentDate && (
            <span className="absolute left-1 -bottom-6 text-red-500 text-[14px] font-light">
              {errors.appointmentDate}
            </span>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-0 responsive-margin">
        <div className="flex flex-col gap-y-2">
          <div
            className={cx("flex gap-x-2 items-center font-semibold", {
              "text-red-500":
                errors.searchOptions &&
                touched.searchOptions &&
                !prefilledValues?.searchOptions,
            })}
          >
            <span>Search options</span>
            {errors.searchOptions &&
              touched.searchOptions &&
              !prefilledValues?.searchOptions && (
                <span className="font-light">({errors.searchOptions})</span>
              )}
          </div>
          <ReactSelect
            styles={getStyles(errors, touched, "searchOptions")}
            name="searchOptions"
            noSeparator
            isClearable={!!prefilledValues?.searchOptions ? false : true}
            isSearchable={false}
            multiple
            data={searchOptions}
            value={
              prefilledValues?.searchOptions?.length > 0
                ? prefilledValues?.searchOptions.map((option: any) => ({
                    value: option,
                    label: option,
                  }))
                : values.searchOptions.map((option: any) => ({
                    value: option,
                    label: option,
                  }))
            }
            tabIndex={1}
            customArrow
            customClear
            placeholder="Search Options"
            onChange={(selectedOptions: any) => {
              setFieldTouched("searchOptions", true);
              setFieldValue(
                "searchOptions",
                selectedOptions
                  ? selectedOptions.map((option: any) => option.value)
                  : []
              );
            }}
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <div
            className={cx("flex gap-x-2 items-center font-semibold", {
              "text-red-500":
                errors.nonPatentLiterature &&
                touched.nonPatentLiterature &&
                !prefilledValues?.nonPatentLiterature,
            })}
          >
            <span>Non-Patent Literature</span>
            {errors.nonPatentLiterature &&
              touched.nonPatentLiterature &&
              !prefilledValues?.nonPatentLiterature && (
                <span className="font-light">
                  ({errors.nonPatentLiterature})
                </span>
              )}
          </div>
          <ReactSelect
            styles={getStyles(errors, touched, "nonPatentLiterature")}
            name="nonPatentLiterature"
            noSeparator
            isClearable={!!prefilledValues?.nonPatentLiterature ? false : true}
            isSearchable={false}
            data={nonPatentLiterature}
            value={
              !!prefilledValues?.nonPatentLiterature
                ? nonPatentLiterature.find(
                    (option) =>
                      option.value === prefilledValues?.nonPatentLiterature
                  )
                : nonPatentLiterature.find(
                    (option) => option.value === values.nonPatentLiterature
                  )
            }
            tabIndex={1}
            customArrow
            customClear
            placeholder="Non-patent Literature"
            onChange={(e: any) => {
              setFieldTouched("nonPatentLiterature", true);
              setFieldValue("nonPatentLiterature", e?.value);
            }}
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <div
            className={cx("flex gap-x-2 items-center font-semibold", {
              "text-red-500":
                errors.reportFormat &&
                touched.reportFormat &&
                !prefilledValues?.reportFormat,
            })}
          >
            <span>Report Format</span>
            {errors.reportFormat &&
              touched.reportFormat &&
              !prefilledValues?.reportFormat && (
                <span className="font-light">({errors.reportFormat})</span>
              )}
          </div>
          <ReactSelect
            styles={getStyles(errors, touched, "reportFormat")}
            name="reportFormat"
            noSeparator
            isClearable={!!prefilledValues?.reportFormat ? false : true}
            isSearchable={false}
            data={reportFormat}
            value={
              !!prefilledValues?.reportFormat
                ? reportFormat.find(
                    (option) => option.value === prefilledValues?.reportFormat
                  )
                : reportFormat.find(
                    (option) => option.value === values.reportFormat
                  )
            }
            tabIndex={1}
            customArrow
            customClear
            placeholder="Report Format"
            onChange={(e: any) => {
              setFieldTouched("reportFormat", true);
              setFieldValue("reportFormat", e?.value);
            }}
          />
        </div>
      </div>
    </form>
  );
};

export default QuestionFour;
