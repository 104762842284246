import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Snackbar = ({ message, onClose }: { message: any; onClose?: any }) => {
  return (
    <div className="flex w-full items-center justify-between">
      <div>{message}</div>
      {onClose && (
        <button onClick={onClose} className="ml-10">
          <XMarkIcon className="w-6 h-6" />
        </button>
      )}
    </div>
  );
};

export default Snackbar;
