import React, { useRef, useState } from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { strategicAlignmentNovelWeightOptions } from "../../utils/consts";
import { Button, Input, Modal, MultiSelect, Textarea } from "../shared";
import { Formik, Form, Field, FieldArray } from "formik";
import { strategicAlignmentSchema } from "../../validations";
import { sortCountries } from "../../utils/helpers";
import SearchConfirmation from "./SearchConfirmation";
import {
  IStrategicAlignmentSearch,
  useAddStrategicAlignmentSearchMutation,
} from "../../features/strategicAlignmentSearchApi";
import Countries from "../../assets/countries.json";

interface IStrategicAlignmentSearchForm {
  backHandler: () => void;
  nextHandler: () => void;
  data?: IStrategicAlignmentSearch;
  projectId: string;
}

const emptyFeature = { text: "", weight: "Medium" };
const factorsList = [
  {
    name: "The client wishes to obtain patents in jurisdictions where it is commercially active (manufactures, uses, or sells the invention):",
    value: "",
  },
  {
    name: "The client wishes to block competitors who are commercially active in the jurisdictions:",
    value: "",
  },
  {
    name: "The client intends to enforce its patent rights in the jurisdictions where the application is filed:",
    value: "",
  },
  {
    name: "The client wishes to file patent applications in jurisdictions where it has existing patent filings:",
    value: "",
  },
  {
    name: "The client wishes to pursue patents in jurisdictions where competitors commonly file:",
    value: "",
  },
];

const initialValues: IStrategicAlignmentSearch = {
  projectId: "",
  publicationNo: "",
  description: "",
  novelFeatures: [{ ...emptyFeature }],
  headQuarters: [],
  supply: [],
  sales: [],
  factors: [...factorsList],
};

const StrategicAlignmentSearchForm: React.FC<IStrategicAlignmentSearchForm> = ({
  backHandler,
  nextHandler,
  data,
  projectId,
}) => {
  const [confirmation, setConfirmation] = useState(false);
  const tempValuesRef = useRef<any>(null);
  const [addSearch] = useAddStrategicAlignmentSearchMutation();
  const sortedCountries = sortCountries(Countries);

  let defaultValues: IStrategicAlignmentSearch;
  if (data) {
    defaultValues = {
      projectId: projectId,
      description: data.description,
      novelFeatures: data.novelFeatures,
      headQuarters: data.headQuarters,
      supply: data.supply,
      sales: data.sales,
      factors: data.factors,
    };
  } else {
    defaultValues = { ...initialValues, projectId: projectId };
  }

  const addNewSearch = () => {
    addSearch(tempValuesRef.current)
      .then((response) => nextHandler())
      .catch((error) => alert("An error occured. Contact with administrator."));
  };

  const onSubmit = (values: any, actions: any) => {
    tempValuesRef.current = { ...values };

    if (data) {
      setConfirmation(true);
    } else {
      addNewSearch();
    }
  };

  const approveConfirmation = () => {
    setConfirmation(false);
    addNewSearch();
  };

  return (
    <>
      <Formik
        initialValues={defaultValues}
        validationSchema={strategicAlignmentSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values }) => (
          <Form autoComplete="off">
            <div className="mb-7 grid grid-col-1 md:grid-cols-4">
              <Field
                name="publicationNo"
                type="text"
                label="Publication No."
                className="border-2 rounded-md p-[11px]"
                as={Input}
                error={errors.publicationNo && touched.publicationNo}
                errorMsg={touched.publicationNo && errors.publicationNo}
                tabIndex={1}
              />
            </div>
            <div className="mb-12">
              <h4 className="font-bold text-lg mb-4">Description</h4>
              <p>
                Describe the invention or product in detail including all of its
                component parts whether they are patentable or not.
              </p>
              <br />
              <Field
                name="description"
                type="text"
                label=""
                as={Textarea}
                className="border-2 rounded-md resize-none p-2"
                error={errors.description && touched.description}
                errorMsg={touched.description && errors.description}
                tabIndex={1}
              />
            </div>

            <div className="mb-12">
              <FieldArray name="novelFeatures">
                {({ push, remove }) => (
                  <>
                    <h4 className="font-bold text-lg mb-4">Novel Features</h4>
                    <p>
                      Describe the point of novelty of the invention or a
                      component or part of the product that provides a
                      competitive advantage.
                    </p>
                    <br />
                    {values.novelFeatures.map((_: any, index: number) => (
                      <div key={index}>
                        <div className="grid grid-cols-4 gap-4">
                          <div className="md:col-span-3 col-span-4">
                            <Field
                              type="text"
                              name={`novelFeatures.${index}.text`}
                              className="border-2 rounded-md resize-none p-[11px]"
                              as={Input}
                              label="Novel Feature"
                              required={false}
                              tabIndex={1}
                              error={
                                // @ts-ignore: Unreachable code error
                                errors.novelFeatures?.[index]?.text &&
                                // @ts-ignore: Unreachable code error
                                touched.novelFeatures?.[index]?.text
                              }
                              errorMsg={
                                // @ts-ignore: Unreachable code error
                                errors.novelFeatures?.[index]?.text
                              }
                            />
                          </div>
                          <div className="-mt-6 mb-4 md:m-0 col-span-2 md:col-span-1">
                            <Field
                              name={`novelFeatures.${index}.weight`}
                              component={MultiSelect}
                              label="Weight"
                              data={strategicAlignmentNovelWeightOptions}
                              tooltip
                              isClearable={false}
                              tabIndex={1}
                              tooltipId={`featureWightInfo___${index}`}
                            />
                          </div>
                        </div>
                        <div
                          className={`inline-flex items-center ${
                            values.novelFeatures.length > 1
                              ? "cursor-pointer text-error"
                              : "text-black/50"
                          }`}
                          onClick={() =>
                            values.novelFeatures.length > 1 && remove(index)
                          }
                        >
                          <XMarkIcon className="w-4 h-4 mr-2" />{" "}
                          <span>Remove Above Item</span>
                        </div>
                        <div className="h-4 border-b mb-4"></div>
                      </div>
                    ))}

                    <div
                      className="text-dorothy-orange inline-flex cursor-pointer items-center"
                      onClick={() => push(emptyFeature)}
                    >
                      <PlusIcon className="w-4 h-4 mr-2" />{" "}
                      <span>Add Novel Features</span>
                    </div>
                  </>
                )}
              </FieldArray>
            </div>

            <div className="mb-12 md:mb-7">
              <h4 className="font-bold text-lg mb-4">Countries</h4>
              <p>
                Identify the jurisdictions where the client is currently
                commercially active or intends to have commercial activities
                using the dropdown menus.
              </p>
              <br />
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="-mb-5 md:mb-0">
                  <Field
                    name="headQuarters"
                    component={MultiSelect}
                    label="Headquarters"
                    data={sortedCountries}
                    isClearable
                    tabIndex={1}
                    multiple
                    error={errors.headQuarters && touched.headQuarters}
                    errorMsg={touched.headQuarters && errors.headQuarters}
                  />
                </div>
                <div>
                  <Field
                    name="supply"
                    component={MultiSelect}
                    label="Supply"
                    data={sortedCountries}
                    isClearable
                    tabIndex={2}
                    multiple
                    error={errors.supply && touched.supply}
                    errorMsg={touched.supply && errors.supply}
                  />
                </div>
                <div>
                  <Field
                    name="sales"
                    component={MultiSelect}
                    label="Sales"
                    data={sortedCountries}
                    isClearable
                    tabIndex={3}
                    multiple
                    error={errors.sales && touched.sales}
                    errorMsg={touched.sales && errors.sales}
                  />
                </div>
              </div>
            </div>

            <div className="mb-12 md:mb-7">
              <FieldArray name="factors">
                {() => (
                  <>
                    <h4 className="font-bold text-lg mb-4">Factors</h4>
                    <p>
                      Describe the client’s strategy surrounding IP rights
                      encompassed by the application or invention described
                      above, using the provided radio buttons.
                    </p>
                    <br />
                    {values.factors.map((_: any, index: number) => (
                      <div className="mb-5" key={index}>
                        <p className="mb-2">{_.name}</p>
                        <div className="mb-2">
                          <label className="inline-flex items-center gap-3">
                            <Field
                              type="radio"
                              name={`factors.${index}.value`}
                              value="Low"
                            />
                            <span>Low</span>
                          </label>
                        </div>
                        <div className="mb-2">
                          <label className="inline-flex items-center gap-3">
                            <Field
                              type="radio"
                              name={`factors.${index}.value`}
                              value="Medium"
                            />
                            <span>Medium</span>
                          </label>
                        </div>
                        <div>
                          <label className="inline-flex items-center gap-3">
                            <Field
                              type="radio"
                              name={`factors.${index}.value`}
                              value="High"
                            />
                            <span>High</span>
                          </label>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </FieldArray>

              {/*
              <div className="mb-5">
                <p className="mb-2">
                  The client is active (manufactures, uses, or sells) in that
                  jurisdiction
                </p>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factors" type="radio" value="0.1" />
                    <span>Low</span>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor1" type="radio" value="0.5" />
                    <span>Medium</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor1" type="radio" value="1.0" />
                    <span>Hight</span>
                  </label>
                </div>
              </div>

              <div className="mb-5">
                <p className="mb-2">
                  A known competitor is active in that jurisdiction
                </p>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor2" type="radio" value="0.1" />
                    <span>Low</span>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor2" type="radio" value="0.5" />
                    <span>Medium</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor2" type="radio" value="1.0" />
                    <span>Hight</span>
                  </label>
                </div>
              </div>

              <div className="mb-5">
                <p className="mb-2">
                  The client intends to enforce their patent rights in that
                  jurisdiction
                </p>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor3" type="radio" value="0.1" />
                    <span>Low</span>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor3" type="radio" value="0.5" />
                    <span>Medium</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor3" type="radio" value="1.0" />
                    <span>Hight</span>
                  </label>
                </div>
              </div>

              <div className="mb-5">
                <p className="mb-2">
                  The client has existing patent filings in that jurisdiction
                </p>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor4" type="radio" value="0.1" />
                    <span>Low</span>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor4" type="radio" value="0.5" />
                    <span>Medium</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor4" type="radio" value="1.0" />
                    <span>Hight</span>
                  </label>
                </div>
              </div>

              <div className="mb-5">
                <p className="mb-2">
                  The client intends to have activity in that jurisdiction
                </p>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor5" type="radio" value="0.1" />
                    <span>Low</span>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor5" type="radio" value="0.5" />
                    <span>Medium</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor5" type="radio" value="1.0" />
                    <span>Hight</span>
                  </label>
                </div>
              </div>

              <div className="mb-5">
                <p className="mb-2">
                  The client wishes to pursue patents in jurisdictions where
                  competitors commonly file
                </p>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor6" type="radio" value="0.1" />
                    <span>Low</span>
                  </label>
                </div>
                <div className="mb-2">
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor6" type="radio" value="0.5" />
                    <span>Medium</span>
                  </label>
                </div>
                <div>
                  <label className="inline-flex items-center gap-3">
                    <Field name="factor6" type="radio" value="1.0" />
                    <span>Hight</span>
                  </label>
                </div>
              </div>
            */}
            </div>

            <div className="border-t -mx-7 px-7 pt-5 mt-5 flex justify-end">
              <Button variant="outlined" onClick={backHandler}>
                Back
              </Button>
              &nbsp;&nbsp;
              <Button type={"submit"}>{"Start Search"}</Button>
            </div>
          </Form>
        )}
      </Formik>

      {data && (
        <Modal
          isShow={confirmation}
          closeHandler={() => setConfirmation(false)}
          type="simple"
          overlayClose={false}
        >
          <SearchConfirmation
            nextHandler={() => approveConfirmation()}
            backHandler={() => setConfirmation(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default StrategicAlignmentSearchForm;
