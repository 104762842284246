import React from "react";
import StrategicAlignmentLogo from "../../../assets/img/strategic-alignment-icon.svg";

const StrategicAlignmentIcon = () => {
  return (
    <div className="w-8 h-8 inline-flex items-center justify-center rounded">
      <img
        src={StrategicAlignmentLogo}
        className="w-5"
        alt="Strategic Alignment Search icon"
      />
    </div>
  );
};

export default StrategicAlignmentIcon;
