import React from "react";
import { useField, useFormikContext } from "formik";
import DateInput from "./DateInput";
import { dateFormatOptions } from "../../../utils/consts";

const DateInputField = (props: any) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <DateInput
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val: any) => {
        const d = val ? val.toLocaleDateString("en-US", dateFormatOptions) : "";
        setFieldValue(field.name, d);
      }}
    />
  );
};

export default DateInputField;
