import { ReactComponent as Upload } from "../../../assets/icons/upload.svg";
import { ReactComponent as Error } from "../../../assets/icons/error.svg";
import { ReactComponent as Uploaded } from "../../../assets/icons/uploaded.svg";
import { ReactComponent as Celebrate } from "../../../assets/icons/celebrate.svg";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import CongratsModal from "../../../components/shared/modal/CongratsModal";
import { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox } from "rsuite";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { FormikValues, useFormik } from "formik";
import { useFormContext } from "../../../contexts/FormContext";
import { MAX_FILE_SIZE, SUPPORTED_FORMATS } from "../../../constants";
import cx from "classnames";
import { Loader, Modal, Snackbar } from "../../../components/shared";
import { useAddPeopleaiProjectMutation } from "../../../features/peopleaiProjectsApi";
import toast from "react-hot-toast";
import { useUploadFilesMutation } from "../../../features/uploadApi";
import { useSendMailMutation } from "../../../features/mailApi";

const QuestionFive: React.FC = () => {
  const dragTimeout = useRef<HTMLDivElement>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [addPeopleaiProject] = useAddPeopleaiProjectMutation();
  const [sendMail] = useSendMailMutation();
  const [uploadFiles] = useUploadFilesMutation();
  const navigate = useNavigate();
  const {
    answers,
    otherIndustry,
    peopleAIFiles,
    setPeopleAIFiles,
    resetAnswers,
    currentQuestionIndex,
    setCurrentQuestionIndex,
    setOtherIndustry,
    setProgress,
    setQuestionFourFormValues,
    setQuestionTwoFormValues,
    questionFourInitialValues,
    questionTwoInitialValues,
    isTermsSelected,
    setIsTermsSelected,
    isPrivacySelected,
    setIsPrivacySelected,
    setIsFormCompleted,
  } = useFormContext();

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isValid,
    handleSubmit,
  } = useFormik({
    initialValues: {
      peopleAIFiles,
      isTermsSelected,
      isPrivacySelected,
    },
    validationSchema: Yup.object().shape({
      peopleAIFiles: Yup.array()
        .test(
          "fileSize",
          "Combined file size is too large. Maximum size is 25MB",
          (value: any) => {
            if (!value) return true;
            const totalSize = value.reduce(
              (acc: any, file: any) => acc + (file?.size || 0),
              0
            );
            return totalSize <= MAX_FILE_SIZE;
          }
        )
        .of(
          Yup.mixed()
            .test(
              "fileSize",
              "File size is too large. Maximum size is 25MB",
              (value: any) => value && value.size <= MAX_FILE_SIZE
            )
            .test(
              "fileFormat",
              "Unsupported file format. Only CSV and PDF are allowed",
              (value: any) => value && SUPPORTED_FORMATS.includes(value.type)
            )
        ),
      isTermsSelected: Yup.boolean().oneOf([true]),
      isPrivacySelected: Yup.boolean().oneOf([true]),
    }),
    onSubmit: async (values: FormikValues) => {
      await createProject();
    },
  });

  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.target && e.target.files) {
      e.preventDefault();
      const files = Array.from(e.target.files);
      setFieldValue("peopleAIFiles", files);
      setPeopleAIFiles(files);
      e.target.value = "";
    }
  };
  const handleDragOver = useCallback(
    (event: React.DragEvent<HTMLDivElement | HTMLLabelElement>) => {
      event.preventDefault();
      clearTimeout(dragTimeout.current as any);
    },
    []
  );
  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };
  const handleDragLeave = () => {
    (dragTimeout.current as any) = setTimeout(() => {
      setIsDragging(false);
    }, 200);
  };
  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement | HTMLLabelElement>) => {
      event.preventDefault();
      setIsDragging(false);
      const files = Array.from(event.dataTransfer.files);
      setFieldValue("peopleAIFiles", files);
      setPeopleAIFiles(files);
    },
    []
  );
  useEffect(() => {
    if (isTermsSelected && isPrivacySelected && isValid) {
      setIsFormCompleted(true);
    } else {
      setIsFormCompleted(false);
    }
  }, [values, isValid, currentQuestionIndex]);
  const createProject = async () => {
    if (isValid && answers) {
      const projectInfo = {
        reportType: answers["1"],
        ...answers["2"],
        industry: answers["3"] === "Other" ? otherIndustry : answers["3"],
        ...answers["4"],
        isRush: answers["4"]["isRush"] === "Yes" ? true : false,
        appointmentDate:
          answers["4"]["appointmentDate"] === ""
            ? null
            : answers["4"]["appointmentDate"],
        totalDocuments: 0,
        documents: [],
      };

      //Upload files only if user has uploaded files
      if (peopleAIFiles && peopleAIFiles.length > 0) {
        const formData = new FormData();
        peopleAIFiles?.forEach((file) => {
          formData.append("files", file);
        });
        try {
          setError(false);
          setLoading(true);
          const uploadedFiles: any = await uploadFiles(formData);
          if (
            uploadedFiles &&
            uploadedFiles.data &&
            uploadedFiles.data.length > 0
          ) {
            projectInfo.totalDocuments = uploadedFiles.data.length;
            projectInfo.documents = uploadedFiles.data;
          }
        } catch {
          projectInfo.totalDocuments = 0;
          console.error("Files are not uploaded");
          setError(true);
        } finally {
          setLoading(false);
        }
      }

      //Construct payload for mail
      const { totalDocuments, documents, isRush, appointmentDate, ...rest } =
        projectInfo;
      const mailData = new FormData();
      const mailInfo = {
        ...rest,
        isRush: isRush ? "Yes" : "No",
        ...(peopleAIFiles?.length > 0 && { documents: peopleAIFiles }),
        ...(appointmentDate !== null && { appointmentDate }),
      };
      for (const key in mailInfo) {
        if (key === "documents") {
          for (let i = 0; i < mailInfo.documents.length; i++) {
            mailData.append("documents", mailInfo.documents[i]);
          }
        } else {
          mailData.append(key, mailInfo[key]);
        }
      }

      //Once files are uploaded, create a project and send mail
      try {
        setLoading(true);
        const response = await addPeopleaiProject(projectInfo);
        if ("data" in response) {
          const mailResponse = await sendMail(mailData);
          toast.success((t) => (
            <Snackbar
              message={
                <>
                  People+AI Project <b>created.</b>
                </>
              }
              onClose={() => toast.dismiss(t.id)}
            />
          ));
          if ("data" in mailResponse) {
            setShowModal(true);
          }
        } else {
          toast.error((t) => (
            <Snackbar
              message={
                <>
                  <b>Failed</b> to create People+AI project.
                </>
              }
              onClose={() => toast.dismiss(t.id)}
            />
          ));
        }
      } catch {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      {loading && <Loader />}
      <form className="relative" onSubmit={handleSubmit}>
        {(peopleAIFiles?.length > 0 || errors.peopleAIFiles) && (
          <div
            className="absolute -top-3 -right-3 z-40 p-1 bg-white border border-dorothy-teal rounded-full cursor-pointer responsive-abort"
            onClick={(e: any) => {
              e.stopPropagation();
              setPeopleAIFiles([]);
              setFieldValue("peopleAIFiles", []);
            }}
          >
            <Cancel />
          </div>
        )}
        <label
          className={cx(
            "flex justify-center items-center border-2 border-dorothy-dark-gray rounded-[10px] py-44 px-20 cursor-pointer hover:border-dorothy-teal transition duration-200 hover:bg-dorothy-teal/5 responsive-form-upload",
            {
              "!border-red-500 hover:!border-red-500 hover:bg-transparent":
                !!errors.peopleAIFiles,
              "!border-dorothy-teal !bg-dorothy-teal/5": isDragging,
            }
          )}
          htmlFor="file"
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <div className="flex flex-col items-center gap-y-3">
            {peopleAIFiles.length > 0 && !errors.peopleAIFiles ? (
              <>
                <Uploaded />
                <span className="text-center text-base text-black/50">
                  Files selected to upload
                </span>
              </>
            ) : errors.peopleAIFiles ? (
              <>
                <Error />
                <span className="text-center text-base text-red-400 max-w-lg">
                  Please note that maximum file size is 25 MB and allowed file
                  formats are CSV and PDF.
                </span>
              </>
            ) : (
              <>
                <Upload />
                <span className="text-center text-base text-black/50">
                  Click to upload or drag it here
                </span>
              </>
            )}

            <input
              type="file"
              name="peopleAIFiles"
              multiple
              className="hidden"
              id="file"
              onChange={handleFile}
            />
          </div>
        </label>
        <div className="flex justify-between items-center mt-4">
          <div className="flex flex-col">
            <label
              className="flex items-center cursor-pointer"
              htmlFor="isTermsSelected"
            >
              <Checkbox
                id="isTermsSelected"
                name="isTermsSelected"
                checked={values.isTermsSelected}
                onChange={(value, checked) => {
                  setIsTermsSelected(checked);
                  setFieldTouched("isTermsSelected", true);
                  setFieldValue("isTermsSelected", checked);
                }}
              />
              <div
                id="isTermsSelected"
                className={cx({
                  "!text-red-500":
                    errors.isTermsSelected && touched.isTermsSelected,
                })}
              >
                I have read and agree to the{" "}
                <Link
                  to="/terms-of-use"
                  className={cx("text-dark-blue font-semibold", {
                    "!text-red-500":
                      errors.isTermsSelected && touched.isTermsSelected,
                  })}
                >
                  terms and conditions
                </Link>
                .
              </div>
            </label>
            <label
              className="flex items-center cursor-pointer"
              htmlFor="isPrivacySelected"
            >
              <Checkbox
                id="isPrivacySelected"
                name="isPrivacySelected"
                checked={values.isPrivacySelected}
                onChange={(value, checked) => {
                  setIsPrivacySelected(checked);
                  setFieldTouched("isPrivacySelected", true);
                  setFieldValue("isPrivacySelected", checked);
                }}
              />
              <div
                id="isPrivacySelected"
                className={cx({
                  "!text-red-500":
                    errors.isPrivacySelected && touched.isPrivacySelected,
                })}
              >
                I have read and agreed to the{" "}
                <Link
                  to="/privacy-policy"
                  className={cx("text-dark-blue font-semibold", {
                    "!text-red-500":
                      errors.isPrivacySelected && touched.isPrivacySelected,
                  })}
                >
                  privacy policy
                </Link>
                .
              </div>
            </label>
          </div>
          <button
            type="submit"
            className="right-0 -bottom-[64px] bg-dorothy-teal cursor-pointer disabled:bg-dorothy-teal/50 text-white px-9 py-4 rounded-[10px] hover:bg-dorothy-teal/80 transition duration-200"
            disabled={
              !isTermsSelected || !isPrivacySelected || !!errors.peopleAIFiles
            }
          >
            Submit
          </button>
        </div>
      </form>
      <CongratsModal
        title="Congratulations!"
        isShow={showModal}
        closeHandler={() => {
          setShowModal(false);
          setCurrentQuestionIndex(1);
          resetAnswers();
          setIsPrivacySelected(false);
          setIsTermsSelected(false);
          setOtherIndustry("");
          setPeopleAIFiles([]);
          setProgress(0);
          setQuestionFourFormValues(questionFourInitialValues);
          setQuestionTwoFormValues(questionTwoInitialValues);
          navigate(`/peopleai`);
        }}
        description="Your request has been submitted successfully. Our agent will connect you within 24 hours."
        icon={<Celebrate />}
        overlayClose
      />
      <Modal
        overlayClose
        isShow={error}
        closeHandler={() => setError(false)}
        title="Error"
        closeButton
      >
        <span className="font-bold text-red-500">Something went wrong</span>
      </Modal>
    </>
  );
};

export default QuestionFive;
