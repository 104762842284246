import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { months } from "../../../utils/consts";
import { yearRange } from "../../../utils/helpers";
import "./style.scss";

export interface IDateInput {
  placeholder?: string;
  label?: string;
  name: string;
  value?: string | number;
  required?: boolean;
  selected?: any;
  onChange?: any;
  error?: boolean;
  errorMsg?: string;
  disabled?: boolean;
  isClearable?: boolean;
  tabIndex?: number;
}

const DateInput: React.FC<IDateInput> = ({
  placeholder,
  label,
  name,
  required,
  selected,
  onChange,
  error,
  errorMsg,
  disabled = false,
  isClearable = false,
  tabIndex,
}) => {
  const currentYear = new Date().getFullYear();
  const years = yearRange(1960, currentYear, 1);
  
  const pickerRef = useRef(null);
  const tabHandler = (e: any) => {
    if (e.key === "Tab") {
      setTimeout(() => {
        // @ts-ignore: Unreachable code error
        pickerRef?.current?.setOpen(false);
      }, 50);
    }
  };

  return (
    <label>
      {label && (
        <span className="mb-1 text-sm block">
          {label} {required && <b className="text-error">*</b>}
        </span>
      )}
      <DatePicker
        className={`bg-white border border-gray-300 rounded p-3 text-sm h-11 w-full placeholder:text-gray-400 ${
          disabled && "!bg-gray-100"
        }`}
        ref={pickerRef}
        dateFormat="MM/dd/yyyy"
        selected={selected}
        onChange={onChange}
        placeholderText={placeholder}
        disabled={disabled}
        name={name}
        isClearable={isClearable}
        autoComplete="off"
        tabIndex={tabIndex}
        onKeyDown={tabHandler}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              tabIndex={-1}
              className="mr-2 bg-white w-5 h-5 rounded text-center hover:bg-gray-300"
            >
              <ChevronLeftIcon className="w-3 h-3 inline-block" />
            </button>
            <select
              value={date.getFullYear()}
              onChange={({ target }) => {
                changeYear(parseInt(target.value, 10));
              }}
              tabIndex={-1}
              className="bg-transparent"
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              tabIndex={-1}
              className="bg-transparent"
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              tabIndex={-1}
              className="ml-2 bg-white w-5 h-5 rounded text-center hover:bg-gray-300"
            >
              <ChevronRightIcon className="w-3 h-3 inline-block" />
            </button>
          </div>
        )}
      />
      {error && errorMsg ? (
        <span className="block text-error text-xs mt-1">{errorMsg}</span>
      ) : (
        ""
      )}
    </label>
  );
};

export default DateInput;
