const infoContents: any = {
  strategicAlignmentSearchInfo: `
			<div style="margin-bottom: 10px">
				<b>How do Strategic Alignment searches work?</b>
			</div>
			<div style="margin-bottom: 10px;">
        Activ8 novelty searches the world's patent database using a series of search models including advanced NLP based word and sentencing embeddings models, CPC classification, word co-locations, cited references, and synonimization. 
			</div>
			<div>
        The results from each of these models are recombined and sorted by reference using a neural net that was specifically trained to sort search results by relevance.
			</div>`,
  noveltySearchInfo: `
			<div style="margin-bottom: 10px">
				<b>How do Novelty Searches Work?</b>
			</div>
			<div style="margin-bottom: 10px">
				Activ8 novelty searches the world's patent database using a series of search models including advanced NLP based word and sentencing embeddings models, CPC classification, word co-locations, cited references, and synonimization. 
			</div>
			<div>
				The results from each of these models are recombined and sorted by reference using a neural net that was specifically trained to sort search results by relevance.
			</div>`,
  freedomSearchInfo: `
			<div style="margin-bottom: 10px">
				<b>How do Freedom Searches Work?</b>
			</div>
			<div style="margin-bottom: 10px">
				Activ8 freedom searches the claims of issued US patents for the components of the product being searched. Each component, or "element," of the product is defined individually. 
			</div>
			<div style="margin-bottom: 10px">
				The combined query is used to create a universe of patents that describe the individual components of the product and the product itself. 
			</div>
			<div>
			The claims of this universe of patents are then searched for the individual elements and claims that include one or more of these elements are returned to the user. Activ8 Freedom uses an ensemble of search models that was specifically created to search patent claims.
			</div>`,
  featureWightInfo: `<div><b>Weighting</b>
    <div>Weighting is used to allow the user to easily manipulate the breadth of the search without modifying the query itself. </div>
    <ol class="tooltip-list top-margin">
      <li>
        "High" weight puts more emphasis on the novel feature portion of the query
        and produces a more narrow set of search results that discuss the novel
        feature.
      </li>
      <li>"Medium" produces an optimized set of search results.</li>
      <li>
        "Low" weight emphasizes the invention disclosure and produces a more broad
        set of search results that contain the novel feature.
      </li>
    </ol>
    </div>`,
  elementWightInfo: `<div><b>Weighting</b>
    <div>Weighting Activ8 Freedom elements allows the user to decide which priority elements are the most important. </div>
    <ol class="tooltip-list top-margin">
      <li>
        "High" weight puts more emphasis on patent claims that include this element, moving these claims higher on the list of search results.
      </li>
      <li>"Medium" produces an optimized set of search results.</li>
      <li>
        "Low" weight de-emphasizes this element, moving these claims lower on the list of search results.
      </li>
    </ol>
    </div>`,
  elementRequiredInfo: `<div><b>Required Checkbox</b>
    <div>Activ8 Freedom includes a priority element checkbox below the query box. "Required" are selected by the user as potentially carrying freedom-to-operate risk.</div>
    <ol class="tooltip-list top-margin">
      <li>
      Unchecked boxes are used to give the platform context but are given less weight than priority elements. Claims containing non-priority elements appear lower in the list of search results. We’ve found that common elements of products that are off-patent or whose rights are exhausted can push more important elements down the list or search results making these important patents harder to find.
      </li>
      <li>
      Checked boxes ensure that claims describing the important elements are at the top of the results list.
      </li>
    </ol>
    </div>`,
  searchDateTypeInfo: `
		<div>
			<div style="margin-bottom: 10px">
				<b>Priority / Application Date:</b> The earliest filing date for the patent applications identified in the search.
			</div>
			<div style="margin-bottom: 10px">
				<b>Publication Date:</b> The date on which the patent applications identified in the search were first published.
			</div>
			<div>
				<b>Issue / Grant Date:</b> The date on which the patent was granted and enforceable rights are attributed to the claims of the patent.
			</div>
    </div>`,
};

export default infoContents;
