import { CheckCircleIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import {
  INoveltySearch,
  useDeleteNoveltySearchMutation,
} from "../../features/noveltySearchApi";
import { dateFormatOptions } from "../../utils/consts";
import { dateFormatter } from "../../utils/helpers";
import {
  Button,
  FreedomIcon,
  Label,
  Modal,
  NoveltyIcon,
  QueueIcon,
  Snackbar,
} from "../shared";
import toast from "react-hot-toast";
import { useDeleteFreedomSearchMutation } from "../../features/freedomSearchApi";

interface ISearchItem {
  label?: string;
  type?: string;
  data: INoveltySearch;
  onClick: any;
}

const SearchItem: React.FC<ISearchItem> = ({
  label,
  type = "novelty",
  data,
  onClick,
}) => {
  const [deleteSearch, setDeleteSearch] = useState(false);
  const [confirmModalId, setConfirmModalId] = useState<any>(null);

  const [deleteNoveltySearch] = useDeleteNoveltySearchMutation();
  const [deleteFreedomSearch] = useDeleteFreedomSearchMutation();
  const date = data.createdAt
    ? new Date(data.createdAt).toLocaleDateString("en-US", dateFormatOptions)
    : "(date_error)";

  let results: any = "";
  let clickableStyles: string = "";
  if (data.status === "ready") {
    clickableStyles = "cursor-pointer hover:shadow-lg";
    results = (
      <>
        <span>{data.totalSaved ? data.totalSaved : "0"} saved</span>
        <span>{data.totalResult ? data.totalResult : "0"} result</span>
      </>
    );
  }
  const deleteHandler = () => {
    const performDelete =
      type === "novelty"
        ? deleteNoveltySearch(confirmModalId)
        : type === "freedom"
        ? deleteFreedomSearch(confirmModalId)
        : null;
    performDelete?.then((response) => {
      if ("data" in response) {
        toast.success((t) => (
          <Snackbar
            message={
              <>
                Search item <b>deleted.</b>
              </>
            }
            onClose={() => toast.dismiss(t.id)}
          />
        ));
      } else {
        toast.error((t) => (
          <Snackbar
            message={
              <>
                <b>Failed</b> to delete search item.
              </>
            }
            onClose={() => toast.dismiss(t.id)}
          />
        ));
      }
    });
    setConfirmModalId(null);
  };
  return (
    <>
      <div
        className={`relative group bg-white flex rounded-xl p-5 text-xs ${clickableStyles}`}
        onClick={data.status === "ready" ? onClick : null}
      >
        {data.status !== "inQueue" && (
          <span
            className="hidden group-hover:block absolute -right-1 -top-1 text-[9px] text-white bg-dorothy-dark-orange rounded-full px-1.5 py-0.5"
            onClick={(e) => {
              e.stopPropagation();
              setDeleteSearch(true);
              setConfirmModalId(data?.id);
            }}
          >
            <i className="fas fa-trash" />
          </span>
        )}

        <div className="flex-none pr-4">
          {type === "freedom" ? <FreedomIcon /> : <NoveltyIcon />}
        </div>
        <div className="flex-1 font-normal flex flex-col justify-between">
          <div>
            <div className="mb-1 flex flex-row justify-between">
              <div>
                <span className="font-light opacity-50">Search ID:</span>{" "}
                {data.id}
              </div>
              <div>
                {dateFormatter(data.createdAt, "frontend")}{" "}
                {data.createdAt && (
                  <span className="font-light opacity-50">
                    {new Date(data.createdAt).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                )}
              </div>
            </div>
            <div className="font-bold break-all">{label}</div>
          </div>
          <div>
            <div className="font-normal mt-3">{date}</div>
            <div className="mt-3 flex justify-between items-center">
              <div className="mr-1">
                {data.status === "inQueue" ? (
                  <Label color="orange" icon={<QueueIcon />}>
                    In Queue
                  </Label>
                ) : (
                  <Label
                    color="darkgray"
                    icon={<CheckCircleIcon className="w-4 h-4" />}
                  >
                    Ready
                  </Label>
                )}
              </div>
              <div className="flex gap-2 text-right">{results}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isShow={deleteSearch}
        closeButton
        overlayClose
        closeHandler={() => {
          setDeleteSearch(false);
          setConfirmModalId(null);
        }}
        title="Delete a search"
      >
        <div className="flex flex-col gap-y-4">
          <span className="font-semibold">
            Are you sure you want to delete this search item?
          </span>
          <div className="-mx-7 px-7 mt-5 flex gap-x-3 justify-end">
            <Button
              variant="outlined"
              onClick={() => setDeleteSearch(false)}
              className="focus:!ring-0 focus:!ring-offset-0"
            >
              Cancel
            </Button>

            <Button variant="filled" type="submit" onClick={deleteHandler}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SearchItem;
