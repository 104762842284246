import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h2 className="text-2xl font-sweetsanspro font-semibold mb-11">
        Privacy Policy
      </h2>
      <span>
        This Privacy Policy applies to our DorothyAI websites, including
        <a
          href="https://www.dorothyai.com"
          target="_blank"
          className="font-semibold text-dark-blue underline pl-1"
        >
          www.dorothyai.com
        </a>
        ,
        <a
          href="https://search.dorothyai.com"
          target="_blank"
          className="font-semibold text-dark-blue underline pl-1"
        >
          search.dorothyai.com
        </a>
        , and
        <a
          href="https://dorothyai.community"
          target="_blank"
          className="font-semibold text-dark-blue underline pl-1"
        >
          dorothyai.community
        </a>
        , and the services available through these websites (collectively,
        "Sites"). This Privacy Policy is intended to disclose how DorothyAI
        collects, uses and discloses personal information about users of our
        Sites, as well as to explain the choices and right individuals have
        regarding their personal information.
      </span>
      <br />
      <br />
      <ol className="list-decimal pl-8">
        <li className="font-bold">Additional privacy notices</li>
        <li className="font-bold">Information we collect</li>
        <li className="font-bold">Use of personal information</li>
        <li className="font-bold">Disclosure of personal information</li>
        <li className="font-bold">Cookies and similar devices</li>
        <li className="font-bold">Direct marketing</li>
        <li className="font-bold">International transfer</li>
        <li className="font-bold">Access and correction</li>
        <li className="font-bold">Third party websites and links</li>
        <li className="font-bold">Co-sponsored events</li>
        <li className="font-bold">Our commitment to security</li>
        <li className="font-bold">How to contact us</li>
        <li className="font-bold">Revisions to our Privacy Policy</li>
        <li className="font-bold">
          Additional Privacy Information for California Residents
        </li>
      </ol>
      <br />
      <br />
      <ol className="list-decimal pl-8">
        <li className="font-bold">Additional privacy notices</li>
        <ul className="mt-2 mb-6 pl-8 list-disc">
          <li>
            <strong>California Consumer Privacy Act.</strong> If you are a
            California resident, please review the Additional privacy
            information for California residents section below, for important
            information about how we collect, use and disclose personal
            information about California residents and the rights California
            residents have under the California Consumer Privacy Act.
          </li>
          <br />
          <li>
            <strong>GDPR.</strong> Our GDPR Privacy Notice contains additional
            information about how DorothyAI processes personal information that
            is subject to EU data protection laws (including those implementing
            the EU General Data Protection Regulation, or "GDPR"), and
            encompasses personal information about users of the Sites and others
            with whom we engage.
          </li>
        </ul>
        <li className="font-bold">Information we collect</li>
        <p className="mt-2 mb-6">
          <span>
            We collect information directly from individuals online, as well as
            automatically through the use of our Sites.
          </span>
          <br />
          <br />
          <ul className="pl-8 list-disc">
            <li className="mb-2">
              <strong>Information collected directly.</strong> We may collect
              personal information about you—such as your name, address,
              telephone number, e-mail address, etc.—directly from you. For
              example, personal information may be collected when you fill out a
              'Contact Us' form, sign up for our mailing lists, register for
              events we host or sponsor, submit information as part of certain
              online services (e.g. DorothyAI User Community), post comments on
              our blogs, or otherwise provide us information through the Sites.
            </li>
            <li className="mb-2">
              <strong>Automatically-collected information.</strong> We use
              cookies, log files, pixel tags, local storage objects and other
              tracking technologies to automatically collect information when
              users access or use the services or visit our Sites, such as an IP
              address, domain name, page views, a date/time stamp, browser type,
              device type, device ID, Internet service provider ("ISP"),
              referring/exit URLs, operating system, language, clickstream data,
              and other information about the links clicked, features used, size
              of files uploaded, streamed or deleted, and similar device and
              usage information. For more information, see the "Cookies and
              similar devices" section below.
            </li>
            <li>
              <strong>Information received from third parties.</strong> We may
              collect or receive information about individuals from service
              providers, and other third parties, such as our clients,
              representatives and professional advisers, content providers,
              government authorities and public sources and records.
            </li>
          </ul>
        </p>
        <li className="font-bold">Use of personal information</li>
        <p className="mt-2 mb-6">
          <span>
            We use the personal information you provide to respond to your
            request or inquiry and in the ordinary course of conducting our
            business. Generally, we use the information (including personal
            information) that we collect online as follows:
          </span>
          <br />
          <br />
          <ul className="pl-8 list-disc">
            <li className="mb-2">
              <strong>Providing support and services.</strong> To provide and
              operate our Sites, communicate with you about your use of the
              Sites, provide troubleshooting and technical support, respond to
              your inquiries, fulfill your orders and requests, process your
              payments, communicate with you, and for similar service and
              support purposes.
            </li>
            <li className="mb-2">
              <strong>Responding to your requests.</strong> To respond to your
              inquiries, fulfill your orders and requests, and consider your
              request or application (e.g. if you have submitted a resume or
              other application information online or by email, we will use it
              as part of the application review process).
            </li>
            <li className="mb-2">
              <strong>Personalization.</strong> To tailor content we may send or
              display on the Sites, including to offer location customization
              and personalized help and instructions, and to otherwise
              personalize your experiences.
            </li>
            <li className="mb-2">
              <strong>Newsletters, mailing lists and direct marketing.</strong>{" "}
              For direct marketing purposes, including to send you newsletters,
              alerts, and information we think may interest you. If you are
              located in a jurisdiction that requires opt-in consent to receive
              electronic marketing messages, we will only send you such messages
              if you opt-in to receive them.
            </li>
            <li className="mb-2">
              <strong>Analytics and improvement.</strong> To better understand
              how users access and use the Sites, and our other products and
              offerings, and for other research and analytical purposes, such as
              to evaluate and improve our services and business operations and
              to develop services and features.
            </li>
            <li className="mb-2">
              <strong>Protect our legal rights and prevent misuse.</strong> To
              protect the Sites and our business operations; to prevent and
              detect fraud, unauthorized activities and access, and other
              misuse; where we believe necessary to investigate, prevent or take
              action regarding illegal activities, suspected fraud, situations
              involving potential threats to the safety or legal rights of any
              person or third party, or violations of our Terms of Use or this
              Privacy Policy.
            </li>
            <li className="mb-2">
              <strong>Comply with legal obligations.</strong> To comply with the
              law or legal proceedings. For example, we may disclose information
              in response to subpoenas, court orders, and/or other lawful
              requests by regulators and/or law enforcement, including
              responding to national security or law enforcement disclosure
              requirements.
            </li>
            <li className="mb-2">
              <strong>General business operations.</strong> Where necessary, for
              the administration of our general business, accounting, record
              keeping and legal functions.
            </li>
            <li className="mb-2">
              <strong>Anonymous and de-identified information.</strong> We
              create and use anonymous and de-identified information to assess,
              improve and develop our business, products and services, and for
              similar research and analytics purposes.
            </li>
          </ul>
        </p>
        <li className="font-bold">Disclosure of personal information</li>
        <p className="mt-2 mb-6">
          <span>
            Any information that we collect or that you provide to us will only
            be processed by DorothyAI. We will not rent or sell your personal
            information, nor will we share it with third parties, outside of
            DorothyAI, for their marketing purposes.
          </span>
          <br />
          <br />
          <span>
            We may share personal information with the following categories of
            third parties:
          </span>
          <br />
          <br />
          <ul className="pl-8 list-disc">
            <li className="mb-2">
              Third party service providers that provide services to us or to
              whom we outsource certain services, such as data hosting or
              software or technology services.
            </li>
            <li className="mb-2">
              Content providers that we partner with to make available CLEs,
              training and other content or resources. We do not share your
              information with them for their own independent use; however, if
              you register for an account with one of these third parties they
              will use the information you provide to them subject to their own
              privacy policy and terms.
            </li>
            <li className="mb-2">
              Our professional advisers, such as lawyers and accountants.
            </li>
            <li className="mb-2">Government and/or regulatory authorities.</li>
            <li className="mb-2">Professional indemnity insurers.</li>
            <li className="mb-2">
              Regulators, tax authorities and/or corporate registries.
            </li>
          </ul>
          <br />
          In addition, we may disclose personal information to other third
          parties under the following circumstances:
          <br />
          <br />
          <ul className="pl-8 list-disc">
            <li className="mb-2">
              <strong>Business transfers.</strong> As part of any merger, sale
              and transfer of our assets, acquisition or restructuring of all or
              part of our business, bankruptcy, or similar event, including
              related to due diligence conducted prior to such event where
              permitted by law.
            </li>
            <li className="mb-2">
              <strong>Where legally required.</strong> If we are required to do
              so by law (eg, to law enforcement, courts or others, eg, in
              response to a subpoena or court order).
            </li>
            <li className="mb-2">
              <strong>Protect our rights.</strong> Where we believe it necessary
              to respond to claims asserted against us, comply with legal
              processes (e.g. subpoenas or warrants), enforce or administer our
              agreements and terms, or for fraud prevention, risk assessment,
              investigation, and to protect the rights, property or safety of
              DorothyAI, our customers, shareholders, or others.
            </li>
          </ul>
          <br />
          We may also share aggregate or de-identified information with third
          parties for research, marketing, analytics and other purposes,
          provided such information does not identify a particular individual.
        </p>
        <li className="font-bold">Cookies and similar devices</li>
        <p className="mt-2 mb-6">
          <span>
            We and our service providers may use cookies, pixels, log files and
            other mechanisms to gather information about your use of our Sites.
          </span>
          <br />
          <br />
          <ul className="pl-8 list-disc">
            <li className="mb-2">
              <strong>Cookies.</strong> Cookies are alphanumeric identifiers
              that we transfer to your computer's hard drive through your web
              browser for record-keeping purposes. Some cookies allow us to make
              it easier for you to navigate our Site. Others are used to enable
              a faster log-in process or to allow us to track your activities
              while using our Site. Most web browsers automatically accept
              cookies, but if you prefer, you can edit your browser options to
              block them in the future. The Help portion of the toolbar on most
              browsers will tell you how to prevent your computer from accepting
              new cookies, how to have the browser notify you when you receive a
              new cookie, or how to disable cookies altogether.
            </li>
            <li className="mb-2">
              <strong>Clear GIFs, pixel tags and other technologies.</strong>{" "}
              Clear GIFs are tiny graphics with a unique identifier, similar in
              function to cookies. In contrast to cookies, which are stored on
              your computer's hard drive, clear GIFs are embedded invisibly on
              web pages. We may use clear GIFs (also referred to as web beacons,
              web bugs, or pixel tags) in connection with our services to, among
              other things, track the activities of users of our services, help
              us manage content, and compile statistics about usage of our
              services. We and our third party service providers also use clear
              GIFs in HTML emails to our customers, to help us track email
              response rates, to identify when our emails are viewed, and to
              track whether our emails are forwarded.
            </li>
            <li className="mb-2">
              <strong>Log files.</strong> Most browsers collect certain
              information, such as your IP address, device type, screen
              resolution, operating system version, and internet browser type
              and version. This information is gathered automatically and stored
              in log files.
            </li>
            <li className="mb-2">
              <strong>Third party analytics.</strong> We use automated devices
              and applications, such as Google Analytics to evaluate the use of
              our services. We use these tools to gather non-personal data about
              users to help us improve our services and user experiences. These
              analytics providers may use cookies and other technologies to
              perform their services, and may combine the information they
              collect about you on our Sites with other information they have
              collected for their own purposes. This Policy does not cover such
              uses of data by third parties.
            </li>
            <li className="mb-2">
              <strong>Do-not-track signals.</strong> Our Sites do not respond to
              do-not-track signals. You may, however certain tracking as
              discussed above (e.g. by disabling cookies) and as set out in our
              Cookie Policy.
            </li>
          </ul>
        </p>
        <li className="font-bold">Direct marketing</li>
        <p className="mt-2 mb-6">
          <span>
            We may send periodic promotional emails to you, and where required
            by law, we will obtain your consent to do so. You may opt out of
            such communications at any time by following the opt-out
            instructions contained in the email or email us at
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="font-semibold text-dark-blue underline pl-1"
            >
              customers@dorothyai.com
            </a>
            . If you opt out of receiving emails, we may still send you emails
            about your account or any services you have requested or received
            from us.
          </span>
        </p>
        <li className="font-bold">International transfers</li>
        <p className="mt-2 mb-6">
          <span>
            When you submit personal information to us, or when others provide
            personal information to us, we will receive it and process it in the
            United States or where we have service providers. In order to
            provide services, we also may need to transfer your personal
            information to locations in other jurisdictions.
          </span>
          <br />
          <br />
          <span>
            If you are based within European Economic Area (EEA), please note
            that where necessary to deliver the services, we will transfer
            personal information to countries outside the EEA. The DorothyAI
            privacy policy is based on the EU standard contractual clauses to
            provide appropriate safeguards and an adequate level of protection
            for personal information.
          </span>
          <br />
          <br />
          <span>
            You have a right to obtain details of the mechanism under which your
            personal information is transferred outside of the EEA. You can
            request such details by contacting us as
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="font-semibold text-dark-blue underline pl-1"
            >
              customers@dorothyai.com
            </a>
            .
          </span>
        </p>
        <li className="font-bold">Access and correction</li>
        <p className="mt-2 mb-6">
          <span>
            If you would like to review the personal information we have
            collected about you via the Sites, or request that we update or
            delete it, please contact us at
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="font-semibold text-dark-blue underline pl-1"
            >
              customers@dorothyai.com
            </a>
            .
          </span>
        </p>
        <li className="font-bold">Third party websites and links</li>
        <p className="mt-2 mb-6">
          <span>
            Our Sites may contain links to other sites whose information
            practices may be different than ours. If you visit these sites, this
            Privacy Policy will not apply. Visitors should consult the other
            sites' privacy notices regarding information that is submitted to,
            or collected by, these third parties.
          </span>
        </p>
        <li className="font-bold">Co-sponsored events</li>
        <p className="mt-2 mb-6">
          <span>
            DorothyAI may post information about programs and events that are
            sponsored by or co-sponsored by third parties. Should you choose to
            register electronically for any of these events or submit your
            information to these third parties, this Privacy Policy will not
            apply. DorothyAI will have no control over any third party sponsors'
            use of this information.
          </span>
        </p>
        <li className="font-bold">Our commitment to security</li>
        <p className="mt-2 mb-6">
          <span>
            We employ security measures to prevent unauthorized access to
            information that we collect online. However, we cannot guarantee the
            security of your personal information.
          </span>
        </p>
        <li className="font-bold">How to contact us</li>
        <p className="mt-2 mb-6">
          <span>
            If you have any questions or concerns about this Privacy Policy or
            its implementation, you may contact us at
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="font-semibold text-dark-blue underline pl-1"
            >
              customers@dorothyai.com
            </a>
            . If at any time you decide that we should no longer hold any of
            your personal information, or you wish to change the use to which
            any such information can be put, please let us know by emailing us
            at
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="font-semibold text-dark-blue underline pl-1"
            >
              customers@dorothyai.com
            </a>
            .
          </span>
        </p>
        <li className="font-bold">Revisions to our Privacy Policy</li>
        <p className="mt-2 mb-6">
          <span>
            We reserve the right to revise this Privacy Policy or any part of it
            from time to time. Please review the Policy periodically for
            changes. If we make material changes, we will endeavor to provide
            notice ahead of such changes, such as by email or through a notice
            on
            <a
              href="https://www.dorothyai.com"
              target="_blank"
              className="font-semibold text-dark-blue underline pl-1"
            >
              www.dorothyai.com
            </a>
            .
          </span>
        </p>
        <li className="font-bold">
          Additional Privacy Information for California Residents
        </li>
        <p className="mt-2 mb-6">
          <span>
            This section of our Policy (the "California Notice") provides
            additional information for California residents, as required under
            the California Consumer Privacy Act of 2018 ("CCPA"). This section
            is effective January 1, 2020. Please note that as of the effective
            date of this Policy, the CCPA implementing regulations have not been
            finalized by the California Attorney General. Accordingly, we may
            update this section, our relevant data practices, or our processes
            for handling CCPA requests, in response to the final CCPA
            regulations or other CCPA legal developments.
          </span>
          <br />
          <br />
          <ul className="pl-8 list-disc">
            <li className="mb-2">
              <div className="mb-2">
                <strong>Scope.</strong> This California Notice applies to the
                personal information we collect, both online and offline, about
                California consumers, including personal information that we
                collect:
              </div>
              <ul className="list-disc pl-8">
                <li className="mb-1">
                  From users of our Sites, including the services available via
                  our Sites About clients and individuals that use or inquire
                  about our services (“Services”)
                </li>
                <li className="mb-1">
                  About individuals that attend events hosted or sponsored by
                  us, reply to our emails or communications, or otherwise
                  communicate or engage with us
                </li>
                <li>
                  About individuals from clients and others related to the
                  Services we provide
                </li>
              </ul>
            </li>
            <li className="mb-2">
              <strong>Personal information.</strong> As used in this California
              Notice, “personal information” means any information that
              identifies, relates to, describes, is reasonably capable of being
              associated with, or could reasonably be linked, directly or
              indirectly, with a particular California resident or household; it
              does not include publicly available information made lawfully
              available by state or federal governments or deidentified
              information that cannot reasonably identify, relate to, describe,
              be capable of being associated with, or be linked directly or
              indirectly to a particular individual.
            </li>
            <li className="mb-2">
              <div className="mb-2">
                <strong>Not covered by this California Notice.</strong> This
                California Notice does not address or apply to:
              </div>
              <ul className="list-disc pl-8">
                <li className="mb-1">
                  Our handling of personal information that is exempt under
                  Section 1798.145 of the CCPA,
                </li>
                <li className="mb-1">
                  Personal information we collect about employees, contractors
                  or job applicants or other individuals who are not California
                  residents, or
                </li>
                <li>
                  Personal information we collect about individuals acting in
                  their capacity as representatives (“B2B contacts”) of our
                  clients, prospective clients, vendors and other businesses
                  that we conduct business with to the extent we use their
                  personal information only in the context of conducting our
                  business relationship with the respective business.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              <div className="mb-2">
                <strong>
                  Categories of personal information that we collect and
                  disclose.
                </strong>{" "}
                Our collection, use and disclosure of personal information about
                a California resident will vary depending upon the circumstances
                and nature of our interactions or relationship with such
                resident. Pursuant to the CCPA, we may collect and disclose for
                a business purpose, and have done so in the prior 12 months, the
                following categories of personal information (as defined by the
                CCPA):
              </div>
              <ul className="list-disc pl-8">
                <li className="mb-1">
                  <strong>Identifiers:</strong> such as a real name, alias,
                  address, unique personal identifier, online identifier,
                  Internet Protocol (IP) address, email address, account name,
                  social security number, driver’s license number, passport
                  number, or other similar identifiers.
                </li>
                <li className="mb-1">
                  <strong>
                    Customer records (categories of personal information set
                    forth in Cal. Civ. Code 1798.80(e)):
                  </strong>{" "}
                  paper and electronic customer records containing personal
                  information, such as name, signature, physical characteristics
                  or description, address, telephone number, education, current
                  employment, employment history, social security number,
                  passport number, driver’s license or state identification card
                  number, insurance policy number, bank account number, credit
                  card number, debit card number, or any other financial or
                  payment information, medical information, or health insurance
                  information.
                </li>
                <li className="mb-1">
                  <strong>
                    Characteristics of protected classifications under
                    California or federal law:
                  </strong>{" "}
                  such as race, color, sex, age, religion, national origin,
                  disability, citizenship status, and genetic information.
                </li>
                <li className="mb-1">
                  <strong>Commercial information:</strong> including records of
                  property, products or services purchased, obtained, or
                  considered, or other purchasing or use histories or
                  tendencies.
                </li>
                <li className="mb-1">
                  <strong>
                    Internet or other electronic network activity information:
                  </strong>{" "}
                  including but not limited to browsing history, search history,
                  and information regarding a consumer’s interaction with an
                  Internet website, application, or advertisement.
                </li>
                <li className="mb-1">
                  <strong>Geolocation data:</strong> precise location
                  information about a particular individual or device.
                </li>
                <li className="mb-1">
                  <strong>
                    Audio, electronic, visual, olfactory, or similar
                    information:
                  </strong>{" "}
                  such as CCTV footage, photographs, and audio recordings.
                </li>
                <li className="mb-1">
                  <strong>Employment information:</strong> professional or
                  employment-related information.
                </li>
                <li className="mb-1">
                  <strong>Education information:</strong> information that is
                  not publicly available personally identifiable information as
                  defined in the Federal Education Rights and Privacy Act (20
                  U.S.C. Sec. 1232g; 34 C.F.R. Part 99)
                </li>
                <li className="mb-1">
                  <strong>
                    Inferences from personal information collected:
                  </strong>{" "}
                  inferences drawn from any of the information identified above
                  to create a profile reflecting a resident’s preferences,
                  characteristics, behavior or attitudes.
                </li>
                <li>
                  We do not sell, and have not in the prior 12 months sold,
                  personal information about California residents. We do
                  disclose personal information for business purposes as
                  described in the disclosure of personal information section
                  above.
                </li>
              </ul>
            </li>
            <li className="mb-2">
              <strong>Sources of personal information.</strong> We may collect
              the above categories of personal information directly from you,
              automatically about your use of our Sites, and from third parties
              such as customers, government and public entities, public records,
              data aggregators and resellers, and others (see the Information we
              collect section above for more information).
            </li>
            <li className="mb-2">
              <strong>
                Purposes for collecting, using and disclosing personal
                information.
              </strong>{" "}
              We use these categories of personal information we collect to
              provide our legal and other Services, to operate our Sites, to
              optimize and personalize your experiences with our Sites (e.g. to
              recognize or store your location, language or other settings), to
              analyze and improve our Sites and Services, to provide customer
              support and respond to requests, to comply with legal and ethical
              obligations and regulatory requirements, in support of our
              business operations (e.g. accounting, record keeping and legal
              functions), to protect or defend our rights and the rights of
              others, to prevent misuse of our Sites and Services, and as
              otherwise required by law. We also use personal information we
              collect about clients, potential clients, event attendees and
              others who subscribe to receive our communications for direct
              marketing purposes and contact them about news, information and
              events they may be interested in (see the Use of personal
              information section above for more information about our use of
              personal information).
            </li>
            <li className="mb-2">
              <div className="mb-2">
                <strong>Rights of California residents under the CCPA.</strong>{" "}
                California law grants California residents certain rights and
                imposes restrictions on particular business practices as set
                forth below:
              </div>
              <ul className="list-disc pl-8">
                <li className="mb-1">
                  <strong>Notice before collection.</strong> We are required to
                  notify California residents, at or before the point of
                  collection of their personal information, the categories of
                  personal information collected and the purposes for which such
                  information is used.
                </li>
                <li className="mb-1">
                  <strong>Request to delete.</strong> California residents have
                  the right to request, at no charge, deletion of their personal
                  information that we have collected about them and to have such
                  personal information deleted, except where an exemption
                  applies. We will respond to verifiable requests received from
                  California residents as required by law.
                </li>
                <li className="mb-1">
                  <div className="mb-2">
                    <strong>Request to know.</strong> California residents have
                    the right to request and, subject to certain exemptions,
                    receive a copy of the specific pieces of personal
                    information that we have collected about them in the prior
                    12 months and to have this delivered, free of charge, either
                    (a) by mail or (b) electronically in a portable and, to the
                    extent technically feasible, readily useable format that
                    allows the individual to transmit this information to
                    another entity without hindrance. California residents also
                    have the right to request that we provide them certain
                    information about how we have handled their personal
                    information in the prior 12 months, including:
                  </div>
                  <ul className="list-disc pl-8">
                    <li className="mb-1">
                      categories of personal information collected;
                    </li>
                    <li className="mb-1">
                      categories of sources of personal information;
                    </li>
                    <li className="mb-1">
                      business and/or commercial purposes for collecting and
                      selling their personal information;
                    </li>
                    <li className="mb-1">
                      categories of third parties/with whom we have disclosed or
                      shared their personal information;
                    </li>
                    <li className="mb-1">
                      categories of personal information that we have disclosed
                      or shared with a third party for a business purpose; and
                    </li>
                    <li className="mb-1">
                      categories of third parties to whom the residents’
                      personal information has been sold and the specific
                      categories of personal information sold to each category
                      of third party.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li className="mb-2">
              <strong>Do-not-sell (opt-out/opt-in).</strong> California
              residents have the right to opt-out of our sale of their personal
              information. Further businesses may not knowingly sell personal
              information about minors under 16 years old without prior express
              consent.
            </li>
            <li className="mb-2">
              <strong>Discrimination and financial incentives.</strong> The CCPA
              prohibits discrimination against California residents for
              exercising their rights under the CCPA. A business may offer
              financial incentives for the collection, sale or deletion of
              California residents’ personal information, where the incentive is
              not unjust, unreasonable, coercive or usurious, and is made
              available in compliance with applicable transparency, informed
              consent, and opt-out requirements.
            </li>
            <li className="mb-2">
              California residents have the right to be notified of any
              financial incentives offers and their material terms, the right to
              opt-out of such incentives at any time, and may not be included in
              such incentives without their prior informed opt-in consent. We do
              not offer any such incentives at this time.
            </li>
          </ul>
          <span>
            California residents may submit a request to know or a request to
            delete to us by contacting us at
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="font-semibold text-dark-blue underline pl-1"
            >
              customers@dorothyai.com
            </a>
            . We may require additional information from you in order to verify
            your request. We will respond to verifiable requests received from
            California residents as required by law. If you have a disability,
            you may request a copy of this Policy or any other required
            accommodation be provided to you by contacting us at
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="font-semibold text-dark-blue underline pl-1"
            >
              customers@dorothyai.com
            </a>
            .
          </span>
        </p>
      </ol>
      <strong>
        For more information about our privacy practices, you may contact us at
        <a
          href="mailto:customers@dorothyai.com"
          target="_blank"
          className="text-dark-blue underline pl-1"
        >
          customers@dorothyai.com
        </a>
      </strong>
    </div>
  );
};

export default PrivacyPolicy;
