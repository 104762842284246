import React from "react";
import { useFormContext } from "../../../contexts/FormContext";
interface IQuestionOneProps {
  prefilledValue?: string;
}
const QuestionOne: React.FC<IQuestionOneProps> = ({ prefilledValue }) => {
  const { answers, currentQuestionIndex, handleAnswers } = useFormContext();
  const title =
    "Which People+AI report are you interested in? Select from the Alignment Products below or select custom? One of our 200+ advisors are ready to assist!";
  const options = [
    "Patentability",
    "Freedom to Operate",
    "Invalidity",
    "Patent Landscape",
    "Filing Strategy",
    "Patent Valuation",
    "Custom",
  ];
  const activeBox = "!bg-dorothy-teal !text-white !font-bold";
  const inactiveBox = "text-black/50 font-medium";
  const checkForActive = (option: string) => {
    if (!!prefilledValue) {
      return prefilledValue === option;
    } else {
      return option === answers[currentQuestionIndex];
    }
  };
  return (
    <>
      <div className="text-[20px] font-medium text-black mb-24">{title}</div>
      <div className="grid md:grid-cols-2 xl:grid-cols-4 sm:grid-cols-1 gap-12 mx-auto max-w-[800px]">
        {options?.slice(0, 4).map((option: string, index: number) => (
          <div
            key={index}
            className={`option-box ${
              checkForActive(option) ? activeBox : inactiveBox
            } ${!!prefilledValue ? "pointer-events-none" : ""}`}
            onClick={() => handleAnswers(option)}
          >
            {option}
          </div>
        ))}
      </div>
      <div className="grid md:grid-cols-2 xl:grid-cols-3 sm:grid-cols-1 gap-12 mx-auto max-w-[800px] my-12">
        {options?.slice(4).map((option: string, index: number) => (
          <div
            key={index}
            className={`option-box ${
              checkForActive(option) ? activeBox : inactiveBox
            } ${!!prefilledValue ? "pointer-events-none" : ""}`}
            onClick={() => handleAnswers(option)}
          >
            {option}
          </div>
        ))}
      </div>
    </>
  );
};

export default QuestionOne;
