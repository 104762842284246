import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import { ReactComponent as AddUser } from "../../assets/icons/add-user.svg";
import { ReactComponent as Instructions } from "../../assets/icons/instructions.svg";
import { ReactComponent as Report } from "../../assets/icons/report.svg";
import { ReactComponent as UploadCloud } from "../../assets/icons/upload-cloud.svg";
import { ReactComponent as Industry } from "../../assets/icons/industry.svg";
import { useFormContext } from "../../contexts/FormContext";

interface ProgressProps {
  decideNextButton: () => { [key: string]: boolean };
}

const values = [
  { component: <Report />, value: 0 },
  { component: <AddUser />, value: 0 },
  { component: <Industry />, value: 0 },
  { component: <Instructions />, value: 0 },
  { component: <UploadCloud />, value: 0 },
];

const ProgressBar: React.FC<ProgressProps> = ({ decideNextButton }) => {
  const { currentQuestionIndex, setProgress } = useFormContext();
  const [progressValues, setProgressValues] = useState(values);
  const [maxProgress, setMaxProgress] = useState(0);

  const [completedSteps, setCompletedSteps] = useState<boolean[]>(
    new Array(values.length).fill(false)
  );
  const updateProgressValues = useCallback(() => {
    return values.map((item, index) => {
      if (completedSteps[index]) {
        return {
          ...item,
          value: index === 0 ? 25 : 20,
        };
      } else {
        return { ...item, value: 0 };
      }
    });
  },[completedSteps]);

  useEffect(() => {
    const { shouldEnable } = decideNextButton();
    setCompletedSteps((prev) => {
      const newCompletedSteps = [...prev];
      newCompletedSteps[currentQuestionIndex - 1] = shouldEnable;
      return newCompletedSteps;
    });
  }, [currentQuestionIndex, decideNextButton]);

  useEffect(() => {
    setProgressValues(updateProgressValues());
  }, [completedSteps, updateProgressValues]);

  useEffect(() => {
    const totalProgress = progressValues.reduce(
      (acc, item) => acc + item.value,
      0
    );
    setProgress(totalProgress);
    if (totalProgress > maxProgress) {
      setMaxProgress(totalProgress);
    }


  }, [progressValues, maxProgress, setProgress]);

  const createGradient = () => {
    let gradientStops = "";
    let accumulatedProgress = 0;
    progressValues.forEach((item) => {
      accumulatedProgress += item.value;
      const color = "#44797b";
      gradientStops += `${color} ${accumulatedProgress}%, `;
    });
    return `linear-gradient(90deg, ${gradientStops.slice(0, -2)})`;
  };

  return (
    <div className="block bg-white rounded-2xl p-10 max-w-[895px] mx-auto bshadow-dark responsive-bar progress-bar-show">
      <div className="flex flex-col gap-y-8">
        <div className="flex justify-around items-center">
          {progressValues.map((item, index) => (
            <span
              key={index}
              className={cx({
                "active-step":
                  completedSteps[index] && index !== 1 && index !== 4,
                "no-fill":
                  completedSteps[index] && (index === 1 || index === 4),
              })}
            >
              {item.component}
            </span>
          ))}
        </div>
        <div className="relative w-full h-4 bg-dorothy-dark-gray rounded-full overflow-hidden">
          <div
            style={{
              width: `${maxProgress}%`,
              height: "100%",
              background: createGradient(),
              borderRadius: "4px",
              transition: "width 0.5s ease-in-out",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
