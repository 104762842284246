import React from "react";
import { toast } from "react-hot-toast";
import SearchInProgressIcon from "../../assets/img/search-in-progress.png";
import { Button, Snackbar } from "../shared";

interface ISearchInProgress {
  closeHandler: () => void;
  buttonLabel?: string;
}

const SearchInProgress: React.FC<ISearchInProgress> = ({
  closeHandler,
  buttonLabel = "Got it",
}) => {
  const gotItHandler = () => {
    toast((t) => (
      <Snackbar
        message={
          <>
            Search <b>initiated.</b>
          </>
        }
        onClose={() => toast.dismiss(t.id)}
      />
    ));
    closeHandler();
  };

  return (
    <div className="p-12">
      <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full">
        <img src={SearchInProgressIcon} alt="search in progress icon"/>
      </div>
      <div className="mt-7 text-center sm:mt-5">
        <h3 className="text-2xl font-sweetsanspro font-bold leading-6 text-gray-900">
          Search is in progress
        </h3>
        <div className="mt-4">
          <p className="text-sm font-normal">
            It takes 2-3 minutes for the search results to be listed.
          </p>
          <p className="text-sm font-normal mt-6 mb-8">
            The search will continue in the background. Feel free to grab a cup
            of coffee, start a new search or work on other projects.
          </p>
        </div>
        <Button onClick={gotItHandler}>{buttonLabel}</Button>
      </div>
    </div>
  );
};

export default SearchInProgress;
