import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../utils/helpers";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface IFreedomSearch {
  id?: string;
  projectId: string;
  elements: any[];
  dateType?: string;
  dateFrom?: string;
  dateTo?: string;
  facetTerms?: any[];
  countries?: any[];
  foundedCountries?: any[];
  foundedFacetTerms?: any[];
  foundedAssignees?: any[];
  foundedClassifications?: any[];
  aiResultId?: string;
  aiSearchId?: string;
  status?: string;
  totalResult?: string | number;
  totalSaved?: string | number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  creatorId?: string;
  updaterId?: string | null;
  deleterId?: string | null;
}

export interface IFreedomSearchList {
  count: number;
  rows: IFreedomSearch[];
}

export const freedomSearchApi = createApi({
  reducerPath: "freedomSearchApi",
  tagTypes: ["freedomsearch"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFreedomSearches: builder.query<
      IFreedomSearchList,
      { currentPage: number; pageSize: number; projectId: number }
    >({
      query: (arg) => {
        const { currentPage, pageSize, projectId } = arg;
        return {
          url: "/freedomSearches",
          params: { projectId, currentPage, pageSize },
        };
      },
      providesTags: ["freedomsearch"],
    }),
    getFreedomSearch: builder.query<IFreedomSearch, any>({
      query: (id) => {
        return {
          url: `/freedomSearches/${id}`,
        };
      },
      providesTags: ["freedomsearch"],
    }),
    addFreedomSearch: builder.mutation<any, any>({
      query: (search: IFreedomSearch) => ({
        url: "/freedomSearches",
        method: "POST",
        body: search,
      }),
      invalidatesTags: ["freedomsearch"],
    }),
    updateFreedomSearch: builder.mutation({
      query: (search: IFreedomSearch) => ({
        url: `/freedomSearches/${search.id}`,
        method: "PATCH",
        body: search,
      }),
      invalidatesTags: ["freedomsearch"],
    }),
    deleteFreedomSearch: builder.mutation({
      query: (id) => ({
        url: `/freedomSearches/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["freedomsearch"],
    }),
  }),
});

export const {
  useGetFreedomSearchesQuery,
  useGetFreedomSearchQuery,
  useAddFreedomSearchMutation,
  useDeleteFreedomSearchMutation,
  useUpdateFreedomSearchMutation,
} = freedomSearchApi;
