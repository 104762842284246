import Empty from "../../assets/img/empty.png";
import { Button, Loader, Modal, Unauthorized } from "../../components/shared";
import cx from "classnames";
import { ReactComponent as People } from "../../assets/icons/people.svg";
import { useNavigate } from "react-router-dom";
import { useGetPeopleaiProjectsQuery } from "../../features/peopleaiProjectsApi";
import { getPermissions } from "../../utils/helpers";
import { useFormContext } from "../../contexts/FormContext";
import { useState } from "react";
import PatentRequestForm from "../PatentModal";

const PeopleAI = () => {
  const permissions = getPermissions();
  const [orderPatentModalOpen, setOrderPatentModalOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { resetAnswers } = useFormContext();

  const {
    data: peopleAIProjects,
    isLoading,
    isError,
    error,
  } = useGetPeopleaiProjectsQuery(
    {
      currentPage: 1,
      pageSize: 100,
    },
    { refetchOnMountOrArgChange: true }
  );

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401) {
      return <Unauthorized />;
    }
  }

  return (
    <>
      {permissions.length > 0 ? (
        <>
          <div className="flex justify-end">
            <div className="flex items-center gap-x-4">
              <Button
                variant="filled"
                size="normal"
                className="flex items-center gap-x-4 justify-center !bg-dark-blue hover:!bg-dark-blue/90 !py-2.5 text-base font-medium rounded-[10px]"
                onClick={() => {
                  setOrderPatentModalOpen(true);
                }}
              >
                <span>I’m Ready to Order a Patent Search</span>
              </Button>
              <Button
                variant="filled"
                size="normal"
                className="flex items-center gap-x-4 justify-center bg-dorothy-teal hover:bg-dorothy-teal/90 !py-2.5 text-base font-medium rounded-[10px]"
                onClick={() => {
                  navigate("/peopleai/create");
                  resetAnswers();
                }}
              >
                <span>Create Project</span>
                <People />
              </Button>
            </div>
          </div>
          {peopleAIProjects && peopleAIProjects.count > 0 ? (
            <div className="flex flex-col gap-y-4 bg-white p-10 my-8 rounded-[15px] bshadow">
              <div className="grid grid-cols-3 italic text-sm font-bold text-gray-400">
                <span>Name</span>
                <span>No. of Documents</span>
              </div>
              {peopleAIProjects?.rows?.map((project: any, index: number) => (
                <div
                  className={cx(
                    "grid grid-cols-3 p-5 rounded-[15px] text-sm font-bold cursor-pointer text-dorothy-teal",
                    {
                      "bg-dorothy-teal/20": index % 2 === 0,
                      "border border-dorothy-dark-gray/20 hover:bg-dorothy-teal/5 transition duration-200":
                        index % 2 !== 0,
                    }
                  )}
                  key={project.id}
                  onClick={() => navigate(`/peopleai/view/${project.id}`)}
                >
                  <span
                    className="truncate max-w-[250px]"
                    title={project.titleOfSearch.replace(/\s+/g, " ").trim()}
                  >
                    {project.titleOfSearch.replace(/\s+/g, " ").trim()}
                  </span>
                  <span>{project.totalDocuments}</span>
                </div>
              ))}
            </div>
          ) : (
            <div
              className="flex flex-col gap-y-6 items-center justify-center"
              style={{ height: "calc(100vh - 160px)" }}
            >
              <img src={Empty} />
              <span className="font-bold text-base text-black">
                No Projects
              </span>
              <span className="text-black/60 text-center">
                Create your first project with People+AI to get started.
              </span>
            </div>
          )}
        </>
      ) : (
        <div>
          You don't have any permission. Please contact the administrator.
        </div>
      )}
      <Modal
        title="Order a patent search"
        overlayClose
        isShow={orderPatentModalOpen}
        closeHandler={() => setOrderPatentModalOpen(false)}
        closeButton
      >
        <PatentRequestForm />
      </Modal>
    </>
  );
};

export default PeopleAI;
