import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken } from "../utils/helpers";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface IStrategicAlignmentSearch {
  id?: string;
  projectId: string;
  publicationNo?: string;
  description: string;
  novelFeatures: any[];
  headQuarters?: any[];
  supply?: any[];
  sales?: any[];
  factors: any[];
  aiResultId?: string;
  status?: string;
  totalResult?: string | number;
  totalSaved?: string | number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  creatorId?: string;
  updaterId?: string | null;
  deleterId?: string | null;
}

export interface IStrategicAlignmentSearchList {
  count: number;
  rows: IStrategicAlignmentSearch[];
}

export const strategicAlignmentSearchApi = createApi({
  reducerPath: "strategicAlignmentSearchApi",
  tagTypes: ["strategicalignmentsearch"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    addStrategicAlignmentSearch: builder.mutation<any, any>({
      query: (search: IStrategicAlignmentSearch) => ({
        url: "/strategicAlignment",
        method: "POST",
        body: search,
      }),
      invalidatesTags: ["strategicalignmentsearch"],
    }),
  }),
});

export const { useAddStrategicAlignmentSearchMutation } =
  strategicAlignmentSearchApi;
