import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import { Snackbar } from "../shared";
import { dateFormatter } from "../../utils/helpers";

const FilterItem = ({
  label,
  data,
  onDelete,
}: {
  label: string;
  data: any;
  onDelete: any;
}) => {
  return (
    <div className="bg-white rounded-md px-3 py-2 inline-flex">
      <span className="font-bold mr-1">{label} :</span>
      {data.toString()}
      <XMarkIcon className="w-4 h-4 ml-2 cursor-pointer" onClick={onDelete} />
    </div>
  );
};

const Filters = ({
  filters,
  setFilters,
  tab,
}: {
  filters: any;
  setFilters: any;
  tab: string;
}) => {
  const deleteHandler = (fields: string[]) => {
    const newFilters = { ...filters };
    fields.forEach((f) => {
      delete newFilters[f];
    });

    newFilters.currentPage = 1;
    setFilters({ tab: tab, params: newFilters });
    toast((t) => (
      <Snackbar
        message={
          <>
            Result <b>filtered.</b>
          </>
        }
        onClose={() => toast.dismiss(t.id)}
      />
    ));
  };

  if (
    !filters.countries?.length &&
    !filters.facetTerms?.length &&
    !filters.assignees?.length &&
    !filters.fromAplicationDate &&
    !filters.toAplicationDate &&
    !filters.fromPublicationDate &&
    !filters.toPublicationDate &&
    !filters.fromIssueDate &&
    !filters.toIssueDate
  ) {
    return null;
  }
  return (
    <div className="flex mt-6 gap-3 flex-wrap">
      {filters.countries?.length > 0 && (
        <FilterItem
          label="Countries"
          data={filters.countries}
          onDelete={() => deleteHandler(["countries"])}
        />
      )}

      {filters.facetTerms?.length > 0 && (
        <FilterItem
          label="Facet Terms"
          data={filters.facetTerms}
          onDelete={() => deleteHandler(["facetTerms"])}
        />
      )}

      {filters.assignees?.length > 0 && (
        <FilterItem
          label="Assignee"
          data={filters.assignees}
          onDelete={() => deleteHandler(["assignees"])}
        />
      )}

      {(filters.fromAplicationDate || filters.toAplicationDate) && (
        <FilterItem
          label="Priority / Application"
          data={`${
            filters.fromAplicationDate
              ? dateFormatter(filters.fromAplicationDate, "frontend")
              : "----"
          }  -  ${
            filters.toAplicationDate
              ? dateFormatter(filters.toAplicationDate, "frontend")
              : "----"
          }`}
          onDelete={() =>
            deleteHandler(["fromAplicationDate", "toAplicationDate"])
          }
        />
      )}

      {(filters.fromPublicationDate || filters.toPublicationDate) && (
        <FilterItem
          label="Publication"
          data={`${
            filters.fromPublicationDate
              ? dateFormatter(filters.fromPublicationDate, "frontend")
              : "----"
          }  -  ${
            filters.toPublicationDate
              ? dateFormatter(filters.toPublicationDate, "frontend")
              : "----"
          }`}
          onDelete={() =>
            deleteHandler(["fromPublicationDate", "toPublicationDate"])
          }
        />
      )}

      {(filters.fromIssueDate || filters.toIssueDate) && (
        <FilterItem
          label="Issue"
          data={`${
            filters.fromIssueDate
              ? dateFormatter(filters.fromIssueDate, "frontend")
              : "----"
          }  -  ${
            filters.toIssueDate
              ? dateFormatter(filters.toIssueDate, "frontend")
              : "----"
          }`}
          onDelete={() => deleteHandler(["fromIssueDate", "toIssueDate"])}
        />
      )}
    </div>
  );
};

export default Filters;
