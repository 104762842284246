import {
  CheckCircleIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Field } from "formik";
import React, { useRef, useState } from "react";
import { LinkButton } from "../shared";

//=======================================
//for refine search founded countries
export const FoundedCountries = ({ data }: { data: any }) => {
  if (!data || data.length < 1) {
    return null;
  }
  return (
    <div className="mb-12 md:mb-7">
      <h4 className="font-bold text-lg mb-4">Countries</h4>
      {data.map((country: any, index: number) => {
        return (
          <div key={index}>
            <label className="inline-flex items-center mt-2 h-5">
              <Field
                type="checkbox"
                name="countries"
                value={country}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="ml-3">{country}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

//=======================================
//for refine search founded facet terms
export const FoundedFacetTerms = ({ data }: { data: any }) => {
  const inputRef: React.RefObject<HTMLInputElement> = useRef(null);
  const [facetTerms, setFacetTerms] = useState<string[]>(data);
  const [showInput, setShowInput] = useState(false);

  const addMoreHandler = () => {
    const val = inputRef?.current?.value;
    if (val) {
      setFacetTerms([...facetTerms, val]);
      setShowInput(false);
    }
  };

  const addTermsWithEnter = (e: any) => {
    if (e.key === "Enter") {
      e.preventDefault();
      addMoreHandler();
    }
  };

  if (!data || data.length < 1) {
    return null;
  }
  return (
    <div className="mb-12 md:mb-7">
      <h4 className="font-bold text-lg mb-4">Facet Terms</h4>
      {facetTerms.map((c: any, index: number) => {
        return (
          <div key={index}>
            <label className="inline-flex items-center mt-2 h-5">
              <Field
                type="checkbox"
                name="facetTerms"
                value={c}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="ml-3">{c}</span>
            </label>
          </div>
        );
      })}
      {showInput && (
        <div className="mt-2 flex items-center">
          <input
            ref={inputRef}
            autoFocus
            className="border border-gray-400 h-8 p-2 rounded mr-2"
            placeholder="Type here..."
            onKeyDown={addTermsWithEnter}
          />
          <LinkButton onClick={addMoreHandler}>
            <CheckCircleIcon className="w-5 h-5 mr-2" title="Add" />
          </LinkButton>
          <LinkButton onClick={() => setShowInput(false)}>
            <XMarkIcon className="w-5 h-5 mr-2 text-gray-600" title="Remove" />
          </LinkButton>
        </div>
      )}
      <div className="mt-4">
        <LinkButton onClick={() => setShowInput(true)}>
          <PlusIcon className="w-5 h-5 mr-2" /> Add Facet Term
        </LinkButton>
      </div>
    </div>
  );
};

//=======================================
//result page tabs
export const ResultTabs = ({
  tab,
  savedCount,
  resultHandler,
  savedHandler,
}: {
  tab: any;
  savedCount: any;
  resultHandler: any;
  savedHandler: any;
}) => {
  const tabClasses = {
    active:
      "py-2 mr-5 border-b-2 cursor-pointer text-dorothy-orange border-dorothy-orange font-bold",
    passive: "mr-5 py-2 border-b-2 cursor-pointer",
  };
  return (
    <div className="border-b border-gray-300 flex justify-start text-sm">
      <span
        className={tab === "results" ? tabClasses.active : tabClasses.passive}
        onClick={resultHandler}
      >
        Search Results
      </span>
      <span
        className={tab === "saved" ? tabClasses.active : tabClasses.passive}
        onClick={savedHandler}
      >
        Saved Results ({savedCount})
      </span>
    </div>
  );
};
