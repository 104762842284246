import { FormikValues, useFormik } from "formik";
import * as Yup from "yup";
import cx from "classnames";
import { emailRegex } from "../../../utils/consts";
import React, { useEffect } from "react";
import _ from "lodash";
import { useFormContext } from "../../../contexts/FormContext";
import { isEmptyObject } from "../../../utils/helpers";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

interface IQuestionTwoForm {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  companyName: string;
  titleOfSearch: string;
  referenceNumber: string;
}
interface IQuestionTwoProps {
  prefilledValues?: IQuestionTwoForm | any;
}
const QuestionTwo: React.FC<IQuestionTwoProps> = ({ prefilledValues }) => {
  const {
    questionTwoFormValues,
    setQuestionTwoFormValues,
    setQuestionTwoNext,
    handleAnswers,
    currentQuestionIndex,
  } = useFormContext();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = useFormik<IQuestionTwoForm>({
    initialValues: questionTwoFormValues,
    validationSchema: Yup.object({
      firstName: Yup.string().trim().required("Please enter first name"),
      lastName: Yup.string().trim().required("Please enter last name"),
      phoneNumber: Yup.string()
        .trim()
        .test(
          "isValidPhone",
          "Phone number is not valid",
          (value: any) => value && value.replace(/\D/g, "").length > 10
        )
        .required("Please enter phone number"),
      email: Yup.string()
        .trim()
        .matches(emailRegex, "Invalid email address")
        .required("Please enter an email"),
      companyName: Yup.string().trim().required("Please enter company name"),
      titleOfSearch: Yup.string()
        .trim()
        .required("Please enter title of search"),
      referenceNumber: Yup.string()
        .trim()
        .required("Please enter reference number"),
    }),
    onSubmit: (values: FormikValues) => {},
  });

  useEffect(() => {
    setQuestionTwoFormValues(values);
    const isFormEmpty =
      isEmptyObject(values) && isEmptyObject(questionTwoFormValues);
    let trimmedValues: IQuestionTwoForm = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      companyName: "",
      titleOfSearch: "",
      referenceNumber: "",
    };
    for (let v in values) {
      const key = v as keyof IQuestionTwoForm;

      trimmedValues[key] = (values[key]?.replace(/\s+/g, " ")?.trim() ??
        "") as string;
    }
    handleAnswers(trimmedValues);
    setQuestionTwoNext({
      enable: !isFormEmpty && isValid,
      disable: isFormEmpty || !isValid,
    });
  }, [values, isValid, currentQuestionIndex, questionTwoFormValues]);

  return (
    <div className="flex flex-col gap-y-6 md:gap-y-8 justify-around responsive-form">
      <form className="flex flex-col md:flex-row items-center gap-x-0 gap-y-8 md:gap-y-0 md:gap-x-6">
        <div className="flex flex-col w-full flex-1 gap-y-2">
          <label
            htmlFor="firstName"
            className="flex gap-x-2 items-center font-bold"
          >
            <span
              className={cx({
                "font-semibold text-red-500":
                  errors.firstName &&
                  touched.firstName &&
                  !prefilledValues?.firstName,
              })}
            >
              First name
            </span>
            {errors.firstName &&
              touched.firstName &&
              !prefilledValues?.firstName && (
                <span className="font-light text-red-500">
                  ({errors.firstName})
                </span>
              )}
          </label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            autoFocus={!prefilledValues?.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            readOnly={!!prefilledValues?.firstName}
            value={
              !!prefilledValues ? prefilledValues.firstName : values.firstName
            }
            placeholder="First name"
            className={cx(
              "flex-1 w-full p-4 rounded-[10px] border-2 border-dorothy-dark-gray text-base",
              {
                "!border-red-400":
                  errors.firstName &&
                  touched.firstName &&
                  !prefilledValues?.firstName,
                "pointer-events-none": !!prefilledValues?.firstName,
              }
            )}
          />
        </div>
        <div className="flex flex-col flex-1 w-full gap-y-2">
          <label
            htmlFor="lastName"
            className="flex gap-x-2 items-center font-bold"
          >
            <span
              className={cx({
                "font-semibold text-red-500":
                  errors.lastName &&
                  touched.lastName &&
                  !prefilledValues?.lastName,
              })}
            >
              Last name
            </span>
            {errors.lastName &&
              touched.lastName &&
              !prefilledValues?.lastName && (
                <span className="font-light text-red-500">
                  ({errors.lastName})
                </span>
              )}
          </label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            onChange={handleChange}
            readOnly={!!prefilledValues?.lastName}
            value={
              !!prefilledValues ? prefilledValues.lastName : values.lastName
            }
            onBlur={handleBlur}
            placeholder="Last name"
            className={cx(
              "flex-1 w-full p-4 rounded-[10px] border-2 border-dorothy-dark-gray text-base",
              {
                "!border-red-400":
                  errors.lastName &&
                  touched.lastName &&
                  !prefilledValues?.lastName,
                "pointer-events-none": !!prefilledValues?.lastName,
              }
            )}
          />
        </div>
      </form>
      <div className="flex flex-col md:flex-row items-center gap-x-0 gap-y-8 md:gap-y-0 md:gap-x-6">
        <div className="flex flex-col flex-1 w-full gap-y-2">
          <label
            htmlFor="phoneNumber"
            className="flex gap-x-2 items-center font-bold"
          >
            <span
              className={cx({
                "font-semibold text-red-500":
                  errors.phoneNumber &&
                  touched.phoneNumber &&
                  !prefilledValues?.phoneNumber,
              })}
            >
              Phone number
            </span>
            {errors.phoneNumber &&
              touched.phoneNumber &&
              !prefilledValues?.phoneNumber && (
                <span className="font-light text-red-500">
                  ({errors.phoneNumber})
                </span>
              )}
          </label>
          <PhoneInput
            disableDropdown={!!prefilledValues?.phoneNumber}
            inputClass={cx(
              "!flex-1 !w-full !py-4 !pr-4 !rounded-[10px] !h-auto !border-2 !text-base focus:border-black font-montserrat",
              {
                error:
                  errors.phoneNumber &&
                  touched.phoneNumber &&
                  !prefilledValues?.phoneNumber,
                "pointer-events-none": !!prefilledValues?.phoneNumber,
              }
            )}
            inputProps={{
              name: "phoneNumber",
              required: true,
            }}
            country={"us"}
            value={
              !!prefilledValues
                ? prefilledValues.phoneNumber
                : values.phoneNumber
            }
            onChange={(phone) => setFieldValue("phoneNumber", phone)}
            onBlur={() => setFieldTouched("phoneNumber", true)}
          />
        </div>
        <div className="flex flex-col flex-1 w-full gap-y-2">
          <label
            htmlFor="email"
            className="flex gap-x-2 items-center font-bold"
          >
            <span
              className={cx({
                "font-semibold text-red-500":
                  errors.email && touched.email && !prefilledValues?.email,
              })}
            >
              Email
            </span>
            {errors.email && touched.email && !prefilledValues?.email && (
              <span className="font-light text-red-500">({errors.email})</span>
            )}
          </label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            readOnly={!!prefilledValues?.email}
            onBlur={handleBlur}
            value={!!prefilledValues ? prefilledValues.email : values.email}
            placeholder="Email"
            className={cx(
              "flex-1 w-full p-4 rounded-[10px] border-2 border-dorothy-dark-gray text-base",
              {
                "!border-red-400":
                  errors.email && touched.email && !prefilledValues?.email,
                "pointer-events-none": !!prefilledValues?.email,
              }
            )}
          />
        </div>
      </div>
      <div className="flex flex-col gap-y-2">
        <label
          htmlFor="companyName"
          className="flex gap-x-2 items-center font-bold"
        >
          <span
            className={cx({
              "font-semibold text-red-500":
                errors.companyName &&
                touched.companyName &&
                !prefilledValues?.companyName,
            })}
          >
            Company name
          </span>
          {errors.companyName &&
            touched.companyName &&
            !prefilledValues?.companyName && (
              <span className="font-light text-red-500">
                ({errors.companyName})
              </span>
            )}
        </label>
        <input
          type="text"
          name="companyName"
          id="companyName"
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={!!prefilledValues?.companyName}
          value={
            !!prefilledValues
              ? prefilledValues?.companyName
              : values.companyName
          }
          placeholder="Company name"
          className={cx(
            "flex-1 w-full p-4 rounded-[10px] border-2 border-dorothy-dark-gray text-base",
            {
              "!border-red-400":
                errors.companyName &&
                touched.companyName &&
                !prefilledValues?.companyName,
              "pointer-events-none": !!prefilledValues?.companyName,
            }
          )}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <label
          htmlFor="titleOfSearch"
          className="flex gap-x-2 items-center font-bold"
        >
          <span
            className={cx({
              "font-semibold text-red-500":
                errors.titleOfSearch &&
                touched.titleOfSearch &&
                !prefilledValues?.titleOfSearch,
            })}
          >
            Title of the search
          </span>
          {errors.titleOfSearch &&
            touched.titleOfSearch &&
            !prefilledValues?.titleOfSearch && (
              <span className="font-light text-red-500">
                ({errors.titleOfSearch})
              </span>
            )}
        </label>
        <input
          type="text"
          name="titleOfSearch"
          id="titleOfSearch"
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={!!prefilledValues?.titleOfSearch}
          value={
            !!prefilledValues
              ? prefilledValues?.titleOfSearch
              : values.titleOfSearch
          }
          placeholder="Title of the search"
          className={cx(
            "flex-1 w-full p-4 rounded-[10px] border-2 border-dorothy-dark-gray text-base",
            {
              "!border-red-400":
                errors.titleOfSearch &&
                touched.titleOfSearch &&
                !prefilledValues?.titleOfSearch,
              "pointer-events-none": !!prefilledValues?.titleOfSearch,
            }
          )}
        />
      </div>
      <div className="flex flex-col gap-y-2">
        <label
          htmlFor="referenceNumber"
          className="flex gap-x-2 items-center font-bold"
        >
          <span
            className={cx({
              "font-semibold text-red-500":
                errors.referenceNumber &&
                touched.referenceNumber &&
                !prefilledValues?.referenceNumber,
            })}
          >
            Reference number
          </span>
          {errors.referenceNumber &&
            touched.referenceNumber &&
            !prefilledValues?.referenceNumber && (
              <span className="font-light text-red-500">
                ({errors.referenceNumber})
              </span>
            )}
        </label>
        <input
          type="text"
          name="referenceNumber"
          id="referenceNumber"
          onChange={handleChange}
          onBlur={handleBlur}
          readOnly={!!prefilledValues?.referenceNumber}
          value={
            !!prefilledValues
              ? prefilledValues?.referenceNumber
              : values.referenceNumber
          }
          placeholder="Reference Number"
          className={cx(
            "flex-1 w-full p-4 rounded-[10px] border-2 border-dorothy-dark-gray text-base",
            {
              "!border-red-400":
                errors.referenceNumber &&
                touched.referenceNumber &&
                !prefilledValues?.referenceNumber,
              "pointer-events-none": !!prefilledValues?.referenceNumber,
            }
          )}
        />
      </div>
    </div>
  );
};

export default QuestionTwo;
