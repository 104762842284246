import React from "react";
import { NavLink } from "react-router-dom";

const SubMenu = ({ onClose }: { onClose: any }) => {
  return (
    <div className="text-xs flex flex-row py-10 justify-center items-center">
      <NavLink
        to="/privacy-policy"
        className={({ isActive }) =>
          isActive ? "text-white font-bold" : "text-white/80"
        }
        onClick={onClose}
      >
        Privacy Policy
      </NavLink>
      <span className="inline-block w-1 h-1 bg-white rounded-full mx-2"></span>
      <NavLink
        to="/terms-of-use"
        className={({ isActive }) =>
          isActive ? "text-white font-bold" : "text-white/80"
        }
        onClick={onClose}
      >
        Terms Of Use
      </NavLink>
    </div>
  );
};

export default SubMenu;
