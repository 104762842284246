import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAccessToken, paramsSerializer } from "../utils/helpers";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface IResult {
  id: string;
  searchId: string;
  searchType: "novelty" | "freedom";
  publicationNo: string;
  filingDate: string;
  publicationDate: string;
  issueDate: string;
  status: any;
  familyMembers: any;
  relevancy: string;
  title: string;
  assignee: string[];
  inventors: string[];
  primaryClassification: string[];
  relatedDocuments: any[];
  citedDocuments: any;
  drawingsFigures: any;
  abstract: string;
  keyPhrases: any[];
  claimsResults: any[];
  googlePatentsLink: any;
  downloadLink: string | URL | undefined;
  isSaved: boolean;
  facetTerms: string[];
  countryCode: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
  creatorId: string | null;
  updaterId: string | null;
  deleterId: string | null;
}

export interface IResultParams {
  type: "novelty" | "freedom";
  searchId: number | string;
  pageSize: number;
  currentPage: number;
  exportCsv?: boolean;
  isSaved?: boolean;
  fromAplicationDate?: string;
  toAplicationDate?: string;
  fromPublicationDate?: string;
  toPublicationDate?: string;
  fromIssueDate?: string;
  toIssueDate?: string;
}

export interface IIResultList {
  count: number;
  rows: IResult[];
}

export const resultsApi = createApi({
  reducerPath: "resultsApi",
  tagTypes: ["results"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token = getAccessToken();
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getResults: builder.query<IIResultList, IResultParams>({
      query: (arg) => {
        return {
          url: "/searchresults/?" + paramsSerializer(arg),
        };
      },
      providesTags: ["results"],
    }),
    updateSavedStatus: builder.mutation({
      query: (params: any) => ({
        url: `/searchresults/${params.id}`,
        method: "PATCH",
        body: params,
      }),
      invalidatesTags: ["results"],
    }),
  }),
});

export const { useGetResultsQuery, useUpdateSavedStatusMutation } = resultsApi;
