import React, { useRef } from "react";
import { Button, DateRangeInput, ReactSelect, Snackbar } from "../shared";
import CountriesData from "../../assets/countries.json";
import { dateFormatter, sortCountries } from "../../utils/helpers";
import { toast } from "react-hot-toast";
import { RangeSlider } from "rsuite";
import "rsuite/dist/rsuite.min.css";

interface IFilterForm {
  params: any;
  countries: string[] | undefined;
  facetTerms: string[] | undefined;
  classifications: string[] | undefined;
  assignees: string[] | undefined;
  filterHandler: any;
  relevancy: [number, number];
  handleRelevancy: React.Dispatch<React.SetStateAction<[number, number]>>;
}

const FilterForm: React.FC<IFilterForm> = ({
  params,
  relevancy,
  handleRelevancy,
  countries,
  facetTerms,
  classifications,
  assignees,
  filterHandler,
}) => {
  const filterParams: any = {};
  const countriesRef = useRef<any>(null);
  const facetTermsRef = useRef<any>(null);
  const assigneesRef = useRef<any>(null);
  const priorityDateRef = useRef<any>(null);
  const publicationDateRef = useRef<any>(null);
  const issueDateRef = useRef<any>(null);
  const classificationsRef = useRef<any>(null);

  let countriesOptions: any[] = [];
  let currentCountries: any[] = [];
  if (countries) {
    countriesOptions = sortCountries(CountriesData).filter((country) =>
      countries.includes(country.value)
    );
    if (params.countries && params.countries.length > 0) {
      currentCountries = countriesOptions.filter((c) =>
        params.countries.includes(c.value)
      );
    }
  }

  const facetTermsOptions = [];
  let currentFacetTerms: any[] = [];
  if (facetTerms) {
    for (const ft of facetTerms) {
      facetTermsOptions.push({
        value: ft,
        label: ft,
      });
    }
    if (params.facetTerms && params.facetTerms.length > 0) {
      currentFacetTerms = facetTermsOptions.filter((f) =>
        params.facetTerms.includes(f.value)
      );
    }
  }

  const assigneesOptions = [];
  let currentAssignees: any[] = [];
  if (assignees) {
    for (const assn of assignees) {
      assigneesOptions.push({
        value: assn,
        label: assn,
      });
    }
    if (params.assignees && params.assignees.length > 0) {
      currentAssignees = assigneesOptions.filter((f) =>
        params.assignees.includes(f.value)
      );
    }
  }

  const primaryClassifications = [];
  let currentClassifications: any[] = [];
  if (classifications) {
    for (const cls of classifications) {
      primaryClassifications.push({
        value: cls,
        label: cls,
      });
    }
    if (
      params.primaryClassification &&
      params.primaryClassification.length > 0
    ) {
      currentClassifications = primaryClassifications.filter((f) =>
        params.primaryClassification.includes(f.value)
      );
    }
  }

  const performFilter = () => {
    const countriesArr = countriesRef.current
      .getValue()
      .map((v: any) => v.value);
    const facetTermsArr = facetTermsRef.current
      .getValue()
      .map((v: any) => v.value);

    const assigneesArr = assigneesRef.current
      .getValue()
      .map((v: any) => v.value);

    const classificationsArr = classificationsRef.current
      .getValue()
      .map((v: any) => v.value);

    filterParams["countries"] = countriesArr;
    filterParams["facetTerms"] = facetTermsArr;
    filterParams["assignees"] = assigneesArr;
    filterParams["primaryClassification"] = classificationsArr;
    filterParams["relevancy"] = relevancy.map((r) => r / 100);

    let priority = priorityDateRef.current.input.value;
    if (priority) {
      priority = priority.split(" - ");
      filterParams["fromAplicationDate"] =
        dateFormatter(priority[0]) || undefined;
      filterParams["toAplicationDate"] =
        dateFormatter(priority[1]) || undefined;
    } else {
      filterParams["fromAplicationDate"] = undefined;
      filterParams["toAplicationDate"] = undefined;
    }

    let publication = publicationDateRef.current.input.value;
    if (publication) {
      publication = publication.split(" - ");
      filterParams["fromPublicationDate"] =
        dateFormatter(publication[0]) || undefined;
      filterParams["toPublicationDate"] =
        dateFormatter(publication[1]) || undefined;
    } else {
      filterParams["fromPublicationDate"] = undefined;
      filterParams["PublicationDate"] = undefined;
    }

    let issue = issueDateRef.current.input.value;
    if (issue) {
      issue = issue.split(" - ");
      filterParams["fromIssueDate"] = dateFormatter(issue[0]) || undefined;
      filterParams["toIssueDate"] = dateFormatter(issue[1]) || undefined;
    } else {
      filterParams["fromIssueDate"] = undefined;
      filterParams["toIssueDate"] = undefined;
    }
    filterHandler(filterParams);
    toast((t) => (
      <Snackbar
        message={
          <>
            Result <b>filtered.</b>
          </>
        }
        onClose={() => toast.dismiss(t.id)}
      />
    ));
  };

  const resetFilters = () => {
    countriesRef.current?.clearValue();
    facetTermsRef.current?.clearValue();
    priorityDateRef.current?.clear();
    publicationDateRef.current?.clear();
    issueDateRef.current?.clear();
    classificationsRef.current?.clearValue();
    handleRelevancy!([20, 100]);
  };
  return (
    <div className="mt-6 rounded-lg bg-white">
      <div className="font-bold text-lg p-6 border-b border-gray-300">
        Filter Results
      </div>
      <div className="grid gap-3 grid-cols1 md:grid-cols-3 p-6 pb-0">
        <div>
          <ReactSelect
            name="countries"
            label="Countries"
            data={countriesOptions}
            defaultValue={currentCountries}
            selectRef={countriesRef}
            multiple
            tabIndex={1}
          />
        </div>
        <div>
          <ReactSelect
            name="facetTerms"
            label="Facet Terms"
            data={facetTermsOptions}
            selectRef={facetTermsRef}
            defaultValue={currentFacetTerms}
            multiple
            tabIndex={2}
          />
        </div>
        <div>
          <ReactSelect
            name="assignees"
            label="Assignee"
            data={assigneesOptions}
            selectRef={assigneesRef}
            defaultValue={currentAssignees}
            multiple
            tabIndex={3}
          />
        </div>
      </div>
      <div className="grid gap-3 grid-cols-1 md:grid-cols-3 p-6 pt-0">
        <div>
          <DateRangeInput
            placeholder="From - To"
            label="Priority/Application Date"
            name="priority_date"
            dateRef={priorityDateRef}
            value={{
              startDate: params.fromAplicationDate
                ? new Date(params.fromAplicationDate)
                : null,
              endDate: params.toAplicationDate
                ? new Date(params.toAplicationDate)
                : null,
            }}
            tabIndex={4}
          />
        </div>
        <div>
          <DateRangeInput
            placeholder="From - To"
            label="Publication Date"
            name="publication_date"
            dateRef={publicationDateRef}
            value={{
              startDate: params.fromPublicationDate
                ? new Date(params.fromPublicationDate)
                : null,
              endDate: params.toPublicationDate
                ? new Date(params.toPublicationDate)
                : null,
            }}
            tabIndex={5}
          />
        </div>
        <div>
          <DateRangeInput
            placeholder="From - To"
            label="Issue Date"
            name="issue_date"
            dateRef={issueDateRef}
            value={{
              startDate: params.fromIssueDate
                ? new Date(params.fromIssueDate)
                : null,
              endDate: params.toIssueDate ? new Date(params.toIssueDate) : null,
            }}
            tabIndex={6}
          />
        </div>
      </div>
      <div className="grid md:gap-x-3 grid-cols-1 md:grid-cols-3 p-6 pt-0">
        <div>
          <ReactSelect
            name="primaryclassification"
            label="Primary Classification"
            data={primaryClassifications}
            selectRef={classificationsRef}
            defaultValue={currentClassifications}
            tabIndex={7}
            multiple
          />
        </div>
        <div className="col-span-2">
          <h4 className="text-[14px] mb-2">Relevancy</h4>
          <div className="px-1">
            <RangeSlider
              progress
              className="w-full"
              value={relevancy}
              defaultValue={[20, 100]}
              onChange={(val: [number, number]) => handleRelevancy!(val)}
              min={20}
              max={100}
            />
          </div>
          <div className="relative flex justify-between items-center top-2 w-full">
            <span>20</span>
            <span className="relative">100</span>
          </div>
        </div>
      </div>

      <div className="p-6 pt-0 flex gap-3 justify-end">
        <Button
          type="button"
          onClick={resetFilters}
          variant="outlined"
          tabIndex={8}
        >
          Reset Form
        </Button>
        <Button type="button" onClick={performFilter} tabIndex={8}>
          Show Result
        </Button>
      </div>
    </div>
  );
};

export default FilterForm;
