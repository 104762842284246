import React from "react";
import FreedomLogo from "../../../assets/img/freedom-icon-white.svg";

const FreedomIcon = () => {
  return (
    <div className="w-8 h-8 inline-flex items-center justify-center bg-freedom rounded">
      <img src={FreedomLogo} className="w-5" alt="Freedom search icon"/>
    </div>
  );
};

export default FreedomIcon;
