import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useRef, useState } from "react";
import { textCleaner } from "../../../utils/helpers";
import "./style.scss";

export interface ISlider {
  data: any[];
  type: string;
}

const Slider: React.FC<ISlider> = ({ data, type }) => {
  const [current, setCurrent] = useState(0);
  const ref: any = useRef(null);

  const next = () => {
    if (current >= data.length - 1) return;
    else setCurrent(current + 1);
  };

  const prev = () => {
    if (current === 0) return;
    else setCurrent(current - 1);
  };

  const jump = (indis: number) => (e: any) => {
    setCurrent(indis);
  };

  useEffect(() => {
    if (ref) {
      ref.current.style.transition = "all 0.2s ease-in-out";
      ref.current.style.transform = `translateX(-${current}00%)`;
    }
  }, [current]);

  return (
    <div>
      <div className="frame">
        <div className="box-container" ref={ref}>
          {data.map((d, i) => {
            if (type === "novelty") {
              return (
                <div className="box" key={i}>
                  {textCleaner(d.phrase_text)}
                </div>
              );
            }
            else if (type === "freedom") {
              return (
                <div className="box" key={i}>
                  {textCleaner(d.claims_text)}
                </div>
              );
            }

          })}
        </div>
        <div className="left" onClick={prev}>
          <ChevronLeftIcon className="w-4 h-4" />
        </div>
        <div className="right" onClick={next}>
          <ChevronRightIcon className="w-4 h-4" />
        </div>
      </div>
      <div className="dots-container">
        {data.map((num, indis) => (
          <div
            className={`dot ${indis === current && "active"}`}
            onClick={jump(indis)}
            key={indis}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;
