import React from "react";
import { Button } from "../shared";

interface ISearchConfirmation {
  backHandler: () => void;
  nextHandler: () => void;
}

const SearchConfirmation: React.FC<ISearchConfirmation> = ({
  backHandler,
  nextHandler,
}) => {
  return (
    <div className="p-10">
      <h3 className="text-2xl font-sweetsanspro font-bold leading-6">Start new search?</h3>
      <div className="my-7 text-sm font-normal">
        We will initiate a new search with your refined parameters. This will
        not impact your current search results.
      </div>
      <div className="flex flex-row justify-end gap-3">
        <Button onClick={backHandler} variant="outlined">
          Go Back
        </Button>
        <Button onClick={nextHandler}>Continue</Button>
      </div>
    </div>
  );
};

export default SearchConfirmation;
