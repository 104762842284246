import { useEffect, useState } from "react";
import Empty from "../../assets/img/empty.png";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as People } from "../../assets/icons/people.svg";
import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { Button, Loader, Unauthorized } from "../../components/shared";
import { useGetPeopleaiProjectQuery } from "../../features/peopleaiProjectsApi";
import QuestionOne from "./Questions/QuestionOne";
import QuestionTwo from "./Questions/QuestionTwo";
import QuestionThree from "./Questions/QuestionThree";
import QuestionFour from "./Questions/QuestionFour";
import moment from "moment";
import { useGetPeopleaiDocumentsQuery } from "../../features/peopleaiDocumentsApi";
import { useGetPresignedUrlQuery } from "../../features/uploadApi";
import { setDocumentName } from "../../features/documentNameSlice";
import { useDispatch, useSelector } from "react-redux";

const ViewProject = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const documentName = useSelector(
    (state: any) => state.documentName.documentName
  );
  const { data: presignedUrl } = useGetPresignedUrlQuery(documentName, {
    skip: !documentName,
  });

  const {
    data: peopleaiDocuments,
    isLoading: documentLoading,
    isError: documentHasError,
    error: documentError,
  } = useGetPeopleaiDocumentsQuery(
    {
      currentPage: 1,
      pageSize: 100,
      id: Number(id),
    },
    { refetchOnMountOrArgChange: true }
  );

  const {
    data: peopleaiProject,
    isLoading,
    isError,
    error,
  } = useGetPeopleaiProjectQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (!!presignedUrl?.url) {
      (async () => await downloadDocument())();
    }
  }, [presignedUrl?.url]);

  if (isLoading || documentLoading) {
    return <Loader />;
  }

  if (isError || documentHasError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401 || documentError?.status === 401) {
      return <Unauthorized />;
    }
    // @ts-ignore: Unreachable code error
    if (error?.status === 404) {
      return <Navigate to="/peopleai" replace />;
    }
  }

  const downloadDocument = async () => {
    try {
      setLoader(true);
      const response = await fetch(presignedUrl.url);
      if (!response.ok) {
        throw new Error("Failed to download file");
      }
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = documentName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    } finally {
      setLoader(false);
      dispatch(setDocumentName(""));
    }
  };

  if (loader) {
    return <Loader />;
  }
  return (
    <div className="flex flex-col">
      <div className="flex justify-between items-center">
        <Button
          variant="filled"
          size="normal"
          onClick={() => navigate("/peopleai")}
          className="bg-dorothy-teal hover:bg-dorothy-teal/90 !py-2.5 text-base font-medium rounded-[10px]"
        >
          Back to list
        </Button>
        <Button
          variant="filled"
          size="normal"
          className="flex items-center gap-x-4 justify-center bg-dorothy-teal hover:bg-dorothy-teal/90 !py-2.5 text-base font-medium rounded-[10px]"
          onClick={() => navigate(`/projects/${peopleaiProject?.projectId}`)}
        >
          <span>Open</span>
          <People />
        </Button>
      </div>
      <div className="flex flex-col text-[14px] gap-y-3 justify-center bg-white rounded-2xl px-12 py-4 mt-12 mx-6 mb-6 bshadow">
        <span className="font-bold uppercase text-black">Project Detail</span>
        <div className="flex justify-between items-center">
          <div className="flex gap-x-8 items-center">
            <span className="font-bold italic text-black/30">Name</span>
            <span
              className="font-bold text-black truncate max-w-[250px]"
              title={peopleaiProject?.titleOfSearch
                ?.replace(/\s+/g, " ")
                ?.trim()}
            >
              {peopleaiProject?.titleOfSearch?.replace(/\s+/g, " ")?.trim()}
            </span>
          </div>
          <div className="flex gap-x-8 items-center">
            <span className="font-bold italic text-black/30">Created date</span>
            <span className="font-bold text-black">
              {moment(peopleaiProject?.createdAt).format("DD-MM-YYYY")}
            </span>
          </div>
          <div className="flex gap-x-8 items-center">
            <span className="font-bold italic text-black/30">
              Last updated date
            </span>
            <span className="font-bold text-black">
              {moment(peopleaiProject?.updatedAt).format("DD-MM-YYYY")}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col justify-center bg-white rounded-2xl p-16 mt-5 mx-6 mb-6 bshadow -[712px]">
          <QuestionOne prefilledValue={peopleaiProject?.reportType} />
        </div>
        <div className="flex flex-col justify-center bg-white rounded-2xl p-16 m-6 bshadow min-h-[712px]">
          <QuestionTwo
            prefilledValues={{
              firstName: peopleaiProject?.firstName,
              lastName: peopleaiProject?.lastName,
              phoneNumber: peopleaiProject?.phoneNumber,
              email: peopleaiProject?.email,
              companyName: peopleaiProject?.companyName,
              titleOfSearch: peopleaiProject?.titleOfSearch,
              referenceNumber: peopleaiProject?.referenceNumber,
            }}
          />
        </div>
        <div className="flex flex-col justify-center bg-white rounded-2xl p-16 m-6 bshadow min-h-[712px]">
          <QuestionThree prefilledValue={peopleaiProject?.industry} />
        </div>
        <div className="flex flex-col justify-center bg-white rounded-2xl p-16 m-6 bshadow min-h-[712px]">
          <QuestionFour
            prefilledValues={{
              keyElements: peopleaiProject?.keyElements,
              isRush: peopleaiProject?.isRush ? "Yes" : "No",
              appointmentDate: peopleaiProject?.appointmentDate,
              searchOptions: peopleaiProject?.searchOptions?.split(", "),
              nonPatentLiterature: peopleaiProject?.nonPatentLiterature,
              reportFormat: peopleaiProject?.reportFormat,
            }}
          />
        </div>
        <div className="flex flex-col justify-center bg-white rounded-2xl p-10 m-6 bshadow h-[712px]">
          <div className="border-2 border-dorothy-dark-gray rounded-2xl pt-6 px-10 pb-10 h-full">
            <h2 className="font-bold text-black uppercase text-xl">
              Documents
            </h2>
            <div className="flex flex-col gap-4 flex-wrap mt-6">
              <div className="flex flex-col gap-y-3 h-[450px] overflow-y-scroll px-2 custom-scroller">
                {peopleaiDocuments && peopleaiDocuments.count > 0 ? (
                  <>
                    <span className="font-bold italic text-black/30 text-base">
                      Name
                    </span>
                    {peopleaiDocuments?.rows?.map(({ documentName }, index) => (
                      <div
                        className="flex justify-between items-center border-2 border-dorothy-slight-gray rounded-[10px] p-4"
                        key={index}
                      >
                        <span
                          className="font-bold text-black truncate max-w-[250px]"
                          title={documentName}
                        >
                          {documentName}
                        </span>
                        <Button
                          variant="filled"
                          size="small"
                          className="flex items-center gap-x-2 justify-center bg-dorothy-teal hover:bg-dorothy-teal/90 text-base font-medium rounded-[10px]"
                          onClick={() =>
                            dispatch(setDocumentName(documentName))
                          }
                        >
                          <Download />
                          <span>Download</span>
                        </Button>
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    className="flex flex-col gap-y-6 items-center justify-center mt-[95px]"
                    style={{ height: "calc(100vh - 160px)" }}
                  >
                    <img src={Empty} />
                    <span className="font-bold text-base text-black">
                      No Documents
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProject;
