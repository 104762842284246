const TermsOfUse = () => {
  return (
    <div className="text-sm">
      <h2 className="text-2xl font-sweetsanspro font-semibold mb-11">
        Terms of Service
      </h2>
      <div className="text-gray-900">
        Welcome to <strong>DorothyAI (“Company”, “we,” “our,” “us”)!</strong> As
        you have just clicked to our Terms of Service, please make a pause, grab
        a cup of coffee and carefully read the following pages. It will take you
        approximately 20 minutes
      </div>
      <br />
      <div className="text-gray-900">
        These Terms of Service <strong>(“Terms,” “Terms of Service”)</strong>{" "}
        govern your use of Dorothy, Company’s AI-driven patent search platform{" "}
        <strong>(“Platform”)</strong> and DorothyAI’s websites and user
        community website <strong>(“Sites”)</strong>.
      </div>
      <br />
      <div className="text-gray-900">
        Your agreement with us includes these Terms{" "}
        <strong>(“Agreements”)</strong>. You acknowledge that you have read and
        understood Agreements, and agree to be bound by them.
      </div>
      <br />
      <div className="text-gray-900">
        If you do not agree with (or cannot comply with) Agreements, then you
        may not use the Service, but please let us know by emailing at{" "}
        <a
          href="mailto:customers@dorothyai.com"
          target="_blank"
          className="underline font-semibold text-dark-blue"
        >
          customers@dorothyai.com
        </a>{" "}
        so we can try to find a solution. These Terms apply to all visitors,
        users and others who wish to access or use the Service.
      </div>
      <br />
      <div className="font-bold">Thank you for being responsible.</div>
      <br />
      <ol className="list-decimal pl-6">
        <li className="font-bold">
          <span className="ml-1">Communications</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            By creating an Account on our Sites, you agree (opt-in) to subscribe
            to newsletters, marketing or promotional materials and other
            information we may send. However, you may opt out of receiving any,
            or all, of these communications from us by following the unsubscribe
            link or by emailing at.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Subscriptions</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            Some parts of Services are billed on a subscription basis ({" "}
            <strong>“Subscription(s)”</strong>). You will be billed in advance
            on a recurring and periodic basis (<strong>“Billing Cycle”</strong>
            ). Billing cycles are set on a monthly basis.
          </span>
          <br />
          <br />
          <span>
            At the end of each Billing Cycle, your Subscription will
            automatically renew under the exact same conditions unless you
            cancel it or your employer cancels it. You may cancel your
            Subscription renewal by contacting,{" "}
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="underline font-semibold text-dark-blue"
            >
              customers@dorothyai.com
            </a>
            , our customer support team.
          </span>
          <br />
          <br />
          <span>
            A valid payment method, including credit card, check, or wire
            transfer, is required to process the payment for your subscription.
            You shall provide Company with accurate and complete billing
            information including full name, address, state, zip code, telephone
            number, and a valid payment method information. By submitting such
            payment information, you automatically authorize Company to charge
            all Subscription fees incurred through your account to any such
            payment instruments.
          </span>
          <br />
          <br />
          <span>
            Should automatic billing fail to occur for any reason, Company will
            issue an electronic invoice indicating that you must proceed
            manually, within a certain deadline date, with the full payment
            corresponding to the billing period as indicated on the invoice.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Free Trial</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            Company may, at its sole discretion, offer a Subscription with a
            free trial for a limited period of time{" "}
            <strong>(“Free Trial”)</strong>.
          </span>
          <br />
          <br />
          <span>
            You may be required to enter your billing information in order to
            sign up for Free Trial.
          </span>
          <br />
          <br />
          <span>
            If you do enter your billing information when signing up for a Free
            Trial, you will not be charged by Company until Free Trial has
            expired. On the last day of Free Trial period, unless you cancelled
            your Subscription, you will be automatically charged the applicable
            Subscription fees for the type of Subscription you have selected.
          </span>
          <br />
          <br />
          <span>
            At any time and without notice, Company reserves the right to (i)
            modify Terms of Service of Free Trial offer, or (ii) cancel such
            Free Trial offer.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Fee Changes</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            Company, in its sole discretion and at any time, may modify
            Subscription fees for the Subscriptions. Any Subscription fee change
            will become effective at the end of the then-current Billing Cycle.
          </span>
          <br />
          <br />
          <span>
            Company will provide you with reasonable prior notice of any change
            in Subscription fees to give you an opportunity to terminate your
            Subscription before such change becomes effective.
          </span>
          <br />
          <br />
          <span>
            Your continued use of the Service after Subscription fee change
            comes into effect constitutes your agreement to pay the modified
            Subscription fee amount.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Refunds</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            Except when required by law, paid Subscription fees are
            non-refundable.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Content</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            Content found on or through these Services or Sites are the property
            of Company or used with permission. You may not distribute, modify,
            transmit, reuse, download, repost, copy, or use said Content,
            whether in whole or in part, for commercial purposes or for personal
            gain, without express advance written permission from us.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Prohibited Uses</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            You may use the Service only for lawful purposes and in accordance
            with Terms. You agree not to use Service:
            <br />
            <br />
            <ul className="pl-6 list-disc">
              <li className="mb-2">
                In any way that violates any applicable national or
                international law or regulation. For the purpose of exploiting,
                harming, or attempting to exploit or harm minors in any way by
                exposing them to inappropriate content or otherwise.
              </li>
              <li className="mb-2">
                To transmit, or procure the sending of, any advertising or
                promotional material, including any “junk mail,” “chain letter,”
                “spam,” or any other similar solicitation.
              </li>
              <li className="mb-2">
                To impersonate or attempt to impersonate Company, a Company
                employee, another user, or any other person or entity.
              </li>
              <li className="mb-2">
                In any way that infringes upon the rights of others, or in any
                way is illegal, threatening, fraudulent, or harmful, or in
                connection with any unlawful, illegal, fraudulent, or harmful
                purpose or activity.
              </li>
              <li className="mb-2">
                To engage in any other conduct that restricts or inhibits
                anyone’s use or enjoyment of Service, or which, as determined by
                us, may harm or offend Company or users of Service or expose
                them to liability.
              </li>
            </ul>
            <br />
            Additionally, you agree not to:
            <br />
            <br />
            <ul className="pl-6 list-disc">
              <li className="mb-2">
                Use Service in any manner that could disable, overburden,
                damage, or impair Service or interfere with any other party’s
                use of the Service, including their ability to engage in real
                time activities through Service.
              </li>
              <li className="mb-2">
                Use any robot, spider, or other automatic device, process, or
                means to access Service for any purpose, including monitoring or
                copying any of the material on Service.
              </li>
              <li className="mb-2">
                Use any manual process to monitor or copy any of the material on
                Service or for any other unauthorized purpose without our prior
                written consent.
              </li>
              <li className="mb-2">
                Use any device, software, or routine that interferes with the
                proper working of Service. Introduce any viruses, trojan horses,
                worms, logic bombs, or other material which is malicious or
                technologically harmful.
              </li>
              <li className="mb-2">
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of Service, the server on which Service is
                stored, or any server, computer, or database connected to
                Service.
              </li>
              <li className="mb-2">
                Attack Service via a denial-of-service attack or a distributed
                denial-of-service attack.
              </li>
              <li className="mb-2">
                Take any action that may damage or falsify Company rating.
              </li>
              <li className="mb-2">
                Otherwise attempt to interfere with the proper working of
                Service.
              </li>
            </ul>
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Analytics</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            We may use third-party Service Providers to monitor and analyze the
            use of our Services and Sites.
          </span>
          <br />
          <br />
          <span>
            <strong>Google Analytics</strong>. Google Analytics is a web
            analytics service offered by Google that tracks and reports website
            traffic. Google uses the data collected to track and monitor the use
            of our Services and Sites. This data is shared with other Google
            services. Google may use the collected data to contextualize and
            personalize the ads of its own advertising network.
          </span>
          <br />
          <br />
          <span>
            For more information on the privacy practices of Google, please
            visit the Google Privacy Terms web page:{" "}
            <a
              href="https://policies.google.com/privacy?hl=en"
              target="_blank"
              className="underline font-semibold text-dark-blue"
            >
              https://policies.google.com/privacy?hl=en
            </a>
          </span>
          <br />
          <br />
          <span>
            We also encourage you to review the Google's policy for safeguarding
            your data:{" "}
            <a
              href="https://support.google.com/analytics/answer/6004245"
              target="_blank"
              className="underline font-semibold text-dark-blue"
            >
              https://support.google.com/analytics/answer/6004245
            </a>
            .
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Accounts</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            When you create an account with us, you guarantee that the
            information you provide us is accurate, complete, and current at all
            times. Inaccurate, incomplete, or obsolete information may result in
            the immediate termination of your account on Services or Sites.
          </span>
          <br />
          <br />
          <span>
            You are responsible for maintaining the confidentiality of your
            account and password, including but not limited to the restriction
            of access to your computer and/or account. You agree to accept
            responsibility for any and all activities or actions that occur
            under your account and/or password, whether your password is with
            our Services, Sites, or a third-party service. You must notify us
            immediately upon becoming aware of any breach of security or
            unauthorized use of your account.
          </span>
          <br />
          <br />
          <span>
            You may not use as a username the name of another person or entity
            or that is not lawfully available for use, a name or trademark that
            is subject to any rights of another person or entity other than you,
            without appropriate authorization. You may not use as a username any
            name that is offensive, vulgar or obscene.
          </span>
          <br />
          <br />
          <span>
            Company, in its sole discretion, reserve the right to refuse
            service, terminate accounts, remove or edit content, or cancel
            orders.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Intellectual Property</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            Service and its original content (excluding Content provided by
            users), features and functionality are and will remain the exclusive
            property of the Company and its licensors. Service is protected by
            copyright, trademark, and other laws of the United States and
            foreign countries. Our trademarks and trade dress may not be used in
            connection with any product or service without the prior written
            consent of the Company.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Copyright Policy</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            We respect the intellectual property rights of others. It is our
            policy to respond to any claim that Content posted on the Service
            infringes on the copyright or other intellectual property rights (
            <strong>“Infringement”</strong>) of any person or entity.
          </span>
          <br />
          <br />
          <span>
            If you are a copyright owner, or authorized on behalf of one, and
            you believe that the copyrighted work has been copied in a way that
            constitutes copyright infringement, please submit your claim via
            email to curt@dorothyai.com, with the subject line: “Copyright
            Infringement” and include in your claim a detailed description of
            the alleged Infringement as detailed below, under “DMCA Notice and
            Procedure for Copyright Infringement Claims”
          </span>
          <br />
          <br />
          <span>
            You may be held accountable for damages (including costs and
            attorneys' fees) for misrepresentation or bad-faith claims on the
            infringement of any Content found on and/or through Service on your
            copyright.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">
            DMCA Notice and Procedure for Copyright Infringement Claims
          </span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            You may submit a notification pursuant to the Digital Millennium
            Copyright Act (DMCA) by providing our Copyright Agent with the
            following information in writing (see 17 U.S.C 512(c)(3) for further
            detail):
          </span>
          <br />
          <br />
          <ul className="pl-6 list-disc">
            <li className="mb-2">
              an electronic or physical signature of the person authorized to
              act on behalf of the owner of the copyright's interest;
            </li>
            <li className="mb-2">
              a description of the copyrighted work that you claim has been
              infringed, including the URL (i.e., web page address) of the
              location where the copyrighted work exists or a copy of the
              copyrighted work;
            </li>
            <li className="mb-2">
              identification of the URL or other specific location on Service
              where the material that you claim is infringing is located;
            </li>
            <li className="mb-2">
              your address, telephone number, and email address;
            </li>
            <li className="mb-2">
              a statement by you that you have a good faith belief that the
              disputed use is not authorized by the copyright owner, its agent,
              or the law;
            </li>
            <li className="mb-2">
              a statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner's
              behalf.
            </li>
          </ul>
          <br />
          <span>
            You can contact our Copyright Agent via email at{" "}
            <a
              href="mailto:curt@dorothyai.com"
              target="_blank"
              className="underline font-semibold text-dark-blue"
            >
              curt@dorothyai.com
            </a>
            .{" "}
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Error Reporting and Feedback</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            You may provide us directly at{" "}
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="underline font-semibold text-dark-blue"
            >
              customers@dorothyai.com
            </a>{" "}
            with information and feedback concerning errors, suggestions for
            improvements, ideas, problems, complaints, and other matters related
            to the Service (<strong>“Feedback”</strong>). You acknowledge and
            agree that: (i) you shall not retain, acquire or assert any
            intellectual property rights or other right, title or interest in or
            to the Feedback; (ii) Company may have development ideas similar to
            the Feedback; (iii) Feedback does not contain confidential
            information or proprietary information from you or any third party;
            and (iv) Company is not under any obligation of confidentiality with
            respect to the Feedback. In the event the transfer of the ownership
            to the Feedback is not possible due to applicable mandatory laws,
            you grant that Company and its affiliates an exclusive,
            transferable, irrevocable, free-of-charge, sub-licensable, unlimited
            and perpetual right to use (including copy, modify, create
            derivative works, publish, distribute and commercialize) Feedback in
            any manner and for any purpose.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Links To Other Websites</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            The Service may contain links to third party web sites or services
            that are not owned or controlled by Company.
          </span>
          <br />
          <br />
          <span>
            Company has no control over, and assumes no responsibility for the
            content, privacy policies, or practices of any third party web sites
            or services. We do not warrant the offerings of any of these
            entities/individuals or their websites.
          </span>
          <br />
          <br />
          <span>
            YOU ACKNOWLEDGE AND AGREE THAT COMPANY SHALL NOT BE RESPONSIBLE OR
            LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR
            ALLEGED TO BE CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON
            ANY SUCH CONTENT, GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH
            THIRD PARTY WEB SITES OR SERVICES.
          </span>
          <br />
          <br />
          <span>
            WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY
            POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Disclaimer Of Warranty</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF
            ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES,
            OR THE INFORMATION, CONTENT OR MATERIALS INCLUDED THEREIN. YOU
            EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR CONTENT, AND
            ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
          </span>
          <br />
          <br />
          <span>
            NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY
            WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE
            SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ANYONE
            ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES,
            THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED,
            THAT DEFECTS WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT
            MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS
            OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
            SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
          </span>
          <br />
          <br />
          <span>
            COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
            OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
            ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
            A PARTICULAR PURPOSE.
          </span>
          <br />
          <br />
          <span>
            THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
            EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Limitation Of Liability</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS,
            DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT,
            PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGE, HOWEVER IT
            ARISES (INCLUDING ATTORNEYS' FEES AND ALL RELATED COSTS AND EXPENSES
            OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL, IF ANY,
            WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN
            AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR
            ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING
            WITHOUT LIMITATION ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE,
            ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL,
            STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF
            COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON
            THE PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE
            PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE
            CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE
            EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL
            DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Termination</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            We may terminate or suspend your account and bar access to the
            Service immediately, without prior notice or liability, under our
            sole discretion, for any reason whatsoever and without limitation,
            including but not limited to a breach of Terms.
          </span>
          <br />
          <br />
          <span>
            If you wish to terminate your account, you may simply discontinue
            using Service.
          </span>
          <br />
          <br />
          <span>
            All provisions of Terms, which by their nature, should survive
            termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity
            and limitations of liability.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Governing Law</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            These Terms shall be governed and construed in accordance with the
            laws of the Commonwealth of Pennsylvania without regard to its
            conflict of law provisions.
          </span>
          <br />
          <br />
          <span>
            Our failure to enforce any right or provision of these Terms will
            not be considered a waiver of those rights. If any provision of
            these Terms is held to be invalid or unenforceable by a court, the
            remaining provisions of these Terms will remain in effect. These
            Terms constitute the entire agreement between us regarding our
            Service and supersede and replace any prior agreements we might have
            had between us regarding Service.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Changes To Service</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            We reserve the right to withdraw or amend our Service, and any
            service or material we provide via Service, in our sole discretion
            without notice. We will not be liable if for any reason all or any
            part of the Service is unavailable at any time or for any period.
            From time to time, we may restrict access to some parts of Service,
            or the entire Service, to users, including registered users.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Amendments To Terms</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            We may amend these Terms at any time by posting the amended terms on
            this site. It is your responsibility to review these Terms
            periodically.
          </span>
          <br />
          <br />
          <span>
            Your continued use of the Platform following the posting of revised
            Terms means that you accept and agree to the changes. You are
            expected to check this page frequently so you are aware of any
            changes, as they are binding on you.
          </span>
          <br />
          <br />
          <span>
            By continuing to access or use our Service after any revisions
            become effective, you agree to be bound by the revised terms. If you
            do not agree to the new terms, you are no longer authorized to use
            Service.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Waiver And Severability</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            No waiver by Company of any term or condition set forth in the Terms
            shall be deemed a further or continuing waiver of such term or
            condition or a waiver of any other term or condition, and any
            failure of Company to assert a right or provision under Terms shall
            not constitute a waiver of such right or provision.
          </span>
          <br />
          <br />
          <span>
            If any provision of the Terms is held by a court or other tribunal
            of competent jurisdiction to be invalid, illegal or unenforceable
            for any reason, such provision shall be eliminated or limited to the
            minimum extent such that the remaining provisions of Terms will
            continue in full force and effect.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Acknowledgement</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE
            THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY
            THEM.
          </span>
        </p>
        <li className="font-bold">
          <span className="ml-1">Contact Us</span>
        </li>
        <p className="mt-2 mb-6">
          <span>
            Please send your feedback, comments, requests for technical support
            by email at{" "}
            <a
              href="mailto:customers@dorothyai.com"
              target="_blank"
              className="underline font-semibold text-dark-blue"
            >
              customers@dorothyai.com
            </a>
            .
          </span>
        </p>
      </ol>
    </div>
  );
};

export default TermsOfUse;
