import { useEffect, useState } from "react";
import { Loader } from "rsuite";

const PatentRequestForm = () => {
  const [iframeLoaded, setIframeLoaded] = useState<boolean>(false);
  useEffect(() => {
    const iframeId = "gps-form-viewer";
    const iframeUrl =
      "https://commercial.globalpatentsolutions.com/search-request-form";
    const iframe = document.createElement("iframe");
    iframe.id = iframeId;
    iframe.style.width = "100%";
    iframe.style.border = "none";
    iframe.onload = () => {
      setIframeLoaded(true);
    };
    iframe.src = `${iframeUrl}?iframe_url=%22https%3A%2F%2Fcommercial.globalpatentsolutions.com%2Fsearch-request-form%22&client_id=${process.env.REACT_APP_PATENT_CLIENT_ID}&client_secret=${process.env.REACT_APP_PATENT_CLIENT_SECRET}&global=%7B%22font_color%22%3A%22%23364f4f%22%2C%22background_color%22%3A%22%23f4f4f4%22%7D&input=%7B%22font_color%22%3A%22%23212529%22%2C%22background_color%22%3A%22%23e8e8e8%22%2C%22border_color%22%3A%22%23ced4da%22%7D&button=%7B%22font_color%22%3A%22%23fff%22%2C%22background_color%22%3A%22%230d6efd%22%2C%22border_color%22%3A%22%230d6efd%22%7D&success=%7B%22font_color%22%3A%22%230f5132%22%2C%22background_color%22%3A%22%23d1e7dd%22%2C%22border_color%22%3A%22%23badbcc%22%7D&error=%7B%22font_color%22%3A%22%23842029%22%2C%22background_color%22%3A%22%23f8d7da%22%7D`;

    document.getElementById("iframe-container")?.appendChild(iframe);
    function resizeIframe(height: number) {
      var newHeight = height + 35;
      document.getElementById(iframeId)!.style.height = newHeight + "px";
    }
    function scrollToTop() {
      document.getElementById(iframeId)?.scrollIntoView(true);
    }
    function handleMessage(e: any) {
      if (iframeUrl.includes(e.origin)) {
        if (e.data === "scrollToTop") {
          scrollToTop();
        } else {
          resizeIframe(e.data);
        }
      }
    }
    window.addEventListener("message", handleMessage, false);
    return () => {
      document.getElementById("iframe-container")?.removeChild(iframe);
      window.removeEventListener("message", handleMessage, false);
    };
  }, []);

  return (
    <>
      {!iframeLoaded && <Loader size="md" center />}
      <div id="iframe-container"></div>
    </>
  );
};

export default PatentRequestForm;
