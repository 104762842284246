import React from "react";
import { dateFormatOptions, dateTypes } from "../../utils/consts";

const DateRange = ({
  type,
  from,
  to,
}: {
  type?: string;
  from?: string;
  to?: string;
}) => {
  const dateLabel = type
    ? dateTypes.filter((d) => d.value === type)[0].label
    : "unknown";
  const dateFrom = from
    ? new Date(from).toLocaleDateString("en-US", dateFormatOptions)
    : "unknown";
  const dateTo = to
    ? new Date(to).toLocaleDateString("en-US", dateFormatOptions)
    : "unknown";

  return (
    <div className="flex gap-1">
      <span className="bg-gray-500 text-white px-2 py-1 rounded">
        {dateLabel}: {dateFrom} - {dateTo}
      </span>
    </div>
  );
};

export default DateRange;
