import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetProjectQuery } from "../../../features/projectsApi";

const BreadcrumbItem = ({ title, url }: { title?: string; url: string }) => {
  const navigate = useNavigate();
  if (!title) {
    return null;
  }
  return (
    <li>
      <div className="flex items-center">
        <span
          className="mr-2 text-sm cursor-pointer font-base text-gray-500 hover:text-gray-700"
          onClick={() => navigate(url)}
        >
          {title}
        </span>
        <ChevronRightIcon
          className="h-5 w-5 mr-2 flex-shrink-0 text-gray-400"
          aria-hidden="true"
        />
      </div>
    </li>
  );
};

const ProjectBreadcrumb = ({ projectId }: { projectId: any }) => {
  const { data } = useGetProjectQuery(projectId);
  return <BreadcrumbItem title={data?.title} url={"/projects/" + data?.id} />;
};

const Breadcrumbs = () => {
  const location = useLocation();

  let { projectId, searchId } = useParams();

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center pl-4">
        {location.pathname === "/projects/new" || projectId ? (
          <BreadcrumbItem title="Projects" url="/projects" />
        ) : (
          ""
        )}

        {(searchId || location.pathname.includes("/projects/edit/")) && (
          <ProjectBreadcrumb projectId={projectId} />
        )}
      </ol>
    </nav>
  );
};

export default Breadcrumbs;
