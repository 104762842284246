import { NavLink, useLocation } from "react-router-dom";
import {
  ArrowRightOnRectangleIcon,
  ClipboardDocumentListIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { ReactComponent as People } from "../assets/icons/people.svg";
import { getPermissions } from "../utils/helpers";

const Menu = ({ onClose, onLogout }: { onClose?: any; onLogout?: any }) => {
  const askDotUrl = process.env.REACT_APP_ASKDOT_URL || "";
  const permissions = getPermissions();
  const { pathname } = useLocation();
  return (
    <>
      <NavLink
        to="/projects"
        className={({ isActive }) =>
          isActive && pathname === "/projects"
            ? "menu-link-active"
            : "menu-link"
        }
        onClick={onClose}
      >
        <ClipboardDocumentListIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Projects
      </NavLink>
      <NavLink
        to="/peopleai"
        className={({ isActive }) =>
          isActive ? "menu-link-active" : "menu-link"
        }
        onClick={onClose}
      >
        <span className="mr-3 h-8 w-8 flex-shrink-0 people">
          <People />
        </span>
        People+AI
      </NavLink>
      <NavLink
        to="/crosscite"
        className={({ isActive }) =>
          isActive ? "menu-link-active" : "menu-link"
        }
        onClick={onClose}
      >
        <img
          src="/crosscite.png"
          className="mr-3 h-8 w-8 flex-shrink-0 opacity-70"
        />
        CrossCite
      </NavLink>
      {permissions.includes("askdot") && (
        <a href={askDotUrl} className="menu-link" target="_blank">
          <img
            src="/askdot.ico"
            className="mr-3 h-8 w-8 flex-shrink-0 opacity-70"
          />
          AskDot
        </a>
      )}
      <NavLink
        to="/help"
        className={({ isActive }) =>
          isActive ? "menu-link-active" : "menu-link"
        }
        onClick={onClose}
      >
        <QuestionMarkCircleIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Help
      </NavLink>
      <span className="menu-link cursor-pointer" onClick={onLogout}>
        <ArrowRightOnRectangleIcon className="mr-3 h-8 w-8 flex-shrink-0" />
        Logout
      </span>
    </>
  );
};

export default Menu;
