import React from "react";

type colorVariant = "gray" | "darkgray" | "orange";

interface ILabel {
  icon?: any;
  color?: colorVariant;
  children: string | React.ReactNode | undefined;
}

const labelStyles: any = {
  gray: "bg-gray-200",
  darkgray: "bg-gray-500 text-white",
  orange: "bg-dorothy-orange text-white",
};

const Label: React.FC<ILabel> = ({ color = "gray", icon, children }) => {
  return (
    <span
      className={`text-xs mr-2 px-2.5 py-1 rounded-sm flex items-center gap-1 ${labelStyles[color]}`}
    >
      {icon}
      <span>{children}</span>
    </span>
  );
};

export default Label;
