import React from "react";

interface ILinkButton {
  onClick?: any;
  children: string | React.ReactNode;
}

const LinkButton: React.FC<ILinkButton> = ({ children, onClick }) => {
  return (
    <span className="text-dorothy-orange cursor-pointer inline-flex" onClick={onClick}>
      {children}
    </span>
  );
};

export default LinkButton;
