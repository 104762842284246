import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface IPeopleaiDocument {
  id: number;
  peopleaiProjectId: number;
  documentName: string;
  documentUrl: string;
  documentSize: string;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  creatorId?: string;
  updaterId?: string | null;
  deleterId?: string | null;
}

export interface IPeopleaiDocumentList {
  count: number;
  rows: IPeopleaiDocument[];
}

export const peopleaiDocumentsApi = createApi({
  reducerPath: "peopleaiDocumentsApi",
  tagTypes: ["peopleaiDocuments"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const token: any = localStorage.getItem("okta-token-storage");
      const accessToken = JSON.parse(token)?.accessToken?.accessToken;
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPeopleaiDocuments: builder.query<
      IPeopleaiDocumentList,
      { currentPage: number; pageSize: number; id: number }
    >({
      query: (arg) => {
        const { currentPage, pageSize, id } = arg;
        return {
          url: "/peopleai/project-documents",
          params: { currentPage, pageSize, id },
        };
      },
      providesTags: ["peopleaiDocuments"],
    }),
    getPeopleaiDocument: builder.query<IPeopleaiDocument, any>({
      query: (id) => {
        return {
          url: `/peopleai/project-documents/${id}`,
        };
      },
      providesTags: ["peopleaiDocuments"],
    }),
  }),
});

export const { useGetPeopleaiDocumentQuery, useGetPeopleaiDocumentsQuery } =
  peopleaiDocumentsApi;
