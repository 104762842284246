import React from "react";
import { useNavigate } from "react-router-dom";
import { LinkButton } from "../shared";
import { IProject } from "../../features/projectsApi";
import { PencilIcon } from "@heroicons/react/24/outline";

interface IProjectDetailCard {
  data: IProject | undefined;
  type?: "normal" | "modal";
}

const ProjectDetailCard: React.FC<IProjectDetailCard> = ({
  data,
  type = "normal",
}) => {
  const navigation = useNavigate();
  return (
    <div
      className={`bg-white rounded-md p-6 font-normal text-xs ${
        type === "modal" && "p-0"
      }`}
    >
      <div
        className={`flex justify-between items-center ${
          type === "modal" && "hidden"
        }`}
      >
        <div className="text-lg font-sweetsanspro font-bold">Project Details</div>
        <div>
          <LinkButton onClick={() => navigation(`/projects/edit/${data?.id}`)}>
            <PencilIcon className="w-4 h4 mr-2" />
            Edit
          </LinkButton>
        </div>
      </div>

      <div
        className={`${
          type === "modal" ? "flex flex-row justify-between" : "mt-6"
        }`}
      >
        <div>
          <div className="font-bold">Client Name</div>
          <div className="mt-3 break-all">{data?.client || "---"}</div>
        </div>
        <div className="xl:hidden">
          <LinkButton onClick={() => navigation(`/projects/edit/${data?.id}`)}>
            <PencilIcon className="w-4 h4 mr-2" />
            Edit
          </LinkButton>
        </div>
      </div>
      <div className="mt-6">
        <div className="font-bold">Owner</div>
        <div className="mt-3">
          {data?.ownerInfo
            ? data.ownerInfo.profile.firstName +
              " " +
              data.ownerInfo.profile.lastName
            : "----"}
        </div>
      </div>
      <div className="mt-6">
        <div className="font-bold">Reference No.</div>
        <div className="mt-3 break-all">{data?.referenceNumber || "---"}</div>
      </div>
      <div className="mt-6">
        <div className="font-bold">Headquarters</div>
        <div className="mt-3">
          {data?.headQuarters && data.headQuarters.length > 0
            ? data?.headQuarters.join()
            : "---"}
        </div>
      </div>
      <div className="mt-6">
        <div className="font-bold">Manufacture</div>
        <div className="mt-3">
          {data?.manufacture && data.manufacture.length > 0
            ? data?.manufacture.join()
            : "---"}
        </div>
      </div>
      <div className="mt-6">
        <div className="font-bold">Primary Markets</div>
        <div className="mt-3">
          {data?.primaryMarkets && data.primaryMarkets.length > 0
            ? data?.primaryMarkets.join()
            : "---"}
        </div>
      </div>
      <div className="mt-6">
        <div className="font-bold">Description</div>
        <div className="mt-3 break-all">{data?.description || "---"}</div>
      </div>
    </div>
  );
};

export default ProjectDetailCard;
