import React from "react";
import Select, {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  StylesConfig,
  components,
} from "react-select";
import { ReactComponent as DownArrow } from "../../../assets/icons/down-arrow.svg";
import { ReactComponent as UpArrow } from "../../../assets/icons/up-arrow.svg";
import { ReactComponent as Clear } from "../../../assets/icons/cancel.svg";
import { TooltipInfoIcon } from "../";

export interface IReactselectItem {
  value: string;
  label: string;
}

export interface IReactselectList extends Array<IReactselectItem> {}

interface IReactselectProps {
  data?: IReactselectItem | any;
  defaultValue?: any;
  name: string;
  label?: string;
  multiple?: boolean;
  menuPosition?: string | any;
  isLoading?: boolean;
  onChange?: any;
  isClearable?: boolean;
  isSearchable?: boolean;
  noSeparator?: boolean;
  required?: boolean;
  value?: any;
  selectRef?: any;
  tooltip?: boolean;
  tooltipId?: string;
  tabIndex?: number;
  placeholder?: string;
  styles?: StylesConfig;
  customArrow?: boolean;
  customClear?: boolean;
}
const IDropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      {props.selectProps.menuIsOpen ? <UpArrow /> : <DownArrow />}
    </components.DropdownIndicator>
  );
};

const IClearIndicator = (props: ClearIndicatorProps) => {
  return (
    <components.ClearIndicator {...props}>
      <Clear />
    </components.ClearIndicator>
  );
};
const ReactSelect: React.FC<IReactselectProps> = ({
  data,
  defaultValue,
  name,
  label,
  multiple = false,
  menuPosition = "bottom",
  isLoading,
  onChange,
  isClearable = true,
  required,
  value,
  placeholder,
  selectRef,
  tooltip = false,
  tooltipId,
  tabIndex,
  styles,
  isSearchable,
  noSeparator,
  customArrow,
  customClear,
}) => {
  return (
    <div className="mb-5">
      {label && (
        <span className="mb-1 text-sm block">
          {label} {required && <b className="text-error">*</b>}
          {tooltip && <TooltipInfoIcon tooltipId={tooltipId} classes="ml-3" />}
        </span>
      )}
      <Select
        isMulti={multiple}
        name={name}
        options={data}
        styles={styles}
        placeholder={placeholder}
        className={`react-select-input text-sm`}
        components={{
          ...(noSeparator ? { IndicatorSeparator: () => null } : {}),
          ...(customArrow ? { DropdownIndicator: IDropdownIndicator } : {}),
          ...(customClear ? { ClearIndicator: IClearIndicator } : {}),
        }}
        menuPlacement={menuPosition}
        defaultValue={defaultValue}
        isLoading={isLoading}
        onChange={onChange}
        isClearable={isClearable}
        isSearchable={isSearchable}
        ref={selectRef}
        value={value}
        tabIndex={tabIndex}

        //getOptionLabel={(op:any) => `${op['value']}`}
      />
    </div>
  );
};

export default ReactSelect;
