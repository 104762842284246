import React from "react";
import cx from "classnames";
import { ReactComponent as Previous } from "../../assets/icons/previous.svg";
import { ReactComponent as Next } from "../../assets/icons/next.svg";
import QuestionOne from "./Questions/QuestionOne";
import QuestionTwo from "./Questions/QuestionTwo";
import QuestionThree from "./Questions/QuestionThree";
import QuestionFour from "./Questions/QuestionFour";
import QuestionFive from "./Questions/QuestionFive";
import { useFormContext } from "../../contexts/FormContext";

interface QuestionItemProps {
  title?: string;
  options?: string[];
  decideNextButton: () => { [key: string]: boolean };
}

const DisplayQuestion = (currentQuestionIndex: number) => {
  switch (currentQuestionIndex) {
    case 1:
      return <QuestionOne />;
    case 2:
      return <QuestionTwo />;
    case 3:
      return <QuestionThree />;
    case 4:
      return <QuestionFour />;
    case 5:
      return <QuestionFive />;
  }
};

const QuestionItem: React.FC<QuestionItemProps> = ({ decideNextButton }) => {
  const { currentQuestionIndex, setCurrentQuestionIndex } = useFormContext();
  return (
    <div className="relative flex flex-col justify-center bg-white rounded-2xl p-16 m-20 bshadow min-h-[712px] responsive">
      {currentQuestionIndex !== 1 && (
        <div
          className="absolute top-1/2 -left-11 -translate-y-1/2 icon-hover cursor-pointer"
          onClick={() =>
            setCurrentQuestionIndex((prevQuestion: number) => prevQuestion - 1)
          }
        >
          <Previous />
        </div>
      )}
      {currentQuestionIndex !== 5 && (
        <div
          className={cx("absolute top-1/2 -right-11 -translate-y-1/2", {
            "disabled-icon":
              currentQuestionIndex === 5 || decideNextButton().shouldDisable,
            "icon-hover cursor-pointer":
              currentQuestionIndex <= 5 && decideNextButton().shouldEnable,
          })}
          onClick={() =>
            setCurrentQuestionIndex((prevQuestion: number) =>
              prevQuestion < 5 ? prevQuestion + 1 : prevQuestion
            )
          }
        >
          <Next />
        </div>
      )}

      {DisplayQuestion(currentQuestionIndex)}
    </div>
  );
};

export default QuestionItem;
