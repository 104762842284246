import { useState } from "react";
import { CROSSCITE_URL } from "../constants/urls";
import { Loader } from "../components/shared";

const CrossCite = () => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <>
      {loading && <Loader />}
      <iframe
        src={CROSSCITE_URL}
        style={{
          width: "100%",
          height: "calc(100vh - 113px)",
        }}
        allowFullScreen
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

export default CrossCite;
