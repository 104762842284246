import { Menu, Transition } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { IProject } from "../../features/projectsApi";
import { Label, QueueIcon } from "../shared";

interface IProjectDetail {
  onClick?: React.MouseEventHandler;
  onDelete?: React.MouseEventHandler;
  data: IProject;
}

const ProjectCard: React.FC<IProjectDetail> = ({ onClick, onDelete, data }) => {
  const navigation = useNavigate();
  const inQueueCount = data.queueCounts?.inQueue || 0;
  const readyCount = data.queueCounts?.ready || 0;
  return (
    <div className="bg-white h-full rounded-xl shadow-sm p-6 cursor-pointer flex flex-col justify-between hover:shadow-lg">
      <div className="flex justify-between">
        <h3
          className="font-bold text-lg leading-6 flex-1 w-full break-words"
          onClick={onClick}
        >
          {data.title}
        </h3>
        <div className="w-5 h-5">
          <Menu as="div" className="relative">
            <Menu.Button className="flex max-w-xs items-center rounded-sm focus:outline-none focus:ring-2 focus:ring-dark-blue focus:ring-offset-2">
              <EllipsisVerticalIcon className="w-6 h-6" />
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                    onClick={() => navigation(`edit/${data.id}`)}
                  >
                    Edit Project
                  </div>
                </Menu.Item>
                <Menu.Item>
                  <div
                    onClick={onDelete}
                    className="block px-4 py-2 text-sm text-red-700 cursor-pointer hover:bg-gray-100"
                  >
                    Delete Project
                  </div>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <div onClick={onClick} className="flex-1">
        <div className="flex text-xs mt-7">
          <div className="flex-none w-16 font-normal">Client</div>
          <div className="flex-1 font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
            {data?.client || "----"}
          </div>
        </div>
        <div className="flex text-xs mt-4">
          <div className="flex-none w-16 font-normal">Owner</div>
          <div className="flex-1 font-semibold">
            {data.ownerInfo
              ? data.ownerInfo?.profile.firstName +
                " " +
                data.ownerInfo?.profile.lastName
              : "----"}
          </div>
        </div>
        <div className="flex text-xs mt-4">
          <div className="flex-none w-16 font-normal">Ref No</div>
          <div className="flex-1 font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
            {data?.referenceNumber || "----"}
          </div>
        </div>
        <div className="text-xs font-normal mt-7 leading-4 break-words">
          {data?.description?.slice(0, 250)}
        </div>
      </div>
      <div className="flex mt-4" onClick={onClick}>
        <Label
          color={`${inQueueCount > 0 ? "orange" : "gray"}`}
          icon={<QueueIcon color={`${inQueueCount ? "white" : "black"}`} />}
        >
          Queue:&nbsp;<b>{inQueueCount}</b>
        </Label>
        <Label color="gray" icon={<CheckCircleIcon className="w-4 h-4" />}>
          Completed:&nbsp;<b>{readyCount}</b>
        </Label>
      </div>
    </div>
  );
};

export default ProjectCard;
