import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import { Fragment } from "react";

interface IModalProps {
  children: JSX.Element;
  type?: "simple" | "dialog";
  closeHandler: () => void;
  isShow: boolean;
  title?: string;
  titleIcon?: JSX.Element | undefined;
  description?: string | JSX.Element;
  closeButton?: boolean;
  overlayClose?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  type = "dialog",
  isShow,
  title,
  description,
  titleIcon,
  closeButton = false,
  closeHandler,
  children,
  overlayClose = true,
}) => {
  return (
    <Transition appear show={isShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={overlayClose ? closeHandler : () => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-1 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full transform overflow-hidden rounded-xl bg-white text-left align-middle shadow-lg transition-all ${
                  type === "simple" ? "max-w-xl" : "max-w-3xl"
                }`}
              >
                {type === "dialog" && (
                  <div
                    className={`p-7 pb-4 border-b border-gray-300 ${
                      description ? "pb-5" : "xl:pb-7"
                    }`}
                  >
                    <div className="flex justify-between items-center">
                      <div>
                        {title && (
                          <h3 className="text-2xl inline-flex items-center h-8 font-sweetsanspro font-bold">
                            {titleIcon && (
                              <span className="mr-2">{titleIcon}</span>
                            )}
                            <span>{title}</span>
                          </h3>
                        )}
                      </div>
                      {closeButton && (
                        <button
                          onClick={closeHandler}
                          tabIndex={-1}
                          className="w-10 h-10 bg-dorothy-gray rounded items-center justify-center inline-flex hover:bg-gray-300"
                        >
                          <XMarkIcon className="w-7 h-7" />
                        </button>
                      )}
                    </div>
                    {description && (
                      <p className="text-sm mt-2 font-normal">{description}</p>
                    )}
                  </div>
                )}
                <div className="p-7 font-normal text-sm">{children}</div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default Modal;
