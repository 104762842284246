import React from "react";
import {
  IProject,
  useAddProjectMutation,
  useUpdateProjectMutation,
} from "../../features/projectsApi";
import { Button, Input, MultiSelect, Snackbar, Textarea } from "../shared";
import Countries from "../../assets/countries.json";
import { sortCountries } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Formik, Form, Field } from "formik";
import { projectSchema } from "../../validations";

type ActionType = "new" | "edit";

interface IProjectForm {
  type: ActionType;
  data?: IProject;
  owners: any[];
  selectedOwner?: any;
  headQuartersData?: any[];
  manufactureData?: any[];
  primaryMarketsData?: any[];
}

const initialValues: IProject = {
  title: "",
  description: "",
  client: "",
  ownerId: "",
  referenceNumber: "",
  headQuarters: [],
  manufacture: [],
  primaryMarkets: [],
};

const ProjectForm: React.FC<IProjectForm> = ({
  type,
  data,
  owners,
  selectedOwner,
}) => {
  let defaultValues: any;
  if (data) {
    defaultValues = {
      id: data.id,
      title: data.title,
      description: data.description,
      client: data.client,
      ownerId: data.ownerId,
      referenceNumber: data.referenceNumber,
      headQuarters: data.headQuarters,
      manufacture: data.manufacture,
      primaryMarkets: data.primaryMarkets,
    };
  } else {
    defaultValues = { ...initialValues, ownerId: selectedOwner?.value || "" };
  }

  const navigate = useNavigate();
  const [addProject] = useAddProjectMutation();
  const [updateProject] = useUpdateProjectMutation();
  const sortedCountries = sortCountries(Countries);

  const onSubmit = (values: any, actions: any) => {
    if (type === "edit") {
      updateProject(values)
        .then((status) => {
          if ('data' in status) {
            toast.success((t) => (
              <Snackbar
                message={
                  <>
                    Project <b>updated.</b>
                  </>
                }
                onClose={() => toast.dismiss(t.id)}
              />
            ));
          } else {
            toast.error((t) => (
              <Snackbar
                message={
                  <>
                    <b>Failed</b> to update project.
                  </>
                }
                onClose={() => toast.dismiss(t.id)}
              />
            ));
          }
          navigate(-1);
        })
        .catch((error) => alert("An error occured"));
    } else {
      addProject(values)
        .then((response: any) => {
          if ('data' in response) {
            toast.success((t) => (
              <Snackbar
                message={
                  <>
                    Project <b>created.</b>
                  </>
                }
                onClose={() => toast.dismiss(t.id)}
              />
            ));
          } else {
            toast.error((t) => (
              <Snackbar
                message={
                  <>
                    <b>Failed</b> to create project.
                  </>
                }
                onClose={() => toast.dismiss(t.id)}
              />
            ));
          }
          if (response?.data?.item?.id) {
            navigate("/projects/" + response.data.item.id);
          } else {
            navigate("/projects");
          }
        })
        .catch((error) => alert("An error occured"));
    }
  };

  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={projectSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, values }) => (
        <Form autoComplete="off">
          <Field
            name="title"
            type="text"
            label="Title"
            as={Input}
            error={errors.title && touched.title ? true : false}
            errorMsg={touched.title && errors.title}
            required
          />
          <Field
            name="description"
            type="textarea"
            label="Description"
            as={Textarea}
            error={errors.description && touched.description}
            errorMsg={touched.description && errors.description}
          />

          <Field
            name="ownerId"
            label="Owner"
            type="text"
            component={MultiSelect}
            error={errors.ownerId && touched.ownerId}
            errorMsg={touched.ownerId && errors.ownerId}
            data={owners}
            isClearable
            menuPosition="bottom"
            placeholder="Select..."
          />

          <h4 className="mt-10 mb-6 text-lg font-bold">Additional Details</h4>
          <Field
            name="client"
            type="text"
            label="Client Name"
            as={Input}
            error={errors.client && touched.client}
            errorMsg={touched.client && errors.client}
          />
          <Field
            name="referenceNumber"
            type="text"
            label="Reference Number"
            as={Input}
            error={errors.referenceNumber && touched.referenceNumber}
            errorMsg={touched.referenceNumber && errors.referenceNumber}
          />

          <div className="grid gap-4 grid-cols-1 sm:grid-cols-3">
            <div>
              <Field
                name="headQuarters"
                label="Headquarters"
                component={MultiSelect}
                error={errors.headQuarters && touched.headQuarters}
                errorMsg={touched.headQuarters && errors.headQuarters}
                data={sortedCountries}
                menuPosition="top"
                multiple
                placeholder="Select..."
              />
            </div>
            <div>
              <Field
                name="manufacture"
                label="Manufacture"
                component={MultiSelect}
                error={errors.manufacture && touched.manufacture}
                errorMsg={touched.manufacture && errors.manufacture}
                data={sortedCountries}
                menuPosition="top"
                multiple
                placeholder="Select..."
              />
            </div>
            <div>
              <Field
                name="primaryMarkets"
                label="Primary Markets"
                component={MultiSelect}
                error={errors.primaryMarkets && touched.primaryMarkets}
                errorMsg={touched.primaryMarkets && errors.primaryMarkets}
                data={sortedCountries}
                menuPosition="top"
                multiple
                placeholder="Select..."
              />
            </div>
          </div>
          <div className="mt-6 mb-10 flex flex-row gap-4">
            {
              <Button
                type="button"
                variant="outlined"
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            }
            {type === "new" ? (
              <Button type="submit">Create</Button>
            ) : (
              <Button type="submit">Update</Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ProjectForm;
