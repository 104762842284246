import React, { createContext, useContext, useState, ReactNode } from "react";

interface QuestionTwoFormValues {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  companyName: string;
  titleOfSearch: string;
  referenceNumber: string;
}
interface QuestionFourFormValues {
  keyElements: string;
  isRush: string | null;
  appointmentDate: string | undefined;
  searchOptions: string[];
  nonPatentLiterature: string;
  reportFormat: string;
}

interface QuestionNextState {
  disable: boolean;
  enable: boolean;
}

interface FormContextProps {
  resetAnswers: () => void;
  questionFourInitialValues: QuestionFourFormValues;
  questionTwoInitialValues: QuestionTwoFormValues;
  answers: any;
  handleAnswers: (answer: any) => void;
  isFormCompleted: boolean;
  setIsFormCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  currentQuestionIndex: number;
  setCurrentQuestionIndex: React.Dispatch<React.SetStateAction<number>>;
  progress: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  peopleAIFiles: File[];
  setPeopleAIFiles: React.Dispatch<React.SetStateAction<File[]>>;
  isTermsSelected: boolean;
  setIsTermsSelected: React.Dispatch<React.SetStateAction<boolean>>;
  isPrivacySelected: boolean;
  setIsPrivacySelected: React.Dispatch<React.SetStateAction<boolean>>;
  questionTwoNext: QuestionNextState;
  setQuestionTwoNext: React.Dispatch<React.SetStateAction<QuestionNextState>>;
  otherIndustry: string;
  setOtherIndustry: React.Dispatch<React.SetStateAction<string>>;
  questionFourNext: QuestionNextState;
  setQuestionFourNext: React.Dispatch<React.SetStateAction<QuestionNextState>>;
  questionTwoFormValues: QuestionTwoFormValues;
  setQuestionTwoFormValues: (values: QuestionTwoFormValues) => void;
  questionFourFormValues: QuestionFourFormValues;
  setQuestionFourFormValues: (values: QuestionFourFormValues) => void;
}

const questionTwoInitialValues: QuestionTwoFormValues = {
  firstName: "",
  lastName: "",
  phoneNumber: "",
  email: "",
  companyName: "",
  titleOfSearch: "",
  referenceNumber: "",
};

const questionFourInitialValues: QuestionFourFormValues = {
  keyElements: "",
  isRush: "",
  appointmentDate: "",
  searchOptions: [],
  nonPatentLiterature: "",
  reportFormat: "",
};

const FormContext = createContext<FormContextProps | undefined>(undefined);

export const FormProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [peopleAIFiles, setPeopleAIFiles] = useState<File[]>([]);
  const [answers, setAnswers] = useState<any>({});
  const [progress, setProgress] = useState<number>(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(1);
  const [isTermsSelected, setIsTermsSelected] = useState<boolean>(false);
  const [isPrivacySelected, setIsPrivacySelected] = useState<boolean>(false);
  const [questionTwoNext, setQuestionTwoNext] = useState<QuestionNextState>({
    disable: false,
    enable: false,
  });
  const [questionFourNext, setQuestionFourNext] = useState<QuestionNextState>({
    disable: false,
    enable: false,
  });
  const [isFormCompleted, setIsFormCompleted] = useState<boolean>(false);
  const [otherIndustry, setOtherIndustry] = useState<string>("");
  const [questionTwoFormValues, setQuestionTwoFormValues] =
    useState<QuestionTwoFormValues>(questionTwoInitialValues);
  const [questionFourFormValues, setQuestionFourFormValues] =
    useState<QuestionFourFormValues>(questionFourInitialValues);

  const handleAnswers = (answer: any) => {
    setAnswers({
      ...answers,
      [currentQuestionIndex]: answer,
    });
  };

  const resetAnswers = () => {
    setAnswers({});
  };

  return (
    <FormContext.Provider
      value={{
        progress,
        setProgress,
        answers,
        resetAnswers,
        handleAnswers,
        currentQuestionIndex,
        setCurrentQuestionIndex,
        peopleAIFiles,
        setPeopleAIFiles,
        isTermsSelected,
        setIsTermsSelected,
        isPrivacySelected,
        setIsPrivacySelected,
        otherIndustry,
        setOtherIndustry,
        questionTwoNext,
        questionFourNext,
        questionFourInitialValues,
        questionTwoInitialValues,
        questionTwoFormValues,
        questionFourFormValues,
        setQuestionTwoNext,
        setQuestionFourNext,
        setQuestionTwoFormValues,
        setQuestionFourFormValues,
        isFormCompleted,
        setIsFormCompleted,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = (): FormContextProps => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};
