import React from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
} from "@heroicons/react/24/outline";
import Pagination from "rc-pagination";

interface IPagination {
  current: number;
  size: number;
  total: number;
  onChange: any;
}

const Pagenation: React.FC<IPagination> = ({
  current,
  size,
  total,
  onChange,
}) => {
  return (
    <Pagination
      className="ant-pagination"
      defaultCurrent={current}
      defaultPageSize={size}
      total={total}
      prevIcon={
        <button className="rc-buton">
          <ChevronLeftIcon className="w-4 h-6 mx-auto" />
        </button>
      }
      nextIcon={
        <button className="rc-buton">
          <ChevronRightIcon className="w-4 h-4 mx-auto" />
        </button>
      }
      jumpPrevIcon={
        <button className="rc-buton">
          <EllipsisHorizontalIcon className="v-4 h-4 mx-auto" />
        </button>
      }
      jumpNextIcon={
        <button className="rc-buton">
          <EllipsisHorizontalIcon className="v-4 h-4 mx-auto" />
        </button>
      }
      onChange={(p: number) => onChange(p)}
    />
  );
};

export default Pagenation;
