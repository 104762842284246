import { paramsSerializer } from "../utils/helpers";
import { customAxios, baseUrl } from "./config";
//import * as Tiff from "tiff.js";

class Services {
  static downloadAll = async (params: any) => {
    try {
      const result = await customAxios.get(
        `${baseUrl}/searchresults/?${paramsSerializer(params)}`,
        { timeout: 60 * 1000 * 5 }
      );
      return result;
    } catch (error: any) {
      throw error;
    }
  };
  static getTifFile = async (url: string) => {
    try {
      const result: any = await customAxios.get(`${baseUrl}/ai/s3/${url}`, {
        responseType: "arraybuffer",
      });

      return result;

      // Tiff.initialize({
      //   TOTAL_MEMORY: 16777216 * 10,
      // });

      // const tiff = new Tiff({
      //   buffer: result.data,
      // });

      // const imageUrl = tiff.toDataUrl();

      //return imageUrl;
      // return new Promise((resolve: any, reject: any) => {
      //   const asd = setTimeout(() => {
      //     resolve(true);
      //   }, 3000);
      // });
    } catch (error: any) {
      throw error;
    }
  };
}

export default Services;
