import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { dateFormatOptions } from "../../utils/consts";
import { textCleaner } from "../../utils/helpers";

interface IAdditionalModalContent {
  data: any;
  title: string;
  description: string;
  type: "familyMembers" | "abstract" | "drawingsFigures" | "citedDocuments";
}

const AdditionalModalContent: React.FC<IAdditionalModalContent> = ({
  data,
  type,
}) => {
  let contentData: any = null;
  switch (type) {
    case "abstract":
      contentData = <div>{textCleaner(data)}</div>;
      break;
    case "citedDocuments":
      contentData = (
        <div className="grid grid-cols-1 gap-2">
          {data.map((d: any) => {
            return <div key={d}>{d}</div>;
          })}
        </div>
      );
      break;
    case "familyMembers":
      contentData = (
        <div className="text-xs">
          <div className="grid grid-cols-5 font-bold mb-4">
            <span>Country</span>
            <span>Doc. Number</span>
            <span>Kind</span>
            <span>Data Format</span>
            <span>Doc. Date</span>
          </div>
          {data.map((d: any, i: number) => {
            return (
              <div className="grid grid-cols-5 p-1 gap-2 hover:bg-gray-200" key={i}>
                <span>{d.country}</span>
                <span className="overflow-hidden break-all">{d.documentNumber}</span>
                <span>{d.kind}</span>
                <span>{d.dataFormat}</span>
                <span>
                  {new Date(d.documentDate).toLocaleDateString(
                    "en-US",
                    dateFormatOptions
                  )}
                </span>
              </div>
            );
          })}
        </div>
      );
      break;
    case "drawingsFigures":
      contentData = (
        <div className="-m-7 min-h-min overflow-hidden relative">
          <ImageSlider data={data} />
        </div>
      );
      break;
    default:
      break;
  }
  return <>{contentData}</>;
};

const ImageSlider = ({ data }: { data: any[] }) => {
  const [current, setCurrent] = useState(0);
  const total = data.length;

  const prev = () => {
    let newCurrent: number;
    if (current > 0) {
      newCurrent = current - 1;
    } else {
      newCurrent = total - 1;
    }
    setCurrent(newCurrent);
  };

  const next = () => {
    let newCurrent: number;
    if (current < total - 1) {
      newCurrent = current + 1;
    } else {
      newCurrent = 0;
    }
    setCurrent(newCurrent);
  };

  return (
    <>
      <button
        onClick={prev}
        className="w-10 h-10 absolute rounded top-1/2 -mt-10 left-7 bg-gray-200 flex items-center justify-center border border-gray-300 hover:bg-white"
      >
        <ChevronLeftIcon className="w-5 h-5" />
      </button>
      <button
        onClick={next}
        className="w-10 h-10 absolute rounded top-1/2 -mt-10 right-7 bg-gray-200 flex items-center justify-center border border-gray-300 hover:bg-white"
      >
        <ChevronRightIcon className="w-5 h-5" />
      </button>
      <div style={{ height: "460px" }} className="-m-3">
        <IframeViewer url={data[current]} />
      </div>
      <div className="w-full flex justify-center my-4 px-2 flex-wrap">
        {data.map((val: string, indis: number) => {
          return (
            <NavItem
              key={indis}
              active={current === indis ? true : false}
              onClick={() => setCurrent(indis)}
            />
          );
        })}
      </div>
    </>
  );
};

const IframeViewer = ({ url }: { url: string }) => {
  return (
    <iframe
      src={
        "https://docs.google.com/gview?embedded=true&url=https://dorothyai.uxkraft.com/apigateway/ai/s3/" +
        url
      }
      style={{ width: "100%", height: "460px" }}
      title={url}
    />
  );
};

const NavItem = ({ active, onClick }: { active: boolean; onClick: any }) => {
  const colorStyle = active ? "bg-dorothy-orange" : "bg-gray-300";
  return (
    <span
      className={`w-4 h-4 block rounded-full m-1 cursor-pointer ${colorStyle}`}
      onClick={onClick}
    ></span>
  );
};

export default AdditionalModalContent;
