import * as Yup from "yup";

export const projectSchema = Yup.object({
  title: Yup.string()
    .min(3, "Title must be at least 3 characters")
    .max(250, "Title must be at most 250 characters")
    .required("Please enter project title."),
});

export const noveltySchema = Yup.object({
  abstract: Yup.string().required(
    "Enter or paste the abstract for your invention. Required"
  ),
  features: Yup.array(
    Yup.object({
      text: Yup.string().required(
        "The distinctive that you wish to patent. Required."
      ),
      weight: Yup.string().required("Year is required"),
    })
  ).min(1, "You have to add at least 1 feature."),
  dateFrom: Yup.date()
    .transform((value) => {
      return value ? new Date(value) : value;
    })
    .typeError("Please enter a valid date")
    .optional()
    .min("01/01/1960", "Date is too early"),
  dateTo: Yup.date()
    .min(Yup.ref("dateFrom"), "To date can't be before From date")
    .optional(),
});

export const freedomSchema = Yup.object({
  elements: Yup.array(
    Yup.object({
      text: Yup.string().required("This field is required."),
      weight: Yup.string().required("Weight is required"),
    })
  ).min(1, "You have to add at least 1 element."),
  dateFrom: Yup.date()
    .transform((value) => {
      return value ? new Date(value) : value;
    })
    .typeError("Please enter a valid date")
    .optional()
    .min("01/01/1960", "Date is too early"),
  dateTo: Yup.date()
    .min(Yup.ref("dateFrom"), "To date can't be before From date")
    .optional(),
});

export const strategicAlignmentSchema = Yup.object({
  publicationNo: Yup.string().optional().max(30, "Max 30 charachters."),
  description: Yup.string(),
  novelFeatures: Yup.array(
    Yup.object({
      text: Yup.string(),
      weight: Yup.string(),
    })
  ).min(0, ""),
});
