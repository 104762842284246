import React, { Fragment } from "react";
import { ReactComponent as Cancel } from "../../../assets/icons/cancel.svg";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../button/Button";

interface CongratsModalProps {
  isShow: boolean;
  closeHandler: () => void;
  title?: string;
  icon?: JSX.Element | undefined;
  description?: string | JSX.Element;
  overlayClose?: boolean;
}
const CongratsModal: React.FC<CongratsModalProps> = ({
  isShow,
  title,
  description,
  icon,
  overlayClose,
  closeHandler,
}) => {
  return (
    <Transition appear show={isShow} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={overlayClose ? closeHandler : () => null}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-1 md:p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-[15px] bshadow bg-white text-left align-middle shadow-lg transition-all w-[578px] max-h-[356px]">
                <div className="p-7 font-normal text-sm">
                  <div
                    className="absolute top-4 right-4 cursor-pointer"
                    onClick={closeHandler}
                  >
                    <Cancel />
                  </div>
                  <div className="flex flex-col items-center justify-center py-6 gap-y-6">
                    <span className="text-[22px] text-black font-bold">
                      {title}
                    </span>
                    <span>{icon}</span>
                    <span className="text-sm text-black px-4">
                      {description}
                    </span>
                    <Button
                      variant="filled"
                      size="large"
                      className="self-end bg-dorothy-teal hover:bg-dorothy-teal/80 focus:ring-0 focus:ring-offset-0"
                      onClick={closeHandler}
                    >
                      Okay
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default CongratsModal;
