import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { helpCenterLink } from "../utils/consts";

const Help = () => {
  return (
    <div>
      <h2 className="text-2xl font-sweetsanspro font-semibold mb-6">Help</h2>
      <div className="flex flex-col mb-10">
        <span className="font-bold text-lg mb-5">
          Learn More About DorothyAI Tools and Services
        </span>
        <span className="mb-5 text-gray-900">
          Click on the link below to visit our help center:
        </span>
        <a
          target="_blank"
          rel="noreferrer"
          className="text-white px-11 py-2.5 bg-dorothy-orange rounded-lg w-max hover:text-white hover:no-underline hover:bg-dorothy-orange/90 visited:no-underline active:no-underline visited:text-white"
          href={helpCenterLink}
        >
          Help center
        </a>
      </div>
      <div className="font-bold text-lg">
        Any questions or need technical help?
      </div>
      <div className="text-sm mt-5 text-gray-900">
        Send us an email at{" "}
        <a
          className="underline"
          rel="noreferrer"
          href="mailto:info@dorothyai.com"
          target="_blank"
        >
          info@dorothyai.com
        </a>{" "}
        or click the button below.
      </div>
      <div className="mt-5">
        <a
          className="h-10 inline-flex items-center justify-center gap-2 rounded-lg text-sm text-white bg-dorothy-orange w-44 hover:bg-dorothy-dark-orange"
          rel="noreferrer"
          href="mailto:info@dorothyai.com"
          target="_blank"
        >
          <EnvelopeIcon className="w-4 h-4" />
          Email Us
        </a>
      </div>
    </div>
  );
};

export default Help;
