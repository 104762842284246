import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { months } from "../../../utils/consts";
import { yearRange } from "../../../utils/helpers";
import "./style.scss";

export interface IDateRangeInput {
  placeholder?: string;
  label?: string;
  name: string;
  value?: any;
  required?: boolean;
  dateRef?: any;
  tabIndex?: number;
}

const DateRangeInput: React.FC<IDateRangeInput> = ({
  placeholder,
  label,
  name,
  value,
  dateRef,
  required,
  tabIndex,
}) => {
  const [dateRange, setDateRange] = useState([
    value?.startDate || null,
    value?.endDate || null,
  ]);
  const [startDate, endDate] = dateRange;

  const currentYear = new Date().getFullYear();
  const years = yearRange(currentYear - 60, currentYear, 1);

  const tabHandler = (e: any) => {
    if (e.key === "Tab") {
      setTimeout(() => {
        // @ts-ignore: Unreachable code error
        dateRef?.current?.setOpen(false);
      }, 50);
    }
  };

  return (
    <label>
      {label && (
        <span className="mb-1 text-sm block">
          {label} {required && <b className="text-error">*</b>}
        </span>
      )}
      <DatePicker
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              margin: 10,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              type="button"
              className="mr-2 bg-white w-5 h-5 rounded text-center hover:bg-gray-300"
            >
              <ChevronLeftIcon className="w-3 h-3 inline-block" />
            </button>
            <select
              value={date.getFullYear()}
              onChange={({ target }) => {
                changeYear(parseInt(target.value, 10));
              }}
              className="bg-transparent"
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={months[date.getMonth()]}
              onChange={({ target: { value } }) =>
                changeMonth(months.indexOf(value))
              }
              className="bg-transparent"
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              type="button"
              className="ml-2 bg-white w-5 h-5 rounded text-center hover:bg-gray-300"
            >
              <ChevronRightIcon className="w-3 h-3 inline-block" />
            </button>
          </div>
        )}
        selected={startDate}
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        onChange={(update: any) => {
          setDateRange(update);
        }}
        isClearable={true}
        className="bg-white border border-gray-300 rounded p-3 text-sm h-11 w-full placeholder:text-gray-400"
        dateFormat="MM/dd/yyyy"
        placeholderText={placeholder}
        name={name}
        ref={dateRef}
        autoComplete="off"
        onKeyDown={tabHandler}
        tabIndex={tabIndex}
      />
    </label>
  );
};

export default DateRangeInput;
