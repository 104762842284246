import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { weightOptions } from "../../utils/consts";
import { Label } from "../shared";

const FeatureItem = ({ data }: { data: any }) => {
  let weight = null;
  if (data.weight) {
    weight = weightOptions.filter((w) => w.value === data.weight)[0];
  }

  return (
    <div className="bg-white p-4 rounded-xl flex flex-col md:flex-row justify-between gap-2 md:gap-1 items-start md:items-center mb-2">
      <div className="break-all pr-2">{data.text}</div>
      <div className="flex items-center min-w-xs font-medium">
        {data.required && (
          <Label color="darkgray" icon={<ExclamationCircleIcon className="w-4 h-4"/>}>
            Required
          </Label>
        )}
        {weight ? (
          <span className={`${weight.color} w-12`}>{weight.label}</span>
        ) : (
          "Undefined (value is : " + data.weight + ")"
        )}
      </div>
    </div>
  );
};

const Features = ({ data = [], type }: { data: any; type: string }) => {
  const [count, setCount] = useState(data.length < 4 ? data.length : 3);
  const toggleString = type === "freedom" ? "elements" : "features";

  const items: any = [];
  for (let i = 0; i < count; i++) {
    items.push(<FeatureItem key={i} data={data[i]} />);
  }

  let moreButton: any = "";
  if (data.length > 3) {
    if (data.length > count) {
      moreButton = (
        <div className="mt-4">
          <span
            onClick={() => setCount(data.length)}
            className="text-dorothy-orange cursor-pointer"
          >
            Show all {data.length} {toggleString}
          </span>
        </div>
      );
    } else {
      moreButton = (
        <div className="mt-4">
          <span
            onClick={() => setCount(3)}
            className="text-dorothy-orange cursor-pointer"
          >
            Show less {toggleString}
          </span>
        </div>
      );
    }
  }

  return (
    <>
      {items} {moreButton}
    </>
  );
};

export default Features;
